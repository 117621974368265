.experience-invite-link {
	border-radius: 0.5rem;
	border: 2px black solid;
	display: inline-block;
	padding-left: 1rem;
	padding-right: 1rem;
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
}

.copy-invite-link {
	padding-left: 1rem;
	display: inline-block;
	cursor: pointer;
}

.experience-invitation-modal-classname {
	border-radius: 0.75rem;
}

.experience-invite-title {
	color: rgb(67, 29, 31);
  font-weight: 600;
}

.link-copied {
	padding-top: 0.2rem;
	padding-bottom: 0.2rem;
	border-radius: 0.5rem;
	background-color: #234351;
	color: white;
	width: 10rem;
	margin: auto;
}

.link-copied img {
	width: 1.2rem;
	height: 1.2rem;
	margin-right: 0.5rem;
}

.stay-on-screen {
	color: #431D1F;
	font-weight: 600;
}

