.connectVideo {
    position: relative;
    padding-top: 56.25%;
    border-radius: 1.875rem;
    overflow: hidden;
}
.connectVideo iframe, .connectVideo .connectImage {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
}

.connectVideo .playIcon {
    position: absolute;
    top: calc(100% -30px);
    left: calc(50% - 30px);
    width: 60px;
    top: calc(50% - 30px);
}
.connectVideoContainer .row {
    padding: 0 3.125rem;
    align-items: center;
}
.connectVideoContainer p {
    margin-bottom: 1.75rem;
}
.connectVideoContainer a {
    font-size: 1rem;
    text-decoration: underline;
}
.connectVideoContainer .videoTitle {
    font-size: 2.25rem;
    line-height: 3rem;
}
.videoTitleContainer {
    margin-bottom: 1.5rem;
}

@media(max-width: 767px) {
    .connectVideoContainer .row {
        padding: 0 1rem;
    }
    .connectVideoContainer {
        margin-top: 1rem !important;
    }
}