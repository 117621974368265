.experienceCardContainer {
    margin: 0 0 2rem;
    position: relative;
    text-align: left;
    height: 38.25rem;
}


.experienceCardContainer .durationBadge {
    position: absolute;
    left: 1.25rem;
    z-index: 10;
    top: 0.9375rem;
    width: 50px;
    height: 25px;
    border-radius: 12.6px;
    background-color: #ffffff;
    font-size: 10.5px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.87);
    padding-top: 5px;
    padding-bottom: 7px;
    text-align: center;
}


.topExperienceContainer {
    height: 16.875rem;
    position: relative;
    border-radius: 0.7875rem;
    background: url('../../resources/images/bitmap.png');
    background-size: cover;
    background-position: center;
    overflow: hidden;
}

.topExperienceContainer .experience-card-background-image{
    position: absolute;
    width: 100%;
    height: 100%;
    right: 0;
    bottom: 0;
    /*background: rgba(67, 29, 31,0.8);*/
    opacity: 0.8;
}

.topExperienceContainer .experienceTitle {
    font-size: 1.575rem;
    font-weight: 600;
    line-height: 1.33;
    text-align: left;
    /*padding: 2.5rem 2.5rem;*/
    color: #FFFFFF;
    z-index: 10;
    position: absolute;
    bottom: 40px;
    left: 35px;
}

.topExperienceContainer .author{
    font-size: 0.7875rem;
    line-height: 1.67rem;
    font-weight: 500;
    color: #FFFFFF;
    z-index: 10;
    position: absolute;
    bottom: 8px;
    left: 35px;
}

.experienceContent {
    height: 10.125rem;
    position: relative;
    padding: 0 0.25rem;
}

.experienceContent .experienceTabAbout {
    border: none;
    margin-right: 2rem;
    padding: 0 0 0.625rem 0;
    font-size: 0.7875rem;
    font-weight: bold;
    cursor: pointer;
    width: 3rem;
    line-height: 1rem;
    opacity: 0.8;
    color: inherit;
}

.experienceContent .experienceTabOptions {
    border: none;
    padding: 0 0 0.625rem 0;
    font-size: 0.7875rem;
    font-weight: bold;
    cursor: pointer;
    width: 3.815rem;
    line-height: 1rem;
    opacity: 0.8;
    color: inherit;
}

.experienceContent .experienceTabActive {
    border-bottom: 0.125rem solid;
    opacity: 1;
}


.experienceContent .experienceTabContent {
    height: 4.125rem;
    margin-bottom: 26px;
}

.experienceContent .experienceDescription {
    font-size: 0.75rem;
    line-height: 1.83;
/*    margin-top: 1rem;*/
}

.experienceContent .experienceDescription a {
    line-height: 1.83;
    margin-top: 1rem;
    /*color: white;*/
}

.switchContainer {
    position: relative;
    width: 100%;
    height: 40px;
    margin: auto;
    font-size: 0.75rem;
    font-weight: 500;
}

.switchLabel {
    position: absolute;
    width: 80%;
    top: 0;
    left: 0;
    text-align: left;

}

.switchMain {
    position: absolute;
/*    width: 84px;*/
    top: 0;
    right: 0;
}

.experienceCardContainer .connectButtonsArea {
    /*position: absolute;*/
    width: 100%;
    height: 7.25rem;
    /*bottom: 0;*/
    padding-top: 0.5rem;
}
.experienceCardContainer .connectButtonsArea .btn {
    height: 2.625rem;
    font-size: 0.8125rem;
    width: 19.375rem;
}

.experienceConnectButton, .experienceCancelButton {
    padding: 0.5rem 0.25rem;
}

.experienceCardContainer .connectButtonsArea #BtnCancel {
    font-size: 0.775rem;
    background-color: #dfcccd!important;
    color: #540b10;
}


@media (max-width: 400px) {
    .experienceCardContainer .connectButtonsArea .btn {
        width: 17.5rem;
        font-size: 0.785rem;
    }
    .experienceCardContainer .connectButtonsArea #BtnCancel {
        font-size: 0.7rem;
    }

}

@media (max-width: 360px) {
    .experienceCardContainer .connectButtonsArea .btn {
        width: 15.5rem;
        font-size: 0.725rem;
    }
    .experienceCardContainer .connectButtonsArea #BtnCancel {
        font-size: 0.6rem;
    }

}