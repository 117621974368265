.partnerItem {
	width: 9.5rem;
	height: 5rem;
	margin: auto;
}

.partnerItem img {
  margin: auto;
  max-height: 100%;
  max-width: 100%;
}


.centeredBoxPartner {
  display: flex;
  justify-content: center;
  align-items: center;
	width: 9.5rem;
	height: 5rem;
}

.centeredBoxPartner img {
  max-width: 100%;
  max-height: 100%;
}