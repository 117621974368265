.overflow-hidden {
  overflow: hidden;
}

.videoConatainer {
  background: #eddcd2;
  padding: 2.813rem 0;
}

.video-wrap{
  width:100%;
  max-width: 66.66%;
  position: relative;
  margin:0 auto;
  border-radius: 2.5rem;
  /* overflow: hidden; */
  background:transparent;
}
.video-wrap video{
  width: 100%;
  height: 100%;
}
.video-wrap .main-video{
  position: absolute;
  background: #431D1F;
  top:0;
  left: 0;
  width: 100%;
  height: 100%;
}
.video-wrap .small-video{
  position: absolute;
  background: #181818;
  bottom:0;
  right: 0;
  width: 11.25rem;
  height: 7.5rem;
  border-left: 0.063rem solid #888;
  border-top: 0.063rem solid #888;
  object-fit: cover;
}

.video-wrap .small-video video{
  object-fit: cover;
}

.video-wrap.gettingMedia {
  position: relative
}

.video-wrap.enteringRoom .main-video, .video-wrap.firstTimeGuidance .main-video , .video-wrap.gettingMedia .main-video, .video-wrap.preparing .main-video { 
  background: #431D1F;
  border-radius: 2.5rem;
}

.invisible {
  display: none;
}

.silhouette {
  max-height: 100%;
  height: 100%;
  max-width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  object-fit: cover;
}
#progressbar {
  border-radius: 0;
  position: absolute;
  top:0;
  left: 0;
  width: 100%;
  height: 100%;
}

.enteringRoomState {
  position: absolute;
  width: 100%;
  margin: auto;
  left:0;
  right:0;
  top:0;
  bottom: 0;
  height:2.5rem;
}


.video-wrap.enteringRoom .enteringRoomState, .video-wrap.firstTimeGuidance .enteringRoomState {
  top: 3.563rem !important;
  bottom: auto !important;
  z-index: 1;
}
.enteringRoomState p {
  color: #FFFFFF;
}

.enteringRoomState .someone-text {
  font-size: 1.5rem;
  line-height: 2.5rem;
  margin: 0 0.625rem 0.938rem 0;
}
.enteringRoomState .TipsContainer {
  max-width: 34.375rem;
  margin: 3rem auto;
}
.enteringRoomState .TipsContainer p {
  line-height: 24px;
}

.enteringRoomState .btn-default{
  border: none!important;
}

.ready-state-text, .disconnected-state-text {
  position: absolute;
  width: 100%;
  margin: auto;
  left:0;
  right:0;
  top:0;
  bottom: 0;
  height:2.5rem;
}

.video-wrap.gettingMedia .getting-media-text {
  position: absolute;
  width: 100%;
  left: 0;
  right: 0;
  top: 2.5rem;
  height: 100%;
}

#CancelButton button {
  border: 1px solid #fff !important;
  color: #FFFFFF;
}

.video-wrap.enteringRoom #CancelButton button, .video-wrap.firstTimeGuidance #CancelButton button {
  border-color: #FFFFFF!important;
  border: 1px solid #FFFFFF
}

.video-wrap.gettingMedia #CancelButton {
  position: absolute;
  width: 100%;
  margin: auto;
  left: 0;
  bottom: 3.125rem;
  height: 2.5rem;
}

.video-wrap.enteringRoom #CancelButton, .video-wrap.firstTimeGuidance #CancelButton {
  position: absolute;
  width: 100%;
  margin: auto;
  left: 0;
  height: 2.5rem;
  top: 25.75rem;
}

#EndConnectionButton {
  position: absolute;
  width: 100%;
  margin: auto;
  left: 0;
  top: 0;
  height: 2.5rem;
  bottom: -85vh;
}

#EndConnectionButton #BtnEndConnection {
  color: #252525;
  font-weight: 500 !important;
  background: #E5E5E5; 
  border-color: 0.188rem solid #979797 !important
}

.video-wrap.preparing #CancelButton{
  width:7.5rem;
  position: relative;
  display: inline-block;
  margin-top:45%;
}

#StartButton {
  width:7.5rem;
  position: relative;
  display: inline-block;
  margin-top:45%;
}
#StartButton button #BtnStart {
  color: #252525;
  font-weight: 500 !important;
  background: #E5E5E5!important; 
  border-color: 0.188rem solid #979797 !important
}

.ready-state-text, .disconnected-state-text {
  font-size: 1.5rem;
  margin-top:0.625rem;
  position: absolute;
  top: -4.75rem;
  width: 100%;
}
.getting-media-text p, .ready-state-text p, .disconnected-state-text p {
  color: #757575;
}

.getting-media-text p {
  font-size: 1rem;
  color: #CBA2A7;
  line-height: 1.75rem;
  text-align: left;
}
.getting-media-text .permissionContainer {
  display: block;
  margin: auto;
  max-width: 23.125rem;
  padding: 6rem 0.625rem 0;
}
.getting-media-text .permissionContainer img{
  width: 1.875rem;
  float: left;
  margin-top: 1.25rem;
}
.getting-media-text .permissionContainer .permission-text{
  width: calc(100% - 1.875rem);
  padding-left: 1.5rem;
  float: left;
}
.getting-media-text .heading-text{
  font-size: 1.5rem;
  line-height: 2.5rem;
  color: #FFFFFF;
  text-align: center;
  padding: 0 0.625rem;
}

.ready-state-text, .disconnected-state-text {
  margin: 0 auto;
  top: 15%;
}

.disconnected-state-text .spinner, .ready-state-text .spinner {
  float: inherit!important;
}

.disconnected-state-text h4 {
  color:#fff!important;
}

.guidanceContainer {
  position: relative;
  z-index: 1;
  color: #FFFFFF;
  margin-top: 60px;
  width: 100%
}
.guidelines {
  padding: 0 4rem;
  text-align: left;
}
.guidelinesText {
  font-size: 1.5rem;
}
.guidanceButtonContainer {
  padding: 0 4rem;
  position: absolute;
  width: 100%;
  z-index: 1;
  bottom: 0;
  transition: all ease-in 0.2s;
}
.guidanceButtonContainer .buttons {
  display: block;
  position: relative;
}
.guidanceButtonContainer .BtnNotFollowGuidelines {
  float: left;
}
.guidanceButtonContainer .BtnFollowGuidelines {
  float: right;
}
.guidanceButtonContainer button {
  color: #fff;
  border-color: #fff;
  width: 280px;
  padding: 0 1rem !important;
}
.guidanceButtonContainer .BtnDisabledFollowGuidelines {
  float: right;
  color: gray;
  border-color: gray;
  opacity: 0.5;
}
.selectLanguageContainer {
  position: absolute;
  width: 100%;
  z-index: 2;
}

.guidanceButtonContainer .spincontainer {
  position: absolute;
  left: calc(50% - 20px);
  top: 0;
}

.guidanceButtonContainer .button_animation:hover {
  background: transparent !important;
  border-color: #FFFFFF;
  color: #FFFFFF !important; 
}


.audio-message-text {
  background: #FFFFFF;
  border-radius: 1.25rem;
  padding: 1.825rem;
  position: absolute;
  width: 18.75rem;
  right: 1.25rem;
  top:1.25rem;
}


@media (max-width: 1200px) and (min-width: 767px) {
  .video-wrap .enteringRoom .enteringRoomState, .video-wrap .firstTimeGuidance .enteringRoomState {
    top: 1rem;
  }
}
  
  .videoConatainer {
    background: #eddcd2;
    padding: 2.813rem 0;
  }
  
  .video-wrap{
    width:100%;
    max-width: 66.66%;
    position: relative;
    margin:0 auto;
    border-radius: 2.5rem;
    /* overflow: hidden; */
    background:transparent;
  }
  .video-wrap video{
    width: 100%;
    height: 100%;
  }
  .video-wrap .main-video{
    position: absolute;
    background: #431D1F;
    top:0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .video-wrap .small-video{
    position: absolute;
    background: #181818;
    bottom:0;
    right: 0;
    width: 11.25rem;
    height: 7.5rem;
    border-left: 0.063rem solid #888;
    border-top: 0.063rem solid #888;
    object-fit: cover;
  }
  
  .video-wrap .small-video video{
    object-fit: cover;
  }
  
  .video-wrap.gettingMedia {
    position: relative
  }
  
  .video-wrap.enteringRoom .main-video, .video-wrap.firstTimeGuidance .main-video, .video-wrap.gettingMedia .main-video, .video-wrap.preparing .main-video{ 
    background: #431D1F;
    border-radius: 2.5rem;
  }
  .video-wrap.enteringRoom, .video-wrap.firstTimeGuidance {
    overflow: hidden;
  }
  .invisible {
    display: none;
  }
  
  .silhouette {
    max-height: 100%;
    height: 100%;
    max-width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    object-fit: cover;
  }
  #progressbar {
    border-radius: 0;
    position: absolute;
    top:0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  
  .enteringRoomState{
    position: absolute;
    width: 100%;
    margin: auto;
    left:0;
    right:0;
    top:0;
     bottom: 0;
     height:2.5rem;
  }
  
  .video-wrap.enteringRoom .enteringRoomState, .video-wrap.firstTimeGuidance .enteringRoomState {
    top: 3.563rem;
    bottom: auto;
  }
  .enteringRoomState p {
    color: #FFFFFF;
  }
  
  .enteringRoomState .someone-text {
    font-size: 1.5rem;
    line-height: 2.5rem;
    margin: 0 0.625rem 0 0.938rem;
  }
  .enteringRoomState .TipsContainer {
    max-width: 34.375rem;
    margin: 3rem auto;
  }
  .enteringRoomState .TipsContainer p {
    line-height: 24px;
  }
  
  .enteringRoomState .btn-default{
    border: none!important;
    min-width: 18.75rem;
    padding: 0.4375rem 0.9375rem !important;
    font-size: 1rem;
    font-weight: bold !important;
    margin-bottom: 20px;
  }
  
  .ready-state-text, .disconnected-state-text {
    position: absolute;
    width: 100%;
    margin: auto;
    left:0;
    right:0;
    top:0;
    bottom: 0;
    height:2.5rem;
  }
  
  .video-wrap.gettingMedia .getting-media-text {
    position: absolute;
    width: 100%;
    left: 0;
    right: 0;
    top: 2.5rem;
    height: 100%;
  }
  
  #CancelButton button {
    border: 1px solid #fff !important;
    color: #FFFFFF;
  }
  
  .video-wrap.enteringRoom #CancelButton button, .video-wrap.firstTimeGuidance #CancelButton button {
    border-color: #FFFFFF!important;
    border: 1px solid #FFFFFF
  }
  
  .video-wrap.gettingMedia #CancelButton {
    position: absolute;
    width: 100%;
    margin: auto;
    left: 0;
    bottom: 3.125rem;
    height: 2.5rem;
  }
  
  .video-wrap.enteringRoom #CancelButton, .video-wrap.firstTimeGuidance #CancelButton {
    position: absolute;
    width: 100%;
    margin: auto;
    left: 0;
    height: 2.5rem;
    top: 23.75rem;
  }
  
  #EndConnectionButton {
    position: absolute;
    width: 100%;
    margin: auto;
    left: 0;
    top: 0;
    height: 2.5rem;
    bottom: -85vh;
  }
  
  #EndConnectionButton #BtnEndConnection {
    color: #252525;
    font-weight: 500 !important;
    background: #E5E5E5; 
    border-color: 0.188rem solid #979797 !important
  }
  
  .video-wrap.preparing #CancelButton{
    width:7.5rem;
    position: relative;
    display: inline-block;
    margin-top:45%;
  }
  
  #StartButton {
    width:7.5rem;
    position: relative;
    display: inline-block;
    margin-top:45%;
  }
  #StartButton button #BtnStart {
    color: #252525;
    font-weight: 500 !important;
    background: #E5E5E5!important; 
    border-color: 0.188rem solid #979797 !important
  }

  .ready-state-text, .disconnected-state-text {
    font-size: 1.5rem;
  }
  .getting-media-text p, .ready-state-text p, .disconnected-state-text p {
    color: #757575;
  }
  
  .getting-media-text p {
    font-size: 1rem;
    color: #CBA2A7;
    line-height: 1.75rem;
    text-align: left;
  }
  .getting-media-text .permissionContainer {
    display: block;
    margin: auto;
    max-width: 23.125rem;
    padding: 6rem 0.625rem 0;
  }
  .getting-media-text .permissionContainer img{
    width: 1.875rem;
    float: left;
    margin-top: 1.25rem;
  }
  .getting-media-text .permissionContainer .permission-text{
    width: calc(100% - 1.875rem);
    padding-left: 1.5rem;
    float: left;
  }
  .getting-media-text .heading-text{
    font-size: 1.5rem;
    line-height: 2.5rem;
    color: #FFFFFF;
    text-align: center;
    padding: 0 0.625rem;
  }
  
  .ready-state-text, .disconnected-state-text {
    margin: 0 auto;
    top: 15%;
  }
  
  .disconnected-state-text .spinner, .ready-state-text .spinner {
    float: inherit!important;
  }
  
  .disconnected-state-text h4 {
    color:#fff!important;
  }


  @media (max-width: 1126px) {
    .guidanceButtonContainer button {
      width: 100%;
    }
    .guidanceButtonContainer .spincontainer {
      position: static;
    }
    .guidanceContainer {
      padding-bottom: 200px;
      height: auto !important;
    }
  }
  
  @media (max-width: 1200px) and (min-width: 767px) {
    .enteringRoomState .TipsContainer {
      margin: 1rem auto;
      margin-bottom: 0;
      margin-top: 0;
    }
    .video-wrap.enteringRoom #CancelButton, .video-wrap.firstTimeGuidance #CancelButton {
      top: 19rem;
    }
    .getting-media-text .permissionContainer {
      padding-top: 3rem;
    }
  }
  
  @media(max-width: 991px) {
    .getting-media-text .permissionContainer {
      padding-top: .5rem;
    }
    .guidelines, .guidanceButtonContainer {
      padding: 0 2rem;
    }
    .video-wrap.enteringRoom, .video-wrap.firstTimeGuidance {
      overflow: hidden;
    }
  }
  @media(max-width: 900px) {
    .getting-media-text .permissionContainer {
      padding-top: 0rem;
    }
    .video-wrap.gettingMedia #CancelButton {
      bottom: 1.125rem;
    }
    .video-wrap.gettingMedia .getting-media-text {
      top: 1.5rem;
    }
  
  }
  
  /*Notification Issue in following devices*/

  @media (min-width: 767px) and (max-width: 800px){
    .enteringRoomState .someone-text {
      font-size: 1.4rem;
    }
  }
  
  @media (max-width: 767px) {
    .enteringRoomState{
      position: absolute!important;
      margin: inherit!important;
    }
    .video-wrap.enteringRoom .enteringRoomState, .video-wrap.firstTimeGuidance .enteringRoomState {
      position: absolute;
    }
  
    .video-wrap.preparing #CancelButton {
      top: -5rem;
      bottom: inherit!important;
      margin-top: inherit!important;
    }
  
    .getting-media-text, .ready-state-text, .disconnected-state-text {
      margin-top: 0.313rem;
      font-size: .9rem;
    }
  
    .getting-media-text, .ready-state-text {
      position: relative;
      top:-8.75rem;    
    }
  
    #StartButton {
      top: -5rem;
      bottom: inherit!important;
      margin-top: inherit!important;
    }
  
    #StartButton .btn-default{
      border:0.188rem solid #fff;
    }
  
    /*Connected Stage*/
    #EndConnectionButton {
      top: -5rem;
    }
  
    .video-wrap.preparing .low-text{
      top: 1.25rem;
      margin: 0 auto;
      left:0;
      right:0;
      max-width: 18.75rem;
    }
    .video-wrap.gettingMedia #CancelButton {
      bottom: 3.125rem;
    }

    .guidanceContainer {
      position: absolute;
      padding-bottom: 100px;
      overflow:scroll;
      max-height:100%;
    }
    .guidanceButtonContainer {
      position: relative;
      margin-top: 2rem;
    }
    .video-wrap.enteringRoom .main-video, .video-wrap.firstTimeGuidance .main-video {
      border-radius: 0;
    }
    .guidanceButtonContainer button {
      width: 100%;
    }
  
  }
  
  
  /* FULL SCREEN STYLES */
  .video-wrap:fullscreen {
    width: 100%;
    height: 100%;
  }
  /* Webkit (Chrome, Opera & Safari) */
  .video-wrap:-webkit-full-screen {
    width: 100%;
    height: 100%;
  }
  /* Gecko (Firefox) */
  .video-wrap:-moz-full-screen {
    width: 100%;
    height: 100%;
  }
  /* Trident (Internet Explorer) */
  .video-wrap:-ms-fullscreen {
    width: 100%;
    height: 100%;
  }
  
  
  /*digesting-screen-full Stage*/
  .digesting-screen-full {
    width: 100vw;
    height: 100vh!important;
    height:calc(var(--vh, 1vh) * 100)!important;
    margin-top: 0;
    border-radius: 0;
    max-width: 100%;
    overflow: hidden;
  }
  
  .digesting-screen-full h4{
    float: inherit!important;
    color:#fff!important;
  }
  .digesting-screen-full .spinner{
    float: inherit!important;
  }
  
  /*Preparing Stage*/
  .video-wrap.preparing .low-text{
    background: #FFFFFF;
    border-radius: 1.25rem;
    padding: 1.825rem;
    position: absolute;
    width: 18.75rem;
    right: 1.25rem;
    top:1.25rem;
  }
  .video-wrap.preparing .low-text img {
    position: absolute;
    right: 0.938rem;
    top: 1rem;
    width: 0.9rem;
  }
  .video-wrap.preparing .low-text p{ 
    margin-bottom: 0;
  }
  
  /*Notification Issue in following devices*/
  @media (min-width: 768px) and (max-width: 975px){
    .video-wrap.enteringRoom, .video-wrap.firstTimeGuidance {
      max-width: 75%;
      height: 22.188rem!important;
    }
  
  }
  /*Small devices & Extra small devices (portrait phones, less than 576px)*/
  @media (max-width: 767px) {
  
    .flex-grow-1.fullBackground h1{
      font-size: 2.5rem!important
    }
  
    .lead {
      font-size: 1.2rem;
    }
  
    .video-wrap video{
      object-fit: cover;
    }
  
    .fullBackground{
      height:100vh;
      height:calc(var(--vh, 1vh) * 100);
      overflow: hidden;
    }
  
    .video-wrap{
      width: 100%;
      max-width: inherit;
      height: auto!important;
    }
    .video-wrap .main-video{
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto;
      width: 100%;
      max-width: inherit;
      margin-top: 0;
      background: #431D1F;
      height: 15rem!important;
      position: relative!important;
    }
  
    .video-wrap .small-video{
      width: 5.313rem;
      height: 7.5rem;
      right: 0;
      box-shadow: 0 0 1.25rem #666;
    }
  
     /*Preparing Stage*/
    .fullBackground .video-wrap.preparing, .fullBackground .video-wrap.gettingMedia {
      height:calc(100vh - 4.563rem)!important;
      height:calc((var(--vh, 1vh) * 100) - 4.563rem)!important;
      max-height:calc(100vh - 4.563rem)!important;
      max-height:calc((var(--vh, 1vh) * 100) - 4.563rem)!important;
      margin-bottom: 0!important;
      border-radius: 0!important;
      overflow: hidden!important;
    }
  
    .fullBackground .video-wrap.preparing .main-video, .fullBackground .video-wrap.gettingMedia .main-video {
      top: 0%!important;
      height:calc(100vh - 4.563rem)!important;
      height:calc((var(--vh, 1vh) * 100) - 4.563rem)!important;
    }
  
    /*Waiting stage*/
  
    .fullBackground .video-wrap.enteringRoom, .fullBackground .video-wrap.firstTimeGuidance {
      height:calc(100vh)!important;
      height:calc((var(--vh, 1vh) * 100))!important;
      max-height:calc(100vh - 4.563rem)!important;
      max-height:calc((var(--vh, 1vh) * 100))!important;
      margin-bottom: 0!important;
      border-radius: 0!important;
      overflow: hidden!important;
    }
  
    .fullBackground .video-wrap.enteringRoom .main-video, .fullBackground .video-wrap.firstTimeGuidance .main-video {
      top: 0%!important;
      height:calc(100vh)!important;
      height:calc((var(--vh, 1vh) * 100))!important;
    }
  
    .fullBackground nav, .fullBackground .welcomecontainer, .fullBackground #Lobby .container, .fullBackground + footer {
      display: none
    }
    .video-wrap.enteringRoom .main-video, .video-wrap.firstTimeGuidance .main-video, .video-wrap.gettingMedia .main-video {
      border-radius: 0;
    }
  
    /*Disconnected State*/
  
    .fullBackground .video-wrap.disconnected{
      height:calc(100vh)!important;
      height:calc((var(--vh, 1vh) * 100))!important;
      max-height:calc(100vh)!important;
      max-height:calc((var(--vh, 1vh) * 100))!important;
      margin-bottom: 0!important;
      border-radius: 0!important;
      overflow: hidden!important;
    }
  
    .fullBackground .video-wrap.disconnected .main-video{
      top: 0%!important;
      height:calc(100vh)!important;
      height:calc((var(--vh, 1vh) * 100))!important;
    }
  
    /*Ready*/
    .video-wrap.ready.digesting-screen-full, .video-wrap.connected.digesting-screen-full, .video-wrap.disconnected.digesting-screen-full{
      height:100vh!important;
      height:calc(var(--vh, 1vh) * 100)!important;
      background: #431D1F;
    }

    .video-wrap.digesting.digesting-screen-full {
      height:100vh!important;
      background: #431D1F;
    }
  
    /*Connected full-screen Start*/
    .video-wrap.connected.digesting-screen-full .main-video{
      height:100vh!important;
      height:calc(var(--vh, 1vh) * 100)!important;
    }
  
    .connected.digesting-screen-full.video-wrap .main-video #mainVideoTag {
      min-width: 100%;
      height:100%;
    }
    /*Connected full-screen End*/
    /*digesting Stage*/
  
    .video-wrap.digesting.digesting-screen-full .main-video{
      display: none;
    } 
  
  }

  
  @media (max-width: 991.98px) {

    .videoConatainer {
      padding-top: 1.563rem;
    }
  }
  
  @media (max-width: 575px) {
    .video-wrap.gettingMedia #CancelButton {
      bottom: 1.125rem;
    }
  }
  
  @media(max-width: 767px) {
    .ready-state-text {
      top: -3.45rem;
    }
    .disconnected-state-text{
      top: 5.5rem;
    }

  }

  @media(max-width: 600px) {
    .enteringRoomState .TipsContainer {
      margin: 2rem 0.625rem;
    }
    .video-wrap.enteringRoom #CancelButton, .video-wrap.firstTimeGuidance #CancelButton {
      top: 27.75rem;
    }
  }
  
  @media (max-width: 480px) {
    .disconnected-state-text{
      top: 3.45rem;
    }
    .enteringRoomState .TipsContainer {
      margin: 1.5rem 0.625rem;
    }
    .flex-grow-1.fullBackground h1{
      font-size: 1.5rem!important;
      height: 4.063rem;
      line-height: 4.063rem;
      padding: 0!important;
    }
  }
  