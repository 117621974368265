.full-height {
  min-height: 100vh;
  overflow: hidden;
}
.newsbannerbox{
    background: #212721;
    color: white;
    padding-top: 0.75rem;
    padding-bottom:0.063rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
}
.emailContainer {
  width: 100%!important;
}
.emailContainer input {
  width: 100%!important;
  text-align: center;
}
.invitationContainer {
  width: 100%!important;
  text-align: center;
  display: block!important;
  
}
.invitationContainer button {
  color: #ffffff;
  background: #A74450;
  margin-top: 0.313rem;
}

.invitationContainer button:hover {
  color: #ffffff;
  background: #8c3742;
}
.invitationContainer button:active {
  color: #ffffff;
  background: #A74450!important;
}
.invitationContainer button:focus {
  background: #A74450!important;
}
.invitationEmailContainer {
  padding: 3.125rem 1.25rem 0;
}
/*Extra small devices (portrait phones, less than 576px)*/
@media (max-width: 575.98px) {

  /* NextGatherings Page */
  .gathering-text{
    text-align: left;
    line-height: 1.625rem;
  }
  /* InvitationForm Page */
  .mobtext-left{
    text-align: left;
  }
  .mobfont16{
    font-size: 1rem;
    line-height: 1.75rem;
  }
}
