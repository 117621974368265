.testimonialsbox{
    max-width: 59.063rem;;
    margin:0 auto;
    padding: 0;
    flex: 100%;
}
/* .testimonialsbox p{
    font-size: 1rem;
    text-align: center;
    margin: 2rem auto;
    line-height: 2.375rem;
    font-weight: 500;
    color:#794D4F;
} */

.testimonialsbox p.testimonialtext{	
    font-size: 1.5rem;	    
    font-style: italic;	    
    text-align: left;	   
    margin: 2rem auto;	   
    color:#7B706A;	   
}

.testimonialsbox p.testimonialauthor{
    font-size: 1.125rem;
    margin: 0 auto;
    line-height: 1.875rem;
    font-weight: normal;
    color: #3C3C3C;
}

.testimonialsbox p.testimonialauthor{
    margin-bottom: 4rem;
}

p.visitorCountry{
    color:#181414 !important;
}

.gathering-text{
    margin-bottom: 2rem;
}

a.emaillink{
    font-size: 1.125rem;
    margin:0rem auto 0rem;
    display: inline-block;
    line-height: 1.875rem;
    font-weight: normal; 
    text-decoration: underline; 
    color: #5B0E0D;
}
.connectintrobox {
    padding: 0 .938rem;
}
.connectintrobox .visitorBox {
    width: 100%;
    background: #ffffff;
    margin: 0 auto;
    border-radius: 0.375rem;
    padding: 2rem 5rem;
    font-style: italic;
}
.visitorBox img{
    margin-bottom: 1.2rem;
}

@media (max-width: 991.98px) {
    .connectintrobox {
        padding: 3.75rem 1.938rem;
    }
    .connectintrobox .visitorBox {
        padding: 1.25rem;
        max-width: 75%;
    }
    .connectintrobox .visitorBox .visitorText{
        font-size: 0.875rem;
        line-height: 1.5rem;
    }
}

@media (max-width: 767px) {
    .connectintrobox .visitorBox {
        max-width: 100%;
    }
 }

@media (max-width: 575.98px) {
    .testimonialsbox p.testimonialtext{
        font-size: 1.25rem;
        margin: 1rem 0.938rem 2rem;
        line-height: 2.125rem;
    }
    .testimonialsbox p.testimonialauthor{
        font-size: 1rem;
        line-height: 1.125rem;
    }
    
}