/*Banner CSS*/
.bannerbox {
  position: relative;
  height:calc(100vh / 1.2);
  min-height:43.75rem;
  max-height: 59.375rem;
  overflow: hidden;
}
.bannerbox img{
    width:100%;
    height: 100%;
    object-fit: cover;
}
.bannertext{
    position: absolute;
    width: 100%;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(180.02deg, rgba(248, 233, 222, 0) 0.3%, #F8E9DE 63.26%);
    mix-blend-mode: normal;
    padding-bottom: 1.25rem;
    height: 40%;
  }

.drawingsGradient{
    background: linear-gradient(180.02deg, #F8E9DE , white);
  }

  .bannertext h1{
    max-width: 48.75rem;
    margin: 0px auto;
    margin-top: 0.875rem;
    text-align: center;
    font-weight: 900;
    font-size: 4rem;
    line-height: 4.75rem;
    font-style: normal;
    color: #6A3E40;
  }
  .bannertext p {
      max-width: 34.375rem;
      text-align: center;
      font-size: 1.125rem;;
      margin: 0.875rem auto 2.5rem;
  }
  .welcomecontainer h1{
    max-width: 48.75rem;;
    color: #6A3E40;
    text-align: center;
    font-size: 3rem;;
    margin: 0.625rem auto 0;
    text-transform: capitalize;
}

.lineDrawingContainer {
  margin-bottom: 4rem;
}
.lineDrawingContainer img {
  height: 200px;
  max-width: 100%;
}
.lineDrawingContainer .spaceTitle{
  font-weight: 500;
  font-size: 1.375rem;
  line-height: 1.875rem;
  margin-top: 3rem;
}
.lineDrawingContainer .spaceText{
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.75rem;
  padding: 0 0.625rem;
}
.lineDrawingRow {
  justify-content: center;
}

@media (max-width: 1024px) {
  .lineDrawingRow .col-sm-4 {
    flex: 0 0 50%;
    max-width: 50%;
  }
}
/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {
  .bannerbox {
    min-height: inherit;
    max-height: 26.25rem;
    margin-bottom: 3.438rem;
  }

  .bannertext h1 {
    max-width: 75%;
    font-size: 1.938rem;
    line-height: 2.75rem;
    margin-top: 3rem;

  }
  .bannertext p {
    max-width: 28.125rem;
    font-size: 0.875rem;
    margin: 0.313rem auto 0.938rem;
  }
}
@media (min-width: 834px) and (max-width: 920px){
  .bannertext{
    bottom:-5%;
  }
  .bannertext h1 {
    margin-top: 3rem;
  }
}
@media (min-width: 576px) and (max-width: 920px){
  .bannertext h1{
    font-size: 1.75rem;
    line-height: 2.063rem;
    margin-top: 3rem;
  }
}
@media (max-width: 683px) {
  .lineDrawingRow .col-sm-4 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
/*Extra small devices (portrait phones, less than 576px)*/
@media (max-width: 575.98px) {
  .bannerbox{
    height: 27.5rem;
    overflow: hidden;
  }
  .bannerbox img{
    height:100%;
    object-fit: cover;
  }
  .bannertext h1 {
    max-width: 97%;
    font-size: 1.875rem;
    line-height: 2.5rem;
    margin-top: 2rem;
  }
  .welcomecontainer h1{
    font-size: 1.875rem;
    line-height: 2.5rem;
    margin: 0;
    padding: 0 !important;
  }
  .welcomecontainer {
    margin: 0!important;
    padding-bottom: 0.5rem;
  }
  .bannertext p {
    max-width: 90%;
    font-size: 1rem;
  }
}
/*Extra extra small devices (portrait phones, less than 576px)*/

@media (max-width: 500px) {
  .bannertext{
    height: 46%;
  }
}

@media (max-width: 373px) {
  .bannertext {
    bottom: -10%;
  }
  .bannertext h1 {
    font-size: 1.563rem;
    line-height: 2rem;
    margin-top: 0.5rem;
  }
  .bannertext p {
    font-size: 0.875rem;
  }
  .bannerbox {
    margin-bottom: 1.875rem;
  }
}