// Override default variables before the import

// Primary color-scheme:

$primary: #431D1F;
$secondary: transparent !default;
$success: #226F26;
$danger: #EF1E14;
$info: #8C5655;
$warning: #212721;
$dark-bg : #431D1F;
$textcenter: center;

// Exceptions to the new primary color (use old one)

// Link Color Variable
// $link-color: #804a49;
$link-hover-color: #5b0e0d;
$link-hover-decoration: none !default;


//$component-active-bg: #007bff;

// General background and text color
$body-bg: white;
$body-color: #212121;

// Font (Montserrat + all previous alternatives)
$font-family-sans-serif: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

$font-size-base: 1rem; // Assumes the browser default, typically `16px`
$font-size-lg: ($font-size-base * 1.25);
$font-size-sm: ($font-size-base * 0.875);

// Font (Playfair Display)
$font-family-playfair: "Playfair Display", "serif";

// line-height Variable
$line-height-base:  1.75;

$input-border-radius: 0.5rem;
//body css
body {
  font-family: $font-family-sans-serif;
}
//link css
a {
  font-weight: 600;
  text-decoration: none;
}
//Navbar css
$navbar-padding-y: 1.3em;
$navbar-light-color:                #CBA2A7!default;
$navbar-light-hover-color:          #ffffff !default;
$navbar-light-active-color:         #ffffff !default;

.navbar-light {
 background: #9c676c;
}

/*Button css*/
// $btn-border-radius-main: 2.813rem;
$btn-border-radius-main: 0.39375rem;
$btn-border-width: 0.125rem;
$btn-font-weight: 600;
$btn-padding-y: 0rem;
$btn-padding-x: 1.25rem;

$btn-padding-y-lg: 0rem;
$btn-padding-x-lg: 2.188rem;

$btn-line-height: 2.25rem;

.btn{
  border-radius: $btn-border-radius-main!important;
  border-color:#979797;
}
.btn-lg{
  padding: $btn-padding-y-lg $btn-padding-x-lg !important;
}

.btn-primary{
  background: #540b10!important;
}


.btn-default{
  border: solid 2px #763b3e !important;
  background: #FFFFFF!important;
  color: #540b10 !important;
  box-shadow: 0px 8px 17px rgba(128, 74, 73, 0.42) !important;
}

.btn-default:hover{
  background: #794D4F !important;
  color: #FFFFFF !important;
  box-shadow: 0px 8px 17px rgba(128, 74, 73, 0.42) !important;
}

.btn-primary:hover{
  background: #794D4F !important;
  color: #FFFFFF !important;
}

.btn-secondary:hover{
  background: #794D4F !important;
  color: #FFFFFF !important;
}

$h1-font-size:  $font-size-base * 3 !default;
$h2-font-size:  $font-size-base * 2.25 !default;
$h4-font-size:  $font-size-base * 1.5 !default;
$h1-line-height: $line-height-base * 3.5 !default;
$h1-font-weight: 900;



/*Banner CSS*/
h1{
  font-family: $font-family-playfair!important;
  line-height: $h1-line-height;
  font-weight: $h1-font-weight;
}
.spincontainer{
  display: inline-block;
}
.vedio-alerttext{
    display: inline-block;
    line-height: 2.5rem;
    margin: 0 auto 0 0.625rem;
    float: left;
    color: #FFFFFF;
  }


.navbar-nav .nav-item .nav-link {
  font-weight: 400;
  border-bottom: 0.125rem solid transparent;
  position: relative;
}

@media (min-width: 768px) {
  .navbar-nav .nav-item .nav-link {
    &:after{
      content:'';
      position: absolute;
      width:0.375rem;
      height: 0.375rem;
      border-radius: 50%; 
      bottom:-0.438rem;
      background-color: $navbar-light-active-color;
      left: 0;
      right: 0;
      margin: auto;
      display: none;
    }
    &:hover {
      &:after{
        display: block
      }
    }
    &.active {
     &:after{
      display: block
    }
    }
  }
}

// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';
@import url('https://fonts.googleapis.com/css?family=Playfair+Display:900&display=swap');


.btn {
  @include button-size($btn-padding-y, $btn-padding-x, $font-size-sm, $btn-line-height, $btn-border-radius);
  // Share hover and focus styles
  @include hover-focus {
    text-decoration: none;
  }
}

