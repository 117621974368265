@import url(https://fonts.googleapis.com/css?family=Playfair+Display:900&display=swap);
body {
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"; }

a {
  font-weight: 600;
  text-decoration: none; }

.navbar-light {
  background: #9c676c; }

/*Button css*/
.btn {
  border-radius: 0.39375rem !important;
  border-color: #979797; }

.btn-lg, .btn-group-lg > .btn {
  padding: 0rem 2.188rem !important; }

.btn-primary {
  background: #540b10 !important; }

.btn-default {
  border: solid 2px #763b3e !important;
  background: #FFFFFF !important;
  color: #540b10 !important;
  box-shadow: 0px 8px 17px rgba(128, 74, 73, 0.42) !important; }

.btn-default:hover {
  background: #794D4F !important;
  color: #FFFFFF !important;
  box-shadow: 0px 8px 17px rgba(128, 74, 73, 0.42) !important; }

.btn-primary:hover {
  background: #794D4F !important;
  color: #FFFFFF !important; }

.btn-secondary:hover {
  background: #794D4F !important;
  color: #FFFFFF !important; }

/*Banner CSS*/
h1 {
  font-family: "Playfair Display", "serif" !important;
  line-height: 6.125;
  font-weight: 900; }

.spincontainer {
  display: inline-block; }

.vedio-alerttext {
  display: inline-block;
  line-height: 2.5rem;
  margin: 0 auto 0 0.625rem;
  float: left;
  color: #FFFFFF; }

.navbar-nav .nav-item .nav-link {
  font-weight: 400;
  border-bottom: 0.125rem solid transparent;
  position: relative; }

@media (min-width: 768px) {
  .navbar-nav .nav-item .nav-link:after {
    content: '';
    position: absolute;
    width: 0.375rem;
    height: 0.375rem;
    border-radius: 50%;
    bottom: -0.438rem;
    background-color: #ffffff;
    left: 0;
    right: 0;
    margin: auto;
    display: none; }
  .navbar-nav .nav-item .nav-link:hover:after {
    display: block; }
  .navbar-nav .nav-item .nav-link.active:after {
    display: block; } }

/*!
 * Bootstrap v4.6.2 (https://getbootstrap.com/)
 * Copyright 2011-2022 The Bootstrap Authors
 * Copyright 2011-2022 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #431D1F;
  --secondary: transparent;
  --success: #226F26;
  --info: #8C5655;
  --warning: #212721;
  --danger: #EF1E14;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.75;
  color: #212121;
  text-align: left;
  background-color: white; }

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
          text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  -webkit-text-decoration-skip-ink: none;
          text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #431D1F;
  text-decoration: none;
  background-color: transparent; }
  a:hover {
    color: #5b0e0d;
    text-decoration: none; }

a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([class]):hover {
    color: inherit;
    text-decoration: none; }

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit;
  text-align: -webkit-match-parent; }

label {
  display: inline-block;
  margin-bottom: 0.5rem; }

button {
  border-radius: 0; }

button:focus:not(:focus-visible) {
  outline: 0; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

[role="button"] {
  cursor: pointer; }

select {
  word-wrap: normal; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2; }

h1, .h1 {
  font-size: 3rem; }

h2, .h2 {
  font-size: 2.25rem; }

h3, .h3 {
  font-size: 1.75rem; }

h4, .h4 {
  font-size: 1.5rem; }

h5, .h5 {
  font-size: 1.25rem; }

h6, .h6 {
  font-size: 1rem; }

.lead {
  font-size: 1.25rem;
  font-weight: 300; }

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2; }

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2; }

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1); }

small,
.small {
  font-size: 0.875em;
  font-weight: 400; }

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem; }

.blockquote-footer {
  display: block;
  font-size: 0.875em;
  color: #6c757d; }
  .blockquote-footer::before {
    content: "\2014\A0"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: white;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption {
  font-size: 90%;
  color: #6c757d; }

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-wrap: break-word; }
  a > code {
    color: inherit; }

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem; }
  kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 700; }

pre {
  display: block;
  font-size: 87.5%;
  color: #212529; }
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal; }

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll; }

.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 576px) {
  .container, .container-sm {
    max-width: 540px; } }

@media (min-width: 768px) {
  .container, .container-sm, .container-md {
    max-width: 720px; } }

@media (min-width: 992px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 960px; } }

@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1140px; } }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%; }

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%; }

.row-cols-3 > * {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%; }

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%; }

.row-cols-6 > * {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-sm-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-sm-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-md-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-md-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-lg-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-lg-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-xl-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-xl-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212121; }
  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6; }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6; }
  .table tbody + tbody {
    border-top: 2px solid #dee2e6; }

.table-sm th,
.table-sm td {
  padding: 0.3rem; }

.table-bordered {
  border: 1px solid #dee2e6; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6; }
  .table-bordered thead th,
  .table-bordered thead td {
    border-bottom-width: 2px; }

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05); }

.table-hover tbody tr:hover {
  color: #212121;
  background-color: rgba(0, 0, 0, 0.075); }

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #cac0c0; }

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #9d898b; }

.table-hover .table-primary:hover {
  background-color: #beb2b2; }
  .table-hover .table-primary:hover > td,
  .table-hover .table-primary:hover > th {
    background-color: #beb2b2; }

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: rgba(255, 255, 255, 0.72); }

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: rgba(255, 255, 255, 0.48); }

.table-hover .table-secondary:hover {
  background-color: rgba(242, 242, 242, 0.72); }
  .table-hover .table-secondary:hover > td,
  .table-hover .table-secondary:hover > th {
    background-color: rgba(242, 242, 242, 0.72); }

.table-success,
.table-success > th,
.table-success > td {
  background-color: #c1d7c2; }

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #8cb48e; }

.table-hover .table-success:hover {
  background-color: #b2cdb3; }
  .table-hover .table-success:hover > td,
  .table-hover .table-success:hover > th {
    background-color: #b2cdb3; }

.table-info,
.table-info > th,
.table-info > td {
  background-color: #dfd0cf; }

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #c3a7a7; }

.table-hover .table-info:hover {
  background-color: #d5c1c0; }
  .table-hover .table-info:hover > td,
  .table-hover .table-info:hover > th {
    background-color: #d5c1c0; }

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #c1c3c1; }

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #8c8f8c; }

.table-hover .table-warning:hover {
  background-color: #b4b6b4; }
  .table-hover .table-warning:hover > td,
  .table-hover .table-warning:hover > th {
    background-color: #b4b6b4; }

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #fbc0bd; }

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #f78a85; }

.table-hover .table-danger:hover {
  background-color: #faa9a5; }
  .table-hover .table-danger:hover > td,
  .table-hover .table-danger:hover > th {
    background-color: #faa9a5; }

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe; }

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #fbfcfc; }

.table-hover .table-light:hover {
  background-color: #ececf6; }
  .table-hover .table-light:hover > td,
  .table-hover .table-light:hover > th {
    background-color: #ececf6; }

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca; }

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #95999c; }

.table-hover .table-dark:hover {
  background-color: #b9bbbe; }
  .table-hover .table-dark:hover > td,
  .table-hover .table-dark:hover > th {
    background-color: #b9bbbe; }

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075); }

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075); }
  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: rgba(0, 0, 0, 0.075); }

.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55; }

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6; }

.table-dark {
  color: #fff;
  background-color: #343a40; }
  .table-dark th,
  .table-dark td,
  .table-dark thead th {
    border-color: #454d55; }
  .table-dark.table-bordered {
    border: 0; }
  .table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05); }
  .table-dark.table-hover tbody tr:hover {
    color: #fff;
    background-color: rgba(255, 255, 255, 0.075); }

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-sm > .table-bordered {
      border: 0; } }

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-md > .table-bordered {
      border: 0; } }

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-lg > .table-bordered {
      border: 0; } }

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-xl > .table-bordered {
      border: 0; } }

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; }
  .table-responsive > .table-bordered {
    border: 0; }

.form-control {
  display: block;
  width: 100%;
  height: calc(1.75em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.75;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.5rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .form-control {
      transition: none; } }
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #9c4448;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(67, 29, 31, 0.25); }
  .form-control::-webkit-input-placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control::placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control:disabled, .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1; }

input[type="date"].form-control,
input[type="time"].form-control,
input[type="datetime-local"].form-control,
input[type="month"].form-control {
  -webkit-appearance: none;
          appearance: none; }

select.form-control:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057; }

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff; }

.form-control-file,
.form-control-range {
  display: block;
  width: 100%; }

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.75; }

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5; }

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  font-size: 1rem;
  line-height: 1.75;
  color: #212121;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

select.form-control[size], select.form-control[multiple] {
  height: auto; }

textarea.form-control {
  height: auto; }

.form-group {
  margin-bottom: 1rem; }

.form-text {
  display: block;
  margin-top: 0.25rem; }

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem; }

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem; }
  .form-check-input[disabled] ~ .form-check-label,
  .form-check-input:disabled ~ .form-check-label {
    color: #6c757d; }

.form-check-label {
  margin-bottom: 0; }

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem; }
  .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #226F26; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.75;
  color: #fff;
  background-color: rgba(34, 111, 38, 0.9);
  border-radius: 0.25rem; }
  .form-row > .col > .valid-tooltip,
  .form-row > [class*="col-"] > .valid-tooltip {
    left: 5px; }

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #226F26;
  padding-right: calc(1.75em + 0.75rem) !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23226F26' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.4375em + 0.1875rem) center;
  background-size: calc(0.875em + 0.375rem) calc(0.875em + 0.375rem); }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus {
    border-color: #226F26;
    box-shadow: 0 0 0 0.2rem rgba(34, 111, 38, 0.25); }

.was-validated select.form-control:valid, select.form-control.is-valid {
  padding-right: 3rem !important;
  background-position: right 1.5rem center; }

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.75em + 0.75rem);
  background-position: top calc(0.4375em + 0.1875rem) right calc(0.4375em + 0.1875rem); }

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #226F26;
  padding-right: calc(0.75em + 2.3125rem) !important;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23226F26' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") center right 1.75rem/calc(0.875em + 0.375rem) calc(0.875em + 0.375rem) no-repeat; }
  .was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
    border-color: #226F26;
    box-shadow: 0 0 0 0.2rem rgba(34, 111, 38, 0.25); }

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #226F26; }

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #226F26; }
  .was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
    border-color: #226F26; }

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #2e9633;
  background-color: #2e9633; }

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(34, 111, 38, 0.25); }

.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #226F26; }

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #226F26; }

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #226F26;
  box-shadow: 0 0 0 0.2rem rgba(34, 111, 38, 0.25); }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #EF1E14; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.75;
  color: #fff;
  background-color: rgba(239, 30, 20, 0.9);
  border-radius: 0.25rem; }
  .form-row > .col > .invalid-tooltip,
  .form-row > [class*="col-"] > .invalid-tooltip {
    left: 5px; }

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #EF1E14;
  padding-right: calc(1.75em + 0.75rem) !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23EF1E14' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23EF1E14' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.4375em + 0.1875rem) center;
  background-size: calc(0.875em + 0.375rem) calc(0.875em + 0.375rem); }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
    border-color: #EF1E14;
    box-shadow: 0 0 0 0.2rem rgba(239, 30, 20, 0.25); }

.was-validated select.form-control:invalid, select.form-control.is-invalid {
  padding-right: 3rem !important;
  background-position: right 1.5rem center; }

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.75em + 0.75rem);
  background-position: top calc(0.4375em + 0.1875rem) right calc(0.4375em + 0.1875rem); }

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #EF1E14;
  padding-right: calc(0.75em + 2.3125rem) !important;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23EF1E14' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23EF1E14' stroke='none'/%3e%3c/svg%3e") center right 1.75rem/calc(0.875em + 0.375rem) calc(0.875em + 0.375rem) no-repeat; }
  .was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
    border-color: #EF1E14;
    box-shadow: 0 0 0 0.2rem rgba(239, 30, 20, 0.25); }

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #EF1E14; }

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #EF1E14; }
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
    border-color: #EF1E14; }

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #f24c44;
  background-color: #f24c44; }

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(239, 30, 20, 0.25); }

.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #EF1E14; }

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #EF1E14; }

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #EF1E14;
  box-shadow: 0 0 0 0.2rem rgba(239, 30, 20, 0.25); }

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center; }
  .form-inline .form-check {
    width: 100%; }
  @media (min-width: 576px) {
    .form-inline label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0; }
    .form-inline .form-group {
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 0; }
    .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-inline .form-control-plaintext {
      display: inline-block; }
    .form-inline .input-group,
    .form-inline .custom-select {
      width: auto; }
    .form-inline .form-check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      padding-left: 0; }
    .form-inline .form-check-input {
      position: relative;
      flex-shrink: 0;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    .form-inline .custom-control {
      align-items: center;
      justify-content: center; }
    .form-inline .custom-control-label {
      margin-bottom: 0; } }

.btn {
  display: inline-block;
  font-weight: 600;
  color: #212121;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
          user-select: none;
  background-color: transparent;
  border: 0.125rem solid transparent;
  padding: 0rem 1.25rem;
  font-size: 1rem;
  line-height: 2.25rem;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .btn {
      transition: none; } }
  .btn:hover {
    color: #212121;
    text-decoration: none; }
  .btn:focus, .btn.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(67, 29, 31, 0.25); }
  .btn.disabled, .btn:disabled {
    opacity: 0.65; }
  .btn:not(:disabled):not(.disabled) {
    cursor: pointer; }

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none; }

.btn-primary {
  color: #fff;
  background-color: #431D1F;
  border-color: #431D1F; }
  .btn-primary:hover {
    color: #fff;
    background-color: #281113;
    border-color: #1f0e0f; }
  .btn-primary:focus, .btn-primary.focus {
    color: #fff;
    background-color: #281113;
    border-color: #1f0e0f;
    box-shadow: 0 0 0 0.2rem rgba(95, 63, 65, 0.5); }
  .btn-primary.disabled, .btn-primary:disabled {
    color: #fff;
    background-color: #431D1F;
    border-color: #431D1F; }
  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #1f0e0f;
    border-color: #170a0a; }
    .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(95, 63, 65, 0.5); }

.btn-secondary {
  color: #fff;
  background-color: transparent;
  border-color: transparent; }
  .btn-secondary:hover {
    color: #fff;
    background-color: rgba(0, 0, 0, 0);
    border-color: rgba(0, 0, 0, 0); }
  .btn-secondary:focus, .btn-secondary.focus {
    color: #fff;
    background-color: rgba(0, 0, 0, 0);
    border-color: rgba(0, 0, 0, 0);
    box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5); }
  .btn-secondary.disabled, .btn-secondary:disabled {
    color: #fff;
    background-color: transparent;
    border-color: transparent; }
  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: rgba(0, 0, 0, 0);
    border-color: rgba(0, 0, 0, 0); }
    .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5); }

.btn-success {
  color: #fff;
  background-color: #226F26;
  border-color: #226F26; }
  .btn-success:hover {
    color: #fff;
    background-color: #19521c;
    border-color: #164819; }
  .btn-success:focus, .btn-success.focus {
    color: #fff;
    background-color: #19521c;
    border-color: #164819;
    box-shadow: 0 0 0 0.2rem rgba(67, 133, 71, 0.5); }
  .btn-success.disabled, .btn-success:disabled {
    color: #fff;
    background-color: #226F26;
    border-color: #226F26; }
  .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
  .show > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #164819;
    border-color: #133e15; }
    .btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(67, 133, 71, 0.5); }

.btn-info {
  color: #fff;
  background-color: #8C5655;
  border-color: #8C5655; }
  .btn-info:hover {
    color: #fff;
    background-color: #744747;
    border-color: #6c4342; }
  .btn-info:focus, .btn-info.focus {
    color: #fff;
    background-color: #744747;
    border-color: #6c4342;
    box-shadow: 0 0 0 0.2rem rgba(157, 111, 111, 0.5); }
  .btn-info.disabled, .btn-info:disabled {
    color: #fff;
    background-color: #8C5655;
    border-color: #8C5655; }
  .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
  .show > .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #6c4342;
    border-color: #643e3d; }
    .btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(157, 111, 111, 0.5); }

.btn-warning {
  color: #fff;
  background-color: #212721;
  border-color: #212721; }
  .btn-warning:hover {
    color: #fff;
    background-color: #0f120f;
    border-color: #0a0b0a; }
  .btn-warning:focus, .btn-warning.focus {
    color: #fff;
    background-color: #0f120f;
    border-color: #0a0b0a;
    box-shadow: 0 0 0 0.2rem rgba(66, 71, 66, 0.5); }
  .btn-warning.disabled, .btn-warning:disabled {
    color: #fff;
    background-color: #212721;
    border-color: #212721; }
  .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
  .show > .btn-warning.dropdown-toggle {
    color: #fff;
    background-color: #0a0b0a;
    border-color: #040404; }
    .btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(66, 71, 66, 0.5); }

.btn-danger {
  color: #fff;
  background-color: #EF1E14;
  border-color: #EF1E14; }
  .btn-danger:hover {
    color: #fff;
    background-color: #cf170e;
    border-color: #c3160d; }
  .btn-danger:focus, .btn-danger.focus {
    color: #fff;
    background-color: #cf170e;
    border-color: #c3160d;
    box-shadow: 0 0 0 0.2rem rgba(241, 64, 55, 0.5); }
  .btn-danger.disabled, .btn-danger:disabled {
    color: #fff;
    background-color: #EF1E14;
    border-color: #EF1E14; }
  .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
  .show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #c3160d;
    border-color: #b7140c; }
    .btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(241, 64, 55, 0.5); }

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-light:hover {
    color: #212529;
    background-color: #e2e6ea;
    border-color: #dae0e5; }
  .btn-light:focus, .btn-light.focus {
    color: #212529;
    background-color: #e2e6ea;
    border-color: #dae0e5;
    box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5); }
  .btn-light.disabled, .btn-light:disabled {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
  .show > .btn-light.dropdown-toggle {
    color: #212529;
    background-color: #dae0e5;
    border-color: #d3d9df; }
    .btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5); }

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40; }
  .btn-dark:hover {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124; }
  .btn-dark:focus, .btn-dark.focus {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124;
    box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5); }
  .btn-dark.disabled, .btn-dark:disabled {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
  .show > .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #1d2124;
    border-color: #171a1d; }
    .btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5); }

.btn-outline-primary {
  color: #431D1F;
  border-color: #431D1F; }
  .btn-outline-primary:hover {
    color: #fff;
    background-color: #431D1F;
    border-color: #431D1F; }
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(67, 29, 31, 0.5); }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #431D1F;
    background-color: transparent; }
  .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #431D1F;
    border-color: #431D1F; }
    .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(67, 29, 31, 0.5); }

.btn-outline-secondary {
  color: transparent;
  border-color: transparent; }
  .btn-outline-secondary:hover {
    color: #fff;
    background-color: transparent;
    border-color: transparent; }
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.5); }
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: transparent;
    background-color: transparent; }
  .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: transparent;
    border-color: transparent; }
    .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.5); }

.btn-outline-success {
  color: #226F26;
  border-color: #226F26; }
  .btn-outline-success:hover {
    color: #fff;
    background-color: #226F26;
    border-color: #226F26; }
  .btn-outline-success:focus, .btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(34, 111, 38, 0.5); }
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #226F26;
    background-color: transparent; }
  .btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #226F26;
    border-color: #226F26; }
    .btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(34, 111, 38, 0.5); }

.btn-outline-info {
  color: #8C5655;
  border-color: #8C5655; }
  .btn-outline-info:hover {
    color: #fff;
    background-color: #8C5655;
    border-color: #8C5655; }
  .btn-outline-info:focus, .btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(140, 86, 85, 0.5); }
  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #8C5655;
    background-color: transparent; }
  .btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #8C5655;
    border-color: #8C5655; }
    .btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(140, 86, 85, 0.5); }

.btn-outline-warning {
  color: #212721;
  border-color: #212721; }
  .btn-outline-warning:hover {
    color: #fff;
    background-color: #212721;
    border-color: #212721; }
  .btn-outline-warning:focus, .btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(33, 39, 33, 0.5); }
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #212721;
    background-color: transparent; }
  .btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .btn-outline-warning.dropdown-toggle {
    color: #fff;
    background-color: #212721;
    border-color: #212721; }
    .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(33, 39, 33, 0.5); }

.btn-outline-danger {
  color: #EF1E14;
  border-color: #EF1E14; }
  .btn-outline-danger:hover {
    color: #fff;
    background-color: #EF1E14;
    border-color: #EF1E14; }
  .btn-outline-danger:focus, .btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(239, 30, 20, 0.5); }
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #EF1E14;
    background-color: transparent; }
  .btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #EF1E14;
    border-color: #EF1E14; }
    .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(239, 30, 20, 0.5); }

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-outline-light:hover {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-outline-light:focus, .btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }
  .btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #f8f9fa;
    background-color: transparent; }
  .btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-light.dropdown-toggle {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
    .btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.btn-outline-dark {
  color: #343a40;
  border-color: #343a40; }
  .btn-outline-dark:hover {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-outline-dark:focus, .btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  .btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #343a40;
    background-color: transparent; }
  .btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
    .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.btn-link {
  font-weight: 400;
  color: #431D1F;
  text-decoration: none; }
  .btn-link:hover {
    color: #5b0e0d;
    text-decoration: none; }
  .btn-link:focus, .btn-link.focus {
    text-decoration: none; }
  .btn-link:disabled, .btn-link.disabled {
    color: #6c757d;
    pointer-events: none; }

.btn-lg, .btn-group-lg > .btn {
  padding: 0rem 2.188rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.btn-block {
  display: block;
  width: 100%; }
  .btn-block + .btn-block {
    margin-top: 0.5rem; }

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%; }

.fade {
  transition: opacity 0.15s linear; }
  @media (prefers-reduced-motion: reduce) {
    .fade {
      transition: none; } }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }
  @media (prefers-reduced-motion: reduce) {
    .collapsing {
      transition: none; } }
  .collapsing.width {
    width: 0;
    height: auto;
    transition: width 0.35s ease; }
    @media (prefers-reduced-motion: reduce) {
      .collapsing.width {
        transition: none; } }

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative; }

.dropdown-toggle {
  white-space: nowrap; }
  .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent; }
  .dropdown-toggle:empty::after {
    margin-left: 0; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212121;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem; }

.dropdown-menu-left {
  right: auto;
  left: 0; }

.dropdown-menu-right {
  right: 0;
  left: auto; }

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0; }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto; } }

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0; }
  .dropdown-menu-md-right {
    right: 0;
    left: auto; } }

@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0; }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto; } }

@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0; }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto; } }

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem; }

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem; }

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }

.dropright .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-toggle::after {
  vertical-align: 0; }

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem; }

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }

.dropleft .dropdown-toggle::after {
  display: none; }

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropleft .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto; }

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:hover, .dropdown-item:focus {
    color: #16181b;
    text-decoration: none;
    background-color: #e9ecef; }
  .dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #431D1F; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #adb5bd;
    pointer-events: none;
    background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap; }

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529; }

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle; }
  .btn-group > .btn,
  .btn-group-vertical > .btn {
    position: relative;
    flex: 1 1 auto; }
    .btn-group > .btn:hover,
    .btn-group-vertical > .btn:hover {
      z-index: 1; }
    .btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
    .btn-group-vertical > .btn:focus,
    .btn-group-vertical > .btn:active,
    .btn-group-vertical > .btn.active {
      z-index: 1; }

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  .btn-toolbar .input-group {
    width: auto; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -0.125rem; }

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.dropdown-toggle-split {
  padding-right: 0.9375rem;
  padding-left: 0.9375rem; }
  .dropdown-toggle-split::after,
  .dropup .dropdown-toggle-split::after,
  .dropright .dropdown-toggle-split::after {
    margin-left: 0; }
  .dropleft .dropdown-toggle-split::before {
    margin-right: 0; }

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem; }

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 1.641rem;
  padding-left: 1.641rem; }

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center; }
  .btn-group-vertical > .btn,
  .btn-group-vertical > .btn-group {
    width: 100%; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) {
    margin-top: -0.125rem; }
  .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
  .btn-group-vertical > .btn-group:not(:last-child) > .btn {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0; }
  .btn-group-toggle > .btn input[type="radio"],
  .btn-group-toggle > .btn input[type="checkbox"],
  .btn-group-toggle > .btn-group > .btn input[type="radio"],
  .btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none; }

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%; }
  .input-group > .form-control,
  .input-group > .form-control-plaintext,
  .input-group > .custom-select,
  .input-group > .custom-file {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0;
    margin-bottom: 0; }
    .input-group > .form-control + .form-control,
    .input-group > .form-control + .custom-select,
    .input-group > .form-control + .custom-file,
    .input-group > .form-control-plaintext + .form-control,
    .input-group > .form-control-plaintext + .custom-select,
    .input-group > .form-control-plaintext + .custom-file,
    .input-group > .custom-select + .form-control,
    .input-group > .custom-select + .custom-select,
    .input-group > .custom-select + .custom-file,
    .input-group > .custom-file + .form-control,
    .input-group > .custom-file + .custom-select,
    .input-group > .custom-file + .custom-file {
      margin-left: -1px; }
  .input-group > .form-control:focus,
  .input-group > .custom-select:focus,
  .input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
    z-index: 3; }
  .input-group > .custom-file .custom-file-input:focus {
    z-index: 4; }
  .input-group > .form-control:not(:first-child),
  .input-group > .custom-select:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .input-group > .custom-file {
    display: flex;
    align-items: center; }
    .input-group > .custom-file:not(:last-child) .custom-file-label,
    .input-group > .custom-file:not(:last-child) .custom-file-label::after {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0; }
    .input-group > .custom-file:not(:first-child) .custom-file-label {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0; }
  .input-group:not(.has-validation) > .form-control:not(:last-child),
  .input-group:not(.has-validation) > .custom-select:not(:last-child),
  .input-group:not(.has-validation) > .custom-file:not(:last-child) .custom-file-label,
  .input-group:not(.has-validation) > .custom-file:not(:last-child) .custom-file-label::after {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .input-group.has-validation > .form-control:nth-last-child(n + 3),
  .input-group.has-validation > .custom-select:nth-last-child(n + 3),
  .input-group.has-validation > .custom-file:nth-last-child(n + 3) .custom-file-label,
  .input-group.has-validation > .custom-file:nth-last-child(n + 3) .custom-file-label::after {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }

.input-group-prepend,
.input-group-append {
  display: flex; }
  .input-group-prepend .btn,
  .input-group-append .btn {
    position: relative;
    z-index: 2; }
    .input-group-prepend .btn:focus,
    .input-group-append .btn:focus {
      z-index: 3; }
  .input-group-prepend .btn + .btn,
  .input-group-prepend .btn + .input-group-text,
  .input-group-prepend .input-group-text + .input-group-text,
  .input-group-prepend .input-group-text + .btn,
  .input-group-append .btn + .btn,
  .input-group-append .btn + .input-group-text,
  .input-group-append .input-group-text + .input-group-text,
  .input-group-append .input-group-text + .btn {
    margin-left: -1px; }

.input-group-prepend {
  margin-right: -1px; }

.input-group-append {
  margin-left: -1px; }

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.75;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.5rem; }
  .input-group-text input[type="radio"],
  .input-group-text input[type="checkbox"] {
    margin-top: 0; }

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 1rem + 2px); }

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 0.5rem + 2px); }

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem; }

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .btn,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .input-group-text,
.input-group.has-validation > .input-group-append:nth-last-child(n + 3) > .btn,
.input-group.has-validation > .input-group-append:nth-last-child(n + 3) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.custom-control {
  position: relative;
  z-index: 1;
  display: block;
  min-height: 1.75rem;
  padding-left: 1.5rem;
  print-color-adjust: exact; }

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem; }

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.375rem;
  opacity: 0; }
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: #431D1F;
    background-color: #431D1F; }
  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 0.2rem rgba(67, 29, 31, 0.25); }
  .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
    border-color: #9c4448; }
  .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
    color: #fff;
    background-color: #b85b60;
    border-color: #b85b60; }
  .custom-control-input[disabled] ~ .custom-control-label, .custom-control-input:disabled ~ .custom-control-label {
    color: #6c757d; }
    .custom-control-input[disabled] ~ .custom-control-label::before, .custom-control-input:disabled ~ .custom-control-label::before {
      background-color: #e9ecef; }

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top; }
  .custom-control-label::before {
    position: absolute;
    top: 0.375rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    background-color: #fff;
    border: 1px solid #adb5bd; }
  .custom-control-label::after {
    position: absolute;
    top: 0.375rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background: 50% / 50% 50% no-repeat; }

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #431D1F;
  background-color: #431D1F; }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(67, 29, 31, 0.5); }

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(67, 29, 31, 0.5); }

.custom-radio .custom-control-label::before {
  border-radius: 50%; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e"); }

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(67, 29, 31, 0.5); }

.custom-switch {
  padding-left: 2.25rem; }
  .custom-switch .custom-control-label::before {
    left: -2.25rem;
    width: 1.75rem;
    pointer-events: all;
    border-radius: 0.5rem; }
  .custom-switch .custom-control-label::after {
    top: calc(0.375rem + 2px);
    left: calc(-2.25rem + 2px);
    width: calc(1rem - 4px);
    height: calc(1rem - 4px);
    background-color: #adb5bd;
    border-radius: 0.5rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out;
    transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .custom-switch .custom-control-label::after {
        transition: none; } }
  .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    background-color: #fff;
    -webkit-transform: translateX(0.75rem);
            transform: translateX(0.75rem); }
  .custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: rgba(67, 29, 31, 0.5); }

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.75em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.75;
  color: #495057;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  -webkit-appearance: none;
          appearance: none; }
  .custom-select:focus {
    border-color: #9c4448;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(67, 29, 31, 0.25); }
    .custom-select:focus::-ms-value {
      color: #495057;
      background-color: #fff; }
  .custom-select[multiple], .custom-select[size]:not([size="1"]) {
    height: auto;
    padding-right: 0.75rem;
    background-image: none; }
  .custom-select:disabled {
    color: #6c757d;
    background-color: #e9ecef; }
  .custom-select::-ms-expand {
    display: none; }
  .custom-select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #495057; }

.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem; }

.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem; }

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.75em + 0.75rem + 2px);
  margin-bottom: 0; }

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.75em + 0.75rem + 2px);
  margin: 0;
  overflow: hidden;
  opacity: 0; }
  .custom-file-input:focus ~ .custom-file-label {
    border-color: #9c4448;
    box-shadow: 0 0 0 0.2rem rgba(67, 29, 31, 0.25); }
  .custom-file-input[disabled] ~ .custom-file-label,
  .custom-file-input:disabled ~ .custom-file-label {
    background-color: #e9ecef; }
  .custom-file-input:lang(en) ~ .custom-file-label::after {
    content: "Browse"; }
  .custom-file-input ~ .custom-file-label[data-browse]::after {
    content: attr(data-browse); }

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.75em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  overflow: hidden;
  font-weight: 400;
  line-height: 1.75;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.5rem; }
  .custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: calc(1.75em + 0.75rem);
    padding: 0.375rem 0.75rem;
    line-height: 1.75;
    color: #495057;
    content: "Browse";
    background-color: #e9ecef;
    border-left: inherit;
    border-radius: 0 0.5rem 0.5rem 0; }

.custom-range {
  width: 100%;
  height: 1.4rem;
  padding: 0;
  background-color: transparent;
  -webkit-appearance: none;
          appearance: none; }
  .custom-range:focus {
    outline: 0; }
    .custom-range:focus::-webkit-slider-thumb {
      box-shadow: 0 0 0 1px white, 0 0 0 0.2rem rgba(67, 29, 31, 0.25); }
    .custom-range:focus::-moz-range-thumb {
      box-shadow: 0 0 0 1px white, 0 0 0 0.2rem rgba(67, 29, 31, 0.25); }
    .custom-range:focus::-ms-thumb {
      box-shadow: 0 0 0 1px white, 0 0 0 0.2rem rgba(67, 29, 31, 0.25); }
  .custom-range::-moz-focus-outer {
    border: 0; }
  .custom-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #431D1F;
    border: 0;
    border-radius: 1rem;
    -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    -webkit-appearance: none;
            appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-webkit-slider-thumb {
        -webkit-transition: none;
        transition: none; } }
    .custom-range::-webkit-slider-thumb:active {
      background-color: #b85b60; }
  .custom-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #431D1F;
    border: 0;
    border-radius: 1rem;
    -moz-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-moz-range-thumb {
        -moz-transition: none;
        transition: none; } }
    .custom-range::-moz-range-thumb:active {
      background-color: #b85b60; }
  .custom-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-ms-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: 0;
    margin-right: 0.2rem;
    margin-left: 0.2rem;
    background-color: #431D1F;
    border: 0;
    border-radius: 1rem;
    -ms-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-ms-thumb {
        -ms-transition: none;
        transition: none; } }
    .custom-range::-ms-thumb:active {
      background-color: #b85b60; }
  .custom-range::-ms-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: transparent;
    border-color: transparent;
    border-width: 0.5rem; }
  .custom-range::-ms-fill-lower {
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range::-ms-fill-upper {
    margin-right: 15px;
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range:disabled::-webkit-slider-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-webkit-slider-runnable-track {
    cursor: default; }
  .custom-range:disabled::-moz-range-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-moz-range-track {
    cursor: default; }
  .custom-range:disabled::-ms-thumb {
    background-color: #adb5bd; }

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .custom-control-label::before,
    .custom-file-label,
    .custom-select {
      transition: none; } }

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.5rem 1rem; }
  .nav-link:hover, .nav-link:focus {
    text-decoration: none; }
  .nav-link.disabled {
    color: #6c757d;
    pointer-events: none;
    cursor: default; }

.nav-tabs {
  border-bottom: 1px solid #dee2e6; }
  .nav-tabs .nav-link {
    margin-bottom: -1px;
    background-color: transparent;
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      isolation: isolate;
      border-color: #e9ecef #e9ecef #dee2e6; }
    .nav-tabs .nav-link.disabled {
      color: #6c757d;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #495057;
    background-color: white;
    border-color: #dee2e6 #dee2e6 white; }
  .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills .nav-link {
  background: none;
  border: 0;
  border-radius: 0.25rem; }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #431D1F; }

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 1.3em 1rem; }
  .navbar .container,
  .navbar .container-fluid, .navbar .container-sm, .navbar .container-md, .navbar .container-lg, .navbar .container-xl {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between; }

.navbar-brand {
  display: inline-block;
  padding-top: 0.28125rem;
  padding-bottom: 0.28125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap; }
  .navbar-brand:hover, .navbar-brand:focus {
    text-decoration: none; }

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0; }
  .navbar-nav .dropdown-menu {
    position: static;
    float: none; }

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center; }

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem; }
  .navbar-toggler:hover, .navbar-toggler:focus {
    text-decoration: none; }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: 50% / 100% 100% no-repeat; }

.navbar-nav-scroll {
  max-height: 75vh;
  overflow-y: auto; }

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-sm .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-sm .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-sm .navbar-toggler {
      display: none; } }

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-md .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-md .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-md .navbar-toggler {
      display: none; } }

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-lg .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-lg .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
      display: none; } }

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-xl .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-xl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xl .navbar-toggler {
      display: none; } }

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl {
    padding-right: 0;
    padding-left: 0; }
  .navbar-expand .navbar-nav {
    flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl {
    flex-wrap: nowrap; }
  .navbar-expand .navbar-nav-scroll {
    overflow: visible; }
  .navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
  .navbar-expand .navbar-toggler {
    display: none; }

.navbar-light .navbar-brand {
  color: #ffffff; }
  .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
    color: #ffffff; }

.navbar-light .navbar-nav .nav-link {
  color: #CBA2A7; }
  .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: #ffffff; }
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3); }

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: #ffffff; }

.navbar-light .navbar-toggler {
  color: #CBA2A7;
  border-color: rgba(0, 0, 0, 0.1); }

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='%23CBA2A7' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-light .navbar-text {
  color: #CBA2A7; }
  .navbar-light .navbar-text a {
    color: #ffffff; }
    .navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
      color: #ffffff; }

.navbar-dark .navbar-brand {
  color: #fff; }
  .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
    color: #fff; }

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.75); }
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25); }

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff; }

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1); }

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-text a {
    color: #fff; }
    .navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
      color: #fff; }

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem; }
  .card > hr {
    margin-right: 0;
    margin-left: 0; }
  .card > .list-group {
    border-top: inherit;
    border-bottom: inherit; }
    .card > .list-group:first-child {
      border-top-width: 0;
      border-top-left-radius: calc(0.25rem - 1px);
      border-top-right-radius: calc(0.25rem - 1px); }
    .card > .list-group:last-child {
      border-bottom-width: 0;
      border-bottom-right-radius: calc(0.25rem - 1px);
      border-bottom-left-radius: calc(0.25rem - 1px); }
  .card > .card-header + .list-group,
  .card > .list-group + .card-footer {
    border-top: 0; }

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem; }

.card-title {
  margin-bottom: 0.75rem; }

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link:hover {
  text-decoration: none; }

.card-link + .card-link {
  margin-left: 1.25rem; }

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125); }
  .card-header:first-child {
    border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0; }

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125); }
  .card-footer:last-child {
    border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px); }

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0; }

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem; }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
  border-radius: calc(0.25rem - 1px); }

.card-img,
.card-img-top,
.card-img-bottom {
  flex-shrink: 0;
  width: 100%; }

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px); }

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px); }

.card-deck .card {
  margin-bottom: 15px; }

@media (min-width: 576px) {
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px; }
    .card-deck .card {
      flex: 1 0;
      margin-right: 15px;
      margin-bottom: 0;
      margin-left: 15px; } }

.card-group > .card {
  margin-bottom: 15px; }

@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap; }
    .card-group > .card {
      flex: 1 0;
      margin-bottom: 0; }
      .card-group > .card + .card {
        margin-left: 0;
        border-left: 0; }
      .card-group > .card:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-top,
        .card-group > .card:not(:last-child) .card-header {
          border-top-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-bottom,
        .card-group > .card:not(:last-child) .card-footer {
          border-bottom-right-radius: 0; }
      .card-group > .card:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-top,
        .card-group > .card:not(:first-child) .card-header {
          border-top-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-bottom,
        .card-group > .card:not(:first-child) .card-footer {
          border-bottom-left-radius: 0; } }

.card-columns .card {
  margin-bottom: 0.75rem; }

@media (min-width: 576px) {
  .card-columns {
    -webkit-column-count: 3;
            column-count: 3;
    grid-column-gap: 1.25rem;
    -webkit-column-gap: 1.25rem;
            column-gap: 1.25rem;
    orphans: 1;
    widows: 1; }
    .card-columns .card {
      display: inline-block;
      width: 100%; } }

.accordion {
  overflow-anchor: none; }
  .accordion > .card {
    overflow: hidden; }
    .accordion > .card:not(:last-of-type) {
      border-bottom: 0;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0; }
    .accordion > .card:not(:first-of-type) {
      border-top-left-radius: 0;
      border-top-right-radius: 0; }
    .accordion > .card > .card-header {
      border-radius: 0;
      margin-bottom: -1px; }

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.25rem; }

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem; }
  .breadcrumb-item + .breadcrumb-item::before {
    float: left;
    padding-right: 0.5rem;
    color: #6c757d;
    content: "/"; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none; }

.breadcrumb-item.active {
  color: #6c757d; }

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem; }

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #431D1F;
  background-color: #fff;
  border: 1px solid #dee2e6; }
  .page-link:hover {
    z-index: 2;
    color: #5b0e0d;
    text-decoration: none;
    background-color: #e9ecef;
    border-color: #dee2e6; }
  .page-link:focus {
    z-index: 3;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(67, 29, 31, 0.25); }

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem; }

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem; }

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #431D1F;
  border-color: #431D1F; }

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6; }

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5; }

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem; }

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem; }

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5; }

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem; }

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem; }

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .badge {
      transition: none; } }
  a.badge:hover, a.badge:focus {
    text-decoration: none; }
  .badge:empty {
    display: none; }

.btn .badge {
  position: relative;
  top: -1px; }

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem; }

.badge-primary {
  color: #fff;
  background-color: #431D1F; }
  a.badge-primary:hover, a.badge-primary:focus {
    color: #fff;
    background-color: #1f0e0f; }
  a.badge-primary:focus, a.badge-primary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(67, 29, 31, 0.5); }

.badge-secondary {
  color: #fff;
  background-color: transparent; }
  a.badge-secondary:hover, a.badge-secondary:focus {
    color: #fff;
    background-color: rgba(0, 0, 0, 0); }
  a.badge-secondary:focus, a.badge-secondary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.5); }

.badge-success {
  color: #fff;
  background-color: #226F26; }
  a.badge-success:hover, a.badge-success:focus {
    color: #fff;
    background-color: #164819; }
  a.badge-success:focus, a.badge-success.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(34, 111, 38, 0.5); }

.badge-info {
  color: #fff;
  background-color: #8C5655; }
  a.badge-info:hover, a.badge-info:focus {
    color: #fff;
    background-color: #6c4342; }
  a.badge-info:focus, a.badge-info.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(140, 86, 85, 0.5); }

.badge-warning {
  color: #fff;
  background-color: #212721; }
  a.badge-warning:hover, a.badge-warning:focus {
    color: #fff;
    background-color: #0a0b0a; }
  a.badge-warning:focus, a.badge-warning.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(33, 39, 33, 0.5); }

.badge-danger {
  color: #fff;
  background-color: #EF1E14; }
  a.badge-danger:hover, a.badge-danger:focus {
    color: #fff;
    background-color: #c3160d; }
  a.badge-danger:focus, a.badge-danger.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(239, 30, 20, 0.5); }

.badge-light {
  color: #212529;
  background-color: #f8f9fa; }
  a.badge-light:hover, a.badge-light:focus {
    color: #212529;
    background-color: #dae0e5; }
  a.badge-light:focus, a.badge-light.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.badge-dark {
  color: #fff;
  background-color: #343a40; }
  a.badge-dark:hover, a.badge-dark:focus {
    color: #fff;
    background-color: #1d2124; }
  a.badge-dark:focus, a.badge-dark.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem; }
  @media (min-width: 576px) {
    .jumbotron {
      padding: 4rem 2rem; } }

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0; }

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem; }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: 700; }

.alert-dismissible {
  padding-right: 4rem; }
  .alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    padding: 0.75rem 1.25rem;
    color: inherit; }

.alert-primary {
  color: #230f10;
  background-color: #d9d2d2;
  border-color: #cac0c0; }
  .alert-primary hr {
    border-top-color: #beb2b2; }
  .alert-primary .alert-link {
    color: black; }

.alert-secondary {
  color: rgba(0, 0, 0, 0.48);
  background-color: rgba(255, 255, 255, 0.8);
  border-color: rgba(255, 255, 255, 0.72); }
  .alert-secondary hr {
    border-top-color: rgba(242, 242, 242, 0.72); }
  .alert-secondary .alert-link {
    color: rgba(0, 0, 0, 0.48); }

.alert-success {
  color: #123a14;
  background-color: #d3e2d4;
  border-color: #c1d7c2; }
  .alert-success hr {
    border-top-color: #b2cdb3; }
  .alert-success .alert-link {
    color: #061307; }

.alert-info {
  color: #492d2c;
  background-color: #e8dddd;
  border-color: #dfd0cf; }
  .alert-info hr {
    border-top-color: #d5c1c0; }
  .alert-info .alert-link {
    color: #291919; }

.alert-warning {
  color: #111411;
  background-color: #d3d4d3;
  border-color: #c1c3c1; }
  .alert-warning hr {
    border-top-color: #b4b6b4; }
  .alert-warning .alert-link {
    color: black; }

.alert-danger {
  color: #7c100a;
  background-color: #fcd2d0;
  border-color: #fbc0bd; }
  .alert-danger hr {
    border-top-color: #faa9a5; }
  .alert-danger .alert-link {
    color: #4d0a06; }

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe; }
  .alert-light hr {
    border-top-color: #ececf6; }
  .alert-light .alert-link {
    color: #686868; }

.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca; }
  .alert-dark hr {
    border-top-color: #b9bbbe; }
  .alert-dark .alert-link {
    color: #040505; }

@-webkit-keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0; }
  to {
    background-position: 0 0; } }

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0; }
  to {
    background-position: 0 0; } }

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  line-height: 0;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem; }

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #431D1F;
  transition: width 0.6s ease; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar {
      transition: none; } }

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem; }

.progress-bar-animated {
  -webkit-animation: 1s linear infinite progress-bar-stripes;
          animation: 1s linear infinite progress-bar-stripes; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar-animated {
      -webkit-animation: none;
              animation: none; } }

.media {
  display: flex;
  align-items: flex-start; }

.media-body {
  flex: 1 1; }

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.25rem; }

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit; }
  .list-group-item-action:hover, .list-group-item-action:focus {
    z-index: 1;
    color: #495057;
    text-decoration: none;
    background-color: #f8f9fa; }
  .list-group-item-action:active {
    color: #212121;
    background-color: #e9ecef; }

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125); }
  .list-group-item:first-child {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit; }
  .list-group-item:last-child {
    border-bottom-right-radius: inherit;
    border-bottom-left-radius: inherit; }
  .list-group-item.disabled, .list-group-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: #fff; }
  .list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #431D1F;
    border-color: #431D1F; }
  .list-group-item + .list-group-item {
    border-top-width: 0; }
    .list-group-item + .list-group-item.active {
      margin-top: -1px;
      border-top-width: 1px; }

.list-group-horizontal {
  flex-direction: row; }
  .list-group-horizontal > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0; }
  .list-group-horizontal > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0; }
  .list-group-horizontal > .list-group-item.active {
    margin-top: 0; }
  .list-group-horizontal > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0; }
    .list-group-horizontal > .list-group-item + .list-group-item.active {
      margin-left: -1px;
      border-left-width: 1px; }

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row; }
    .list-group-horizontal-sm > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-sm > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-sm > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-sm > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row; }
    .list-group-horizontal-md > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-md > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-md > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-md > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-md > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row; }
    .list-group-horizontal-lg > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-lg > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-lg > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-lg > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row; }
    .list-group-horizontal-xl > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-xl > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-xl > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-xl > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

.list-group-flush {
  border-radius: 0; }
  .list-group-flush > .list-group-item {
    border-width: 0 0 1px; }
    .list-group-flush > .list-group-item:last-child {
      border-bottom-width: 0; }

.list-group-item-primary {
  color: #230f10;
  background-color: #cac0c0; }
  .list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
    color: #230f10;
    background-color: #beb2b2; }
  .list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: #230f10;
    border-color: #230f10; }

.list-group-item-secondary {
  color: rgba(0, 0, 0, 0.48);
  background-color: rgba(255, 255, 255, 0.72); }
  .list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
    color: rgba(0, 0, 0, 0.48);
    background-color: rgba(242, 242, 242, 0.72); }
  .list-group-item-secondary.list-group-item-action.active {
    color: #fff;
    background-color: rgba(0, 0, 0, 0.48);
    border-color: rgba(0, 0, 0, 0.48); }

.list-group-item-success {
  color: #123a14;
  background-color: #c1d7c2; }
  .list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
    color: #123a14;
    background-color: #b2cdb3; }
  .list-group-item-success.list-group-item-action.active {
    color: #fff;
    background-color: #123a14;
    border-color: #123a14; }

.list-group-item-info {
  color: #492d2c;
  background-color: #dfd0cf; }
  .list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
    color: #492d2c;
    background-color: #d5c1c0; }
  .list-group-item-info.list-group-item-action.active {
    color: #fff;
    background-color: #492d2c;
    border-color: #492d2c; }

.list-group-item-warning {
  color: #111411;
  background-color: #c1c3c1; }
  .list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
    color: #111411;
    background-color: #b4b6b4; }
  .list-group-item-warning.list-group-item-action.active {
    color: #fff;
    background-color: #111411;
    border-color: #111411; }

.list-group-item-danger {
  color: #7c100a;
  background-color: #fbc0bd; }
  .list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
    color: #7c100a;
    background-color: #faa9a5; }
  .list-group-item-danger.list-group-item-action.active {
    color: #fff;
    background-color: #7c100a;
    border-color: #7c100a; }

.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe; }
  .list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
    color: #818182;
    background-color: #ececf6; }
  .list-group-item-light.list-group-item-action.active {
    color: #fff;
    background-color: #818182;
    border-color: #818182; }

.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca; }
  .list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
    color: #1b1e21;
    background-color: #b9bbbe; }
  .list-group-item-dark.list-group-item-action.active {
    color: #fff;
    background-color: #1b1e21;
    border-color: #1b1e21; }

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5; }
  .close:hover {
    color: #000;
    text-decoration: none; }
  .close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
    opacity: .75; }

button.close {
  padding: 0;
  background-color: transparent;
  border: 0; }

a.close.disabled {
  pointer-events: none; }

.toast {
  flex-basis: 350px;
  max-width: 350px;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  opacity: 0;
  border-radius: 0.25rem; }
  .toast:not(:last-child) {
    margin-bottom: 0.75rem; }
  .toast.showing {
    opacity: 1; }
  .toast.show {
    display: block;
    opacity: 1; }
  .toast.hide {
    display: none; }

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px); }

.toast-body {
  padding: 0.75rem; }

.modal-open {
  overflow: hidden; }
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto; }

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none; }
  .modal.fade .modal-dialog {
    transition: -webkit-transform 0.3s ease-out;
    transition: transform 0.3s ease-out;
    transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
    -webkit-transform: translate(0, -50px);
            transform: translate(0, -50px); }
    @media (prefers-reduced-motion: reduce) {
      .modal.fade .modal-dialog {
        transition: none; } }
  .modal.show .modal-dialog {
    -webkit-transform: none;
            transform: none; }
  .modal.modal-static .modal-dialog {
    -webkit-transform: scale(1.02);
            transform: scale(1.02); }

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem); }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 1rem);
    overflow: hidden; }
  .modal-dialog-scrollable .modal-header,
  .modal-dialog-scrollable .modal-footer {
    flex-shrink: 0; }
  .modal-dialog-scrollable .modal-body {
    overflow-y: auto; }

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem); }
  .modal-dialog-centered::before {
    display: block;
    height: calc(100vh - 1rem);
    height: -webkit-min-content;
    height: min-content;
    content: ""; }
  .modal-dialog-centered.modal-dialog-scrollable {
    flex-direction: column;
    justify-content: center;
    height: 100%; }
    .modal-dialog-centered.modal-dialog-scrollable .modal-content {
      max-height: none; }
    .modal-dialog-centered.modal-dialog-scrollable::before {
      content: none; }

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.5; }

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .modal-header .close {
    padding: 1rem 1rem;
    margin: -1rem -1rem -1rem auto; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.75; }

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem; }

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px); }
  .modal-footer > * {
    margin: 0.25rem; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto; }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem); }
    .modal-dialog-scrollable .modal-content {
      max-height: calc(100vh - 3.5rem); }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem); }
    .modal-dialog-centered::before {
      height: calc(100vh - 3.5rem);
      height: -webkit-min-content;
      height: min-content; }
  .modal-sm {
    max-width: 300px; } }

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px; } }

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px; } }

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.75;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0; }
  .tooltip.show {
    opacity: 0.9; }
  .tooltip .arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem; }
    .tooltip .arrow::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0; }
  .bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
    bottom: 0; }
    .bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
      top: 0;
      border-width: 0.4rem 0.4rem 0;
      border-top-color: #000; }

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem; }
  .bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
      right: 0;
      border-width: 0.4rem 0.4rem 0.4rem 0;
      border-right-color: #000; }

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0; }
  .bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
    top: 0; }
    .bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
      bottom: 0;
      border-width: 0 0.4rem 0.4rem;
      border-bottom-color: #000; }

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem; }
  .bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
      left: 0;
      border-width: 0.4rem 0 0.4rem 0.4rem;
      border-left-color: #000; }

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem; }

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.75;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem; }
  .popover .arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
    margin: 0 0.3rem; }
    .popover .arrow::before, .popover .arrow::after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem; }
  .bs-popover-top > .arrow, .bs-popover-auto[x-placement^="top"] > .arrow {
    bottom: calc(-0.5rem - 1px); }
    .bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^="top"] > .arrow::before {
      bottom: 0;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^="top"] > .arrow::after {
      bottom: 1px;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: #fff; }

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem; }
  .bs-popover-right > .arrow, .bs-popover-auto[x-placement^="right"] > .arrow {
    left: calc(-0.5rem - 1px);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
    .bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^="right"] > .arrow::before {
      left: 0;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^="right"] > .arrow::after {
      left: 1px;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: #fff; }

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem; }
  .bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^="bottom"] > .arrow {
    top: calc(-0.5rem - 1px); }
    .bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^="bottom"] > .arrow::before {
      top: 0;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^="bottom"] > .arrow::after {
      top: 1px;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: #fff; }
  .bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -0.5rem;
    content: "";
    border-bottom: 1px solid #f7f7f7; }

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem; }
  .bs-popover-left > .arrow, .bs-popover-auto[x-placement^="left"] > .arrow {
    right: calc(-0.5rem - 1px);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
    .bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^="left"] > .arrow::before {
      right: 0;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^="left"] > .arrow::after {
      right: 1px;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: #fff; }

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .popover-header:empty {
    display: none; }

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212121; }

.carousel {
  position: relative; }

.carousel.pointer-event {
  touch-action: pan-y; }

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden; }
  .carousel-inner::after {
    display: block;
    clear: both;
    content: ""; }

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  transition: -webkit-transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out, -webkit-transform 0.6s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-item {
      transition: none; } }

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block; }

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  -webkit-transform: translateX(100%);
          transform: translateX(100%); }

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%); }

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  -webkit-transform: none;
          transform: none; }

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1; }

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-fade .active.carousel-item-left,
    .carousel-fade .active.carousel-item-right {
      transition: none; } }

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-control-prev,
    .carousel-control-next {
      transition: none; } }
  .carousel-control-prev:hover, .carousel-control-prev:focus,
  .carousel-control-next:hover,
  .carousel-control-next:focus {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: 0.9; }

.carousel-control-prev {
  left: 0; }

.carousel-control-next {
  right: 0; }

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: 50% / 100% 100% no-repeat; }

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e"); }

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e"); }

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none; }
  .carousel-indicators li {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: .5;
    transition: opacity 0.6s ease; }
    @media (prefers-reduced-motion: reduce) {
      .carousel-indicators li {
        transition: none; } }
  .carousel-indicators .active {
    opacity: 1; }

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center; }

@-webkit-keyframes spinner-border {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes spinner-border {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  border: 0.25em solid currentcolor;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: .75s linear infinite spinner-border;
          animation: .75s linear infinite spinner-border; }

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em; }

@-webkit-keyframes spinner-grow {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0); }
  50% {
    opacity: 1;
    -webkit-transform: none;
            transform: none; } }

@keyframes spinner-grow {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0); }
  50% {
    opacity: 1;
    -webkit-transform: none;
            transform: none; } }

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  background-color: currentcolor;
  border-radius: 50%;
  opacity: 0;
  -webkit-animation: .75s linear infinite spinner-grow;
          animation: .75s linear infinite spinner-grow; }

.spinner-grow-sm {
  width: 1rem;
  height: 1rem; }

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
  .spinner-grow {
    -webkit-animation-duration: 1.5s;
            animation-duration: 1.5s; } }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #431D1F !important; }

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #1f0e0f !important; }

.bg-secondary {
  background-color: transparent !important; }

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: rgba(0, 0, 0, 0) !important; }

.bg-success {
  background-color: #226F26 !important; }

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #164819 !important; }

.bg-info {
  background-color: #8C5655 !important; }

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #6c4342 !important; }

.bg-warning {
  background-color: #212721 !important; }

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #0a0b0a !important; }

.bg-danger {
  background-color: #EF1E14 !important; }

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #c3160d !important; }

.bg-light {
  background-color: #f8f9fa !important; }

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important; }

.bg-dark {
  background-color: #343a40 !important; }

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important; }

.bg-white {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #dee2e6 !important; }

.border-top {
  border-top: 1px solid #dee2e6 !important; }

.border-right {
  border-right: 1px solid #dee2e6 !important; }

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important; }

.border-left {
  border-left: 1px solid #dee2e6 !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #431D1F !important; }

.border-secondary {
  border-color: transparent !important; }

.border-success {
  border-color: #226F26 !important; }

.border-info {
  border-color: #8C5655 !important; }

.border-warning {
  border-color: #212721 !important; }

.border-danger {
  border-color: #EF1E14 !important; }

.border-light {
  border-color: #f8f9fa !important; }

.border-dark {
  border-color: #343a40 !important; }

.border-white {
  border-color: #fff !important; }

.rounded-sm {
  border-radius: 0.2rem !important; }

.rounded {
  border-radius: 0.25rem !important; }

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important; }

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-lg {
  border-radius: 0.3rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: 50rem !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 768px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

.user-select-all {
  -webkit-user-select: all !important;
          user-select: all !important; }

.user-select-auto {
  -webkit-user-select: auto !important;
          user-select: auto !important; }

.user-select-none {
  -webkit-user-select: none !important;
          user-select: none !important; }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: -webkit-sticky !important;
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports ((position: -webkit-sticky) or (position: sticky)) {
  .sticky-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal; }

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; }

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; }

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }

.shadow-none {
  box-shadow: none !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.vw-100 {
  width: 100vw !important; }

.vh-100 {
  height: 100vh !important; }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.5rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3,
.my-3 {
  margin-top: 1rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5,
.my-5 {
  margin-top: 3rem !important; }

.mr-5,
.mx-5 {
  margin-right: 3rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 3rem !important; }

.ml-5,
.mx-5 {
  margin-left: 3rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3,
.py-3 {
  padding-top: 1rem !important; }

.pr-3,
.px-3 {
  padding-right: 1rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }

.pl-3,
.px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4,
.py-4 {
  padding-top: 1.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5,
.py-5 {
  padding-top: 3rem !important; }

.pr-5,
.px-5 {
  padding-right: 3rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 3rem !important; }

.pl-5,
.px-5 {
  padding-left: 3rem !important; }

.m-n1 {
  margin: -0.25rem !important; }

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important; }

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important; }

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important; }

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important; }

.m-n2 {
  margin: -0.5rem !important; }

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important; }

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important; }

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important; }

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important; }

.m-n3 {
  margin: -1rem !important; }

.mt-n3,
.my-n3 {
  margin-top: -1rem !important; }

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important; }

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important; }

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important; }

.m-n4 {
  margin: -1.5rem !important; }

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important; }

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important; }

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important; }

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important; }

.m-n5 {
  margin: -3rem !important; }

.mt-n5,
.my-n5 {
  margin-top: -3rem !important; }

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important; }

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important; }

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important; }
  .m-sm-n1 {
    margin: -0.25rem !important; }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important; }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important; }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important; }
  .m-sm-n2 {
    margin: -0.5rem !important; }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important; }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important; }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important; }
  .m-sm-n3 {
    margin: -1rem !important; }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important; }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important; }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important; }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important; }
  .m-sm-n4 {
    margin: -1.5rem !important; }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important; }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important; }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important; }
  .m-sm-n5 {
    margin: -3rem !important; }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important; }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important; }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important; }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important; }
  .m-md-n1 {
    margin: -0.25rem !important; }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important; }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important; }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important; }
  .m-md-n2 {
    margin: -0.5rem !important; }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important; }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important; }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important; }
  .m-md-n3 {
    margin: -1rem !important; }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important; }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important; }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important; }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important; }
  .m-md-n4 {
    margin: -1.5rem !important; }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important; }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important; }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important; }
  .m-md-n5 {
    margin: -3rem !important; }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important; }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important; }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important; }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important; }
  .m-lg-n1 {
    margin: -0.25rem !important; }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important; }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important; }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important; }
  .m-lg-n2 {
    margin: -0.5rem !important; }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important; }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important; }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important; }
  .m-lg-n3 {
    margin: -1rem !important; }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important; }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important; }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important; }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important; }
  .m-lg-n4 {
    margin: -1.5rem !important; }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important; }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important; }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important; }
  .m-lg-n5 {
    margin: -3rem !important; }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important; }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important; }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important; }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important; }
  .m-xl-n1 {
    margin: -0.25rem !important; }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important; }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important; }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important; }
  .m-xl-n2 {
    margin: -0.5rem !important; }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important; }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important; }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important; }
  .m-xl-n3 {
    margin: -1rem !important; }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important; }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important; }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important; }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important; }
  .m-xl-n4 {
    margin: -1.5rem !important; }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important; }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important; }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important; }
  .m-xl-n5 {
    margin: -3rem !important; }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important; }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important; }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important; }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0); }

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important; }

.text-justify {
  text-align: justify !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-lighter {
  font-weight: lighter !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-weight-bolder {
  font-weight: bolder !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #fff !important; }

.text-primary {
  color: #431D1F !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #0e0606 !important; }

.text-secondary {
  color: transparent !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: rgba(0, 0, 0, 0) !important; }

.text-success {
  color: #226F26 !important; }

a.text-success:hover, a.text-success:focus {
  color: #103412 !important; }

.text-info {
  color: #8C5655 !important; }

a.text-info:hover, a.text-info:focus {
  color: #5c3938 !important; }

.text-warning {
  color: #212721 !important; }

a.text-warning:hover, a.text-warning:focus {
  color: black !important; }

.text-danger {
  color: #EF1E14 !important; }

a.text-danger:hover, a.text-danger:focus {
  color: #ab130c !important; }

.text-light {
  color: #f8f9fa !important; }

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important; }

.text-dark {
  color: #343a40 !important; }

a.text-dark:hover, a.text-dark:focus {
  color: #121416 !important; }

.text-body {
  color: #212121 !important; }

.text-muted {
  color: #6c757d !important; }

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.text-decoration-none {
  text-decoration: none !important; }

.text-break {
  word-break: break-word !important;
  word-wrap: break-word !important; }

.text-reset {
  color: inherit !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important; }
  a:not(.btn) {
    text-decoration: underline; }
  abbr[title]::after {
    content: " (" attr(title) ")"; }
  pre {
    white-space: pre-wrap !important; }
  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid; }
  tr,
  img {
    page-break-inside: avoid; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  @page {
    size: a3; }
  body {
    min-width: 992px !important; }
  .container {
    min-width: 992px !important; }
  .navbar {
    display: none; }
  .badge {
    border: 1px solid #000; }
  .table {
    border-collapse: collapse !important; }
    .table td,
    .table th {
      background-color: #fff !important; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important; }
  .table-dark {
    color: inherit; }
    .table-dark th,
    .table-dark td,
    .table-dark thead th,
    .table-dark tbody + tbody {
      border-color: #dee2e6; }
  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6; } }

.btn {
  padding: 0rem 1.25rem;
  font-size: 0.875rem;
  line-height: 2.25rem;
  border-radius: 0.25rem; }
  .btn:hover, .btn:focus {
    text-decoration: none; }

body {
  margin: 0;
  padding: 0;

  text-align: center;
  min-height: 100vh;
  width: 100vw;
  overflow-x: hidden;

}


#ccc-notify-dismiss {
	display: none !important;
}

/* For phones */
@media (max-width: 720px) {
  .mobtextcenter{
    text-align: center;
  }
  footer a{
    font-size: .9rem;
  }
}
.full-height {
  min-height: 100vh;
  overflow: hidden;
}
.newsbannerbox{
    background: #212721;
    color: white;
    padding-top: 0.75rem;
    padding-bottom:0.063rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
}
.emailContainer {
  width: 100%!important;
}
.emailContainer input {
  width: 100%!important;
  text-align: center;
}
.invitationContainer {
  width: 100%!important;
  text-align: center;
  display: block!important;
  
}
.invitationContainer button {
  color: #ffffff;
  background: #A74450;
  margin-top: 0.313rem;
}

.invitationContainer button:hover {
  color: #ffffff;
  background: #8c3742;
}
.invitationContainer button:active {
  color: #ffffff;
  background: #A74450!important;
}
.invitationContainer button:focus {
  background: #A74450!important;
}
.invitationEmailContainer {
  padding: 3.125rem 1.25rem 0;
}
/*Extra small devices (portrait phones, less than 576px)*/
@media (max-width: 575.98px) {

  /* NextGatherings Page */
  .gathering-text{
    text-align: left;
    line-height: 1.625rem;
  }
  /* InvitationForm Page */
  .mobtext-left{
    text-align: left;
  }
  .mobfont16{
    font-size: 1rem;
    line-height: 1.75rem;
  }
}

 /* Navbar */

.navbar-in-lobby{
    background: #9c676c;
    /*background-image: linear-gradient(to bottom, #9c676c, #48070c);*/
}

.navbar-out-of-lobby{
    background: #9c676c;
    background-image: linear-gradient(to bottom, #9c676c, #48070c);
}

.navbar-facilitator-page {
    background: #3b161f;
}

 .navbar-toggler{
     outline: none!important;
 }
.navbar-brand #logoSymbol{
     max-width: 1.75rem;
}
.navbar-brand #logoWordmark{
    max-height:1.813rem; 
}
.navbar-brand {
    padding-top: 0;
    padding-bottom: 0.625rem; 
}
.navbar-nav .nav-item{
    margin: 0 1.875rem;
}
.btn-secondary, .btn-secondary:hover{
    color: #CBA2A7;
    border-color:#AA6362;
    
}
#NavbarSignIn {
    box-shadow: none;
    margin-left: 1.875rem;
}
/* Footer */
.footer {
    background: #431D1F;
    font-size: 1rem;
}
.footer p{
    padding: 2rem 0 1rem 0; 
    margin-bottom: 0;
    line-height: 1.875rem;
}
.footerLinks {
    background: #431D1F;
}
.footerLinks a {
    color: #ffffff;
    margin: 0 2rem;
}
.footer-link{
    display: inline;
    margin: 0 0.938rem;
    font-size: 1rem;
}
.userbtn{
    width:2.5rem!important;
    padding: 0!important;
    background: white;
    border-radius: 100%!important;
}
.userbtn img{
    width:1.813rem;
    height:1.813rem;
    object-fit: contain;
    margin:-0.313rem 0 0;
}
.donationIcon {
    position: absolute;
    top: 0.188rem;
    right: 0.3rem;

}

.donationIconImg {
    max-height: 2.5rem;
    max-width: 2.5rem;
}

.donation {
    padding-right: 3.125rem !important;
}
@media (max-width: 1212px) {
    .navbar-nav .nav-item{
        margin: 0 0.625rem;
    }
    .userDropdown {
        margin-left: 0.5rem!important;
    }
}
@media (max-width: 929px) {
    .navbar-nav .nav-item{
        margin: 0 0.005rem;
    }
}

@media (min-width: 768px) and (max-width: 821px) {
    .navbar-brand #logoWordmark {
        max-height: 1.475rem!important;
    }
}

@media (min-width: 768px) and (max-width: 773px) {
    .navbar-expand-md .navbar-nav .nav-link{
        padding-right: 0.3rem;
        padding-left: 0.3rem;
    }
}


@media (max-width: 768px) {
    .socialMediaLinks li{
        margin: 0 1rem!important;
    }
    .footerLinks a{
        margin: 0 1rem!important;
    }
}
@media (max-width: 767px) {
    .donation {
        padding-right: 0.5rem !important;
    }
    .donationIcon {
        position: absolute;
        top: 0.188rem;
        left: 6.875rem;
    }
    .btn.btn-secondary.userDropdown{
        margin-top: 1rem;
    }
}
@media (max-width: 480px) {
    .navbar-brand #logoWordmark {
        max-height: 1.375rem!important;
    }
}
@media only screen and (max-width: 690px) and (min-width: 576px)  {
    
    .navbar-brand #logoSymbol {
        max-width: 1.25rem;
    }
    .navbar-nav .nav-item {
        margin: 0 0.063rem;
    }
    .navbar-nav .nav-item .nav-link{
        font-size: 0.875rem;
    }
    .navbar-nav .btn {
        padding: 0rem 0.938rem;
        font-size: .7rem;
        line-height: 1.875rem;
    }
}

.btn.btn-secondary.userDropdown:hover{
    color: #ffffff;
    border-color: #ffffff;
}

.socialMediaLinks {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    list-style: none;
    padding: 0 0 4rem;
    margin: 0;
}

.socialMediaLinks li {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 3em;
    height: 3em;
    margin: 0 1.5rem;
}

.socialMediaLinks li a {
    width: 100%;
    height: 100%;
    padding: 0.25em;
    border-radius: 50%;
    background: #794D4F;
    transition: background 300ms;
    font-size: 1.5rem;
    line-height: 1.5;
}

.socialMediaLinks li a.facebook:hover {
    background: #3D63A7;
}

.socialMediaLinks li a.instagram:hover {
    background: #F00075;
}

.socialMediaLinks li a.youtube:hover {
    background: #F00;
}


.testimonialsbox{
    max-width: 59.063rem;;
    margin:0 auto;
    padding: 0;
    flex: 100% 1;
}
/* .testimonialsbox p{
    font-size: 1rem;
    text-align: center;
    margin: 2rem auto;
    line-height: 2.375rem;
    font-weight: 500;
    color:#794D4F;
} */

.testimonialsbox p.testimonialtext{	
    font-size: 1.5rem;	    
    font-style: italic;	    
    text-align: left;	   
    margin: 2rem auto;	   
    color:#7B706A;	   
}

.testimonialsbox p.testimonialauthor{
    font-size: 1.125rem;
    margin: 0 auto;
    line-height: 1.875rem;
    font-weight: normal;
    color: #3C3C3C;
}

.testimonialsbox p.testimonialauthor{
    margin-bottom: 4rem;
}

p.visitorCountry{
    color:#181414 !important;
}

.gathering-text{
    margin-bottom: 2rem;
}

a.emaillink{
    font-size: 1.125rem;
    margin:0rem auto 0rem;
    display: inline-block;
    line-height: 1.875rem;
    font-weight: normal; 
    text-decoration: underline; 
    color: #5B0E0D;
}
.connectintrobox {
    padding: 0 .938rem;
}
.connectintrobox .visitorBox {
    width: 100%;
    background: #ffffff;
    margin: 0 auto;
    border-radius: 0.375rem;
    padding: 2rem 5rem;
    font-style: italic;
}
.visitorBox img{
    margin-bottom: 1.2rem;
}

@media (max-width: 991.98px) {
    .connectintrobox {
        padding: 3.75rem 1.938rem;
    }
    .connectintrobox .visitorBox {
        padding: 1.25rem;
        max-width: 75%;
    }
    .connectintrobox .visitorBox .visitorText{
        font-size: 0.875rem;
        line-height: 1.5rem;
    }
}

@media (max-width: 767px) {
    .connectintrobox .visitorBox {
        max-width: 100%;
    }
 }

@media (max-width: 575.98px) {
    .testimonialsbox p.testimonialtext{
        font-size: 1.25rem;
        margin: 1rem 0.938rem 2rem;
        line-height: 2.125rem;
    }
    .testimonialsbox p.testimonialauthor{
        font-size: 1rem;
        line-height: 1.125rem;
    }
    
}
.partnerItem {
	width: 9.5rem;
	height: 5rem;
	margin: auto;
}

.partnerItem img {
  margin: auto;
  max-height: 100%;
  max-width: 100%;
}


.centeredBoxPartner {
  display: flex;
  justify-content: center;
  align-items: center;
	width: 9.5rem;
	height: 5rem;
}

.centeredBoxPartner img {
  max-width: 100%;
  max-height: 100%;
}
.cardRow {
    padding: 2.25rem 1.875rem 1.25rem;;
}
.cardRow .cardContainer {
    background: rgba(81, 35, 37, 0.98);
    border-radius: 2.5rem;
}
.cardRow .relateCardContainer {
    background: rgba(170, 101, 84, 0.98);
    border-radius: 2.5rem;
}
.cardRow .selfCardContainer {
    background: #813F4C;
    border-radius: 2.5rem;
}
.cardRow .cardTitle {
    padding: 4.688rem 3rem 0;
    max-width: 370px;
    margin: auto;
    height: 16.688rem;
}
.cardRow .cardTitle p {
    font-weight: normal;
    font-size: 2.25rem!important;
    line-height: 3rem!important;
}
.cardRow p {
    color: #ffffff;
}
.cardRow .cardDescription {
    padding: 1.563rem 2.5rem 0;
    height: 10.938rem;
    font-weight: 100;
}
.cardRow .joinButton {
    padding: 2.5rem 0 3.063rem;
}
.cardRow .joinButton button{
    color: #FFFFFF;
    border:1px solid #FFFFFF;
}
.cardRow .col-sm-4 {
    margin-bottom: 30px;
}
.cardRow .cardRowContainer {
    justify-content: center;
}
.alignCenter{
    max-width: 27.5rem;
}

/*Extra small devices (portrait phones, less than 576px)*/
@media (max-width: 1200px) {
    .cardRow .cardDescription {
        padding: 1.563rem 1.5rem 0;
        height: 13.938rem;
    }
}
@media (max-width: 1024px) {
    .cardRow .col-sm-4 {
        flex: 0 0 50%;
        max-width: 50%;
    }
    .cardRow .cardDescription {
        padding: 1.563rem 2.5rem 0;
        height: 12.938rem;
        font-size: 0.9rem;
    }
}

@media (max-width: 683px) {
    .cardRow .col-sm-4 {
        flex: 0 0 100%;
        max-width: 100%;
    }
    .cardRow .cardDescription {
        padding: 1.563rem 1.5rem 0;
        height: 8.938rem;
    }
    .cardRow .cardTitle {
        padding: 4.688rem 1.5rem 0;
        max-width: 370px;
        margin: auto;
        height: 16.688rem;
    }
}
@media(max-width: 420px) {
    .cardRow .cardDescription {
        height: 12.938rem;
    }
}
.inner-tooltip {
  min-width: 22.5rem;
}

.not-transparent {
  opacity: 1;	
  width: 34.375rem;
}

#WhatAreGatheringsTrigger {
	font-size: 0.7em;
	vertical-align: super;
}


.emailBox {
  clear: both;
  width: 100%;
  margin: 3.5rem 0 3.688rem;
}


@media (max-width: 991.98px) {
  .emailBox {
    margin-top: 2.5rem;
  }
}

@media (max-width: 767px) {
  .emailBox .emaillink{
    font-size: 1rem;
  }
}

.connectVideo {
    position: relative;
    padding-top: 56.25%;
    border-radius: 1.875rem;
    overflow: hidden;
}
.connectVideo iframe, .connectVideo .connectImage {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
}

.connectVideo .playIcon {
    position: absolute;
    top: calc(100% -30px);
    left: calc(50% - 30px);
    width: 60px;
    top: calc(50% - 30px);
}
.connectVideoContainer .row {
    padding: 0 3.125rem;
    align-items: center;
}
.connectVideoContainer p {
    margin-bottom: 1.75rem;
}
.connectVideoContainer a {
    font-size: 1rem;
    text-decoration: underline;
}
.connectVideoContainer .videoTitle {
    font-size: 2.25rem;
    line-height: 3rem;
}
.videoTitleContainer {
    margin-bottom: 1.5rem;
}

@media(max-width: 767px) {
    .connectVideoContainer .row {
        padding: 0 1rem;
    }
    .connectVideoContainer {
        margin-top: 1rem !important;
    }
}
.experience-invite-link {
	border-radius: 0.5rem;
	border: 2px black solid;
	display: inline-block;
	padding-left: 1rem;
	padding-right: 1rem;
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
}

.copy-invite-link {
	padding-left: 1rem;
	display: inline-block;
	cursor: pointer;
}

.experience-invitation-modal-classname {
	border-radius: 0.75rem;
}

.experience-invite-title {
	color: rgb(67, 29, 31);
  font-weight: 600;
}

.link-copied {
	padding-top: 0.2rem;
	padding-bottom: 0.2rem;
	border-radius: 0.5rem;
	background-color: #234351;
	color: white;
	width: 10rem;
	margin: auto;
}

.link-copied img {
	width: 1.2rem;
	height: 1.2rem;
	margin-right: 0.5rem;
}

.stay-on-screen {
	color: #431D1F;
	font-weight: 600;
}


.my-modal-classname {
    padding: 0!important;
    border-radius: 0.75rem;
}

.topNoOneInCircleModalContainer {
    height: 26.5rem;
    position: relative;
    border-radius: 0.75rem;
    background-image: linear-gradient(to bottom, #9c676c, #48070c);
    background-size: cover;
    background-position: center;
    overflow: hidden;
}

.topNoOneInCircleModalContainer .closeModalX {
    position: absolute;
    /*width: 1.75rem;*/
    right: 1.25rem;
    z-index: 10;
    top: 0.9375rem;
    padding: 0;
    height: 15px;
    width: 15px;
    line-height: 15px;
    cursor: pointer;
}

.topNoOneInCircleModalContainer .closeModalX img {
    height: 15px;
    width: 15px;
}

.topNoOneInCircleModalContainer .noOneInCircleTitle {
	  font-size: 24px;
	  font-weight: bold;
	  line-height: 1.42;
    text-align: left;
    padding-right: 2rem;
    color: #FFFFFF;
    z-index: 10;
    position: absolute;
    top: 6rem;
    left: 2rem;
}

.topNoOneInCircleModalContainer .noOneInCircleText{
	  font-size: 14px;
	  font-weight: 500;
	  line-height: 1.71;
	  text-align: left;
    color: #FFFFFF;
    z-index: 10;
    position: absolute;
    top: 10rem;
    left: 2rem;
    padding-right: 2rem;
}


.topNoOneInCircleModalContainer .buttonArea {
    position: absolute;
    width: 100%;
    height: 3rem;
    bottom: 2rem;
    /*padding-top: 0.5rem;*/
}

.topNoOneInCircleModalContainer .buttonArea .btn {
    height: 2.5rem;
    border: none!important;
    font-size: 1rem;
    font-weight: bold!important;
    width: 14rem;
    border-radius: 0.7875rem!important;
}


@media (max-width: 575px) {
	.topNoOneInCircleModalContainer {
    height: 32rem;
  }

	.topNoOneInCircleModalContainer .noOneInCircleTitle {
	  padding-right: 1rem;
	  left: 1.5rem;
	}

	.topNoOneInCircleModalContainer .noOneInCircleText{
	  padding-right: 1rem;
	  top: 12rem;
	  left: 1.5rem;
	}

}

@media (max-width: 400px) {
	.topNoOneInCircleModalContainer {
    height: 34rem;
  }

	.topNoOneInCircleModalContainer .noOneInCircleTitle {
	  padding-right: 1rem;
	  left: 1.5rem;
	}

	.topNoOneInCircleModalContainer .noOneInCircleText{
	  padding-right: 1rem;
	  top: 12rem;
	  left: 1.5rem;
	}

}


.presenceIndicatorDiv {
  width: 340px;
  height: 112px;
  margin: auto;
  position: relative;
  top: -56px;
  margin-bottom: 20px;
  color: #736060;
}

.presenceIndicatorDiv .presenceIndicatorBox1 {
  width: 166px;
  height: 112px;
  border-radius: 12px;
  box-shadow: 0 12px 35px 0 rgba(119, 66, 55, 0.15);
  background-color: #fcf5f1;
  position: absolute;
  top: 0;
  left: 0;
}

.presenceIndicatorDiv .presenceIndicatorBox2 {
  width: 166px;
  height: 112px;
  border-radius: 12px;
  box-shadow: 0 12px 35px 0 rgba(119, 66, 55, 0.15);
  background-color: #fcf5f1;
  position: absolute;
  top: 0;
  right: 0;
}

#presence-circle-explainer {
  position: absolute;
  top: 0;
  right: 0.5rem;
}

.people-number {
	padding-top: 16px;
	margin: auto;
  font-size: 24px;
  font-weight: bold;
  color: #795557;
}

@media (max-width: 376px) {
	.presenceIndicatorDiv {
	  width: 96%;
	}

	.presenceIndicatorDiv .presenceIndicatorBox1 {
	  width: 48%;
	}

	.presenceIndicatorDiv .presenceIndicatorBox2 {
	  width: 48%;
	}
}




.overflow-hidden {
  overflow: hidden;
}

.humanMinuteAreaContainer .exploreHM{
  max-width: 15rem;
  margin: 0 auto 1.25rem;
}

.humanMinuteAreaContainer .exploreHM img{
  margin-bottom: 0.938rem;
  height: 2rem;
  width: 2rem;
}

.humanMinuteAreaContainer .exploreHM h5{
  margin-bottom: 0.75rem;
  font-weight: bold;
  font-size: 1.5rem;
}
.humanMinuteAreaContainer .exploreHM p{
  margin-bottom: 0;
}

.humanMinuteAreaContainer .exploreHM--from-invite h5{
  font-weight: 500;
}

.humanMinuteAreaContainer {
  background: #eddcd2;
  padding: 2.813rem 0;
}

.hm-area-wrap{
  width:100%;
  max-width: 66.66%;
  position: relative;
  margin:0 auto;
  border-radius: 2.5rem;
  /* overflow: hidden; */
  background:transparent;
}

.hm-area-wrap .main-video{
  position: absolute;
  background: #431D1F;
  top:0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 2.5rem;
}

.hm-area-wrap .video-background{
  position: absolute;
  overflow: hidden;
  border-radius: 2.5rem;
  background: url(/static/media/bitmap.95a1ce22.png);
  background-size: cover;
  background-position: center;
  left: 0;
  top:0;
  width: 100%;
}

.hm-area-wrap .video-background .video-background-image{
  position: absolute;
  width: 100%;
  height: 100%;
  right: 0;
  bottom: 0;
  background: rgba(67, 29, 31,0.4);
}

.hm-area-wrap .video-background .my-video-image{
  position: absolute;
  height: 10rem;
  width: 15.813rem;
  right: 0;
  bottom: 0;
  background: url(/static/media/bitmap_me.524fe479.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  -webkit-filter: blur(0.5rem);
          filter: blur(0.5rem);
}
.hm-area-wrap .video-background img{
  width: 100%;
}

.hm-area-wrap.present .main-video, .hm-area-wrap.absent .main-video { 
  background: transparent;
  box-sizing: border-box;
}

.notifications-checkbox {
  width: 320px;
  margin: auto;
  padding-left: 0.625rem;
  padding-right: 0.625rem;
  line-height: 1.35rem;
}

.waitingStateContainer {
  position: absolute;
  width: 100%;
  margin: auto;
  left:0;
  right:0;
  top: 1rem;
  bottom: auto;
  height:2.5rem;
  color: #FFFFFF;
}

.waitingStateContainer .someone-text {
  font-size: 1.5rem;
  line-height: 2.5rem;
  margin: 0 0.625rem 0.938rem 0;
}

.centeredBoxHM .btn-default{
  min-width: 15.75rem;
  height: 2.625rem
}

.centeredBoxHM .BtnAuthModal{
  min-width: 0;
}

#CancelButton button {
  border: 1px solid #fff !important;
  color: #FFFFFF;
}

.hm-area-wrap.waiting #CancelButton button {
  border-color: #FFFFFF!important;
  border: 1px solid #FFFFFF
}

.hm-area-wrap.waiting #CancelButton {
  position: absolute;
  width: 100%;
  margin: auto;
  left: 0;
  height: 2.5rem;
  bottom: 3rem;
}

.humanMinuteAreaContainer .videoVisitors {
  padding: 0 0.938rem;
  max-width: 66.66%;
  margin: 3.125rem auto 0;
}

.audio-message-text {
  background: #FFFFFF;
  border-radius: 1.25rem;
  padding: 1.825rem;
  position: absolute;
  width: 18.75rem;
  right: 1.25rem;
  top:1.25rem;
}


.hm-area-wrap.firstTimeGuidance .enteringRoomState {
  top: 3.563rem !important;
  bottom: auto !important;
  z-index: 1;
}


.hm-area-wrap.firstTimeGuidance #CancelButton button {
  border-color: #FFFFFF!important;
  border: 1px solid #FFFFFF
}


.hm-area-wrap.firstTimeGuidance #CancelButton {
  position: absolute;
  width: 100%;
  margin: auto;
  left: 0;
  height: 2.5rem;
  top: 23.75rem;
}


.guidanceContainer {
  position: relative;
  z-index: 1;
  color: #FFFFFF;
  margin-top: 60px;
  width: 100%;
  /*overflow:scroll;*/
}
.guidelines {
  padding: 0 4rem;
  text-align: left;
}
.guidelinesText {
  font-size: 1.5rem;
}
.guidanceButtonContainer {
  padding: 0 4rem;
  position: absolute;
  width: 100%;
  z-index: 1;
  bottom: 0;
  transition: all ease-in 0.2s;
}
.guidanceButtonContainer .buttons {
  display: block;
  position: relative;
}
.guidanceButtonContainer .BtnNotFollowGuidelines {
  float: left;
}
.guidanceButtonContainer .BtnFollowGuidelines {
  float: right;
}
.guidanceButtonContainer button {
  color: #fff;
  border-color: #fff;
  width: 280px;
  padding: 0 1rem !important;
}
.guidanceButtonContainer .BtnDisabledFollowGuidelines {
  float: right;
  color: gray;
  border-color: gray;
  opacity: 0.5;
}
.selectLanguageContainer {
  position: absolute;
  width: 100%;
  z-index: 2;
}

.guidanceButtonContainer .spincontainer {
  position: absolute;
  left: calc(50% - 20px);
  top: 0;
}

.guidanceButtonContainer .button_animation:hover {
  background: transparent !important;
  border-color: #FFFFFF;
  color: #FFFFFF !important; 
}

.centeredBoxHM {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100%;
  position: relative; 
  padding-top: 24px; 
  z-index: 1;
}

.socialloginContainer {
  position: relative;
  margin: 0 auto 1.25rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.socialloginContainer.connectButtonContainer {
  padding: 0 1rem;
  max-width: 40.625rem;	
  margin: auto;	
  color: #fff;
}
.socialloginContainer a{
  text-decoration: underline;
}
.socialloginContainer.connectButtonContainer a{
  color: #FFFFFF;
}
.socialloginContainer p {
  line-height: 1.75rem;
}
.socialloginContainer button {
  background: #FFFFFF;
  border-radius: 2.844rem;
  line-height: 1.75rem;
  height: 3rem;
  width: 100%;
  display: block;
  margin: 0 auto 1.25rem;
  font-size: 1rem;
  font-weight: bold!important;
  box-shadow: none!important;
  border: 1px solid #794D4F !important;
}
.socialloginContainer.connectButtonContainer button {
  width: 18.75rem;
  border: none!important;
}
.socialloginContainer .activeButton {
  background: #794D4F !important;
  color: #fff !important;
}
.socialloginContainer .btn:hover {
  box-shadow: none !important;
}
.socialloginContainer .facebookButton {
  color: #4469B0;
}
.socialloginContainer .facebookButton:focus {
  outline: none;
}
.socialloginContainer .facebookButton .fa-facebook-official:before {
  position: relative;
  top: 0.188rem;
  margin-right: 0.625rem;
  margin-left: -0.625rem;
  font-size: 1.5rem;
}
.presence-indicator {
  padding: 0 10px;
}

.hm-area-wrap.firstTimeGuidance {
  overflow: hidden;
}


@media (max-width: 1126px) {
  .guidanceButtonContainer button {
    width: 100%;
  }
  .guidanceButtonContainer .spincontainer {
    position: static;
  }
  .guidanceContainer {
    padding-bottom: 200px;
    height: auto !important;
  }
}

  
@media (max-width: 1200px) and (min-width: 767px) {

  .hm-area-wrap.waiting #CancelButton {
    bottom: 3rem;
  }

  .hm-area-wrap.firstTimeGuidance #CancelButton {
    top: 19rem;
  }
}

@media (min-width: 767px) {

  .share-one-minute {
    max-width: 66%;
    margin: auto;
  }
}


@media(max-width: 991px) {

  .guidelines, .guidanceButtonContainer {
    padding: 0 2rem;
  }
  .hm-area-wrap.firstTimeGuidance {
    overflow: hidden;
  }
}
  
@media (max-width: 767px) {

  #Lobby h2 {
    font-size: 1.75rem;
  }

  .socialloginContainer.connectButtonContainer {	
    padding: 2rem 1rem 0;	
  }

  .guidanceContainer {
    position: absolute;
    padding-bottom: 100px;
    overflow:scroll;
    max-height:100%;
  }
  .guidanceButtonContainer {
    position: relative;
    margin-top: 2rem;
  }
  .hm-area-wrap.firstTimeGuidance .main-video {
    border-radius: 0;
  }
  .guidanceButtonContainer button {
    width: 100%;
  }

  .fullBackground nav, .fullBackground .topCarousel, .fullBackground .welcomecontainer, .fullBackground #Lobby .container, .fullBackground #Lobby .presence-indicator,.fullBackground + footer, .fullBackground .hm-title {
    display: none
  }

}
  

/*Notification Issue in following devices*/
@media (min-width: 768px) and (max-width: 975px){
  .hm-area-wrap.present, .hm-area-wrap.absent, .hm-area-wrap.waiting {
    max-width: 75%;
    height: 22.188rem!important;
  }

  .hm-area-wrap.present .video-background, .hm-area-wrap.absent .video-background, .hm-area-wrap.waiting .video-background {
   
    height: 22.188rem!important;
  }
  
}


  /*Small devices & Extra small devices (portrait phones, less than 576px)*/
  @media (max-width: 767px) {
  
    .flex-grow-1.fullBackground h1{
      font-size: 2.5rem!important
    }
  
    .lead {
      font-size: 1.2rem;
    }
  
    .fullBackground{
      height:100vh;
      height:calc(1vh * 100);
      height:calc(var(--vh, 1vh) * 100);
      overflow: hidden;
    }
  
    .hm-area-wrap{
      width: 100%;
      max-width: inherit;
    }

    .fullBackground .hm-area-wrap.firstTimeGuidance {
      height:calc(100vh)!important;
      height:calc((1vh * 100))!important;
      height:calc((var(--vh, 1vh) * 100))!important;
      max-height:calc(100vh - 4.563rem)!important;
      max-height:calc((1vh * 100))!important;
      max-height:calc((var(--vh, 1vh) * 100))!important;
      margin-bottom: 0!important;
      border-radius: 0!important;
      overflow: hidden!important;
    }
  
    .fullBackground .hm-area-wrap.firstTimeGuidance .main-video {
      top: 0%!important;
      height:calc(100vh)!important;
      height:calc((1vh * 100))!important;
      height:calc((var(--vh, 1vh) * 100))!important;
    }


  
  }
  
  @media (max-width: 1200px) {
    .hm-area-wrap .video-background .my-video-image{
      width: 12.5rem;
      height: 7.5rem;
    }
  }
  
  @media (max-width: 991.98px) {
    .humanMinuteAreaContainer {
      padding-top: 1.563rem;
    }
    .hm-area-wrap .video-background .my-video-image{
      width: 9.375rem;
      height: 6.25rem;
    }
  }

  @media(max-width: 600px) {

    .share-one-minute--from-invite {
      font-size: 1.5rem;
    }

    .hm-area-wrap.waiting #CancelButton {
      bottom: 2rem;
    }

    .hm-area-wrap.firstTimeGuidance #CancelButton {
      top: 27.75rem;
    }

    .hm-area-wrap.hm-area-social {
      height: 100%!important;
    }

    .hm-area-wrap.hm-area-social .video-background {
      height: 100%!important;
    }

  }
  
  @media (max-width: 480px) {
    .hm-area-wrap .video-background .my-video-image{
      width: 7.5rem;
      height: 5rem;
    }

    .flex-grow-1.fullBackground h1{
      font-size: 1.5rem!important;
      height: 4.063rem;
      line-height: 4.063rem;
      padding: 0!important;
    }
    .socialloginContainer button {
      font-size: 0.875rem;
    }

    .waitingStateContainer {
      top: 0;
    }

    .waitingStateContainer .someone-text {
      font-size: 1.25rem;
      line-height: 1.75rem;
    }

    .hm-area-wrap.waiting #CancelButton {
      bottom: 1rem;
    }
  }
.spinner {
  margin: 0 auto;
  position: relative;
  display: inline-block;
  /*float: left;*/
}

.spinner .spinner-circle {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.spinner .spinner-circle:before {
  content: '';
  display: block;
  margin: 0 auto;
  width: 15%;
  height: 15%;
  background-color: #FFFFFF;
  border-radius: 100%;
  -webkit-animation: sk-circleFadeDelay 1.2s infinite ease-in-out both;
          animation: sk-circleFadeDelay 1.2s infinite ease-in-out both;
}
.spinner .spinner-circle2 {
  -webkit-transform: rotate(30deg);
          transform: rotate(30deg);
}
.spinner .spinner-circle3 {
  -webkit-transform: rotate(60deg);
          transform: rotate(60deg);
}
.spinner .spinner-circle4 {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}
.spinner .spinner-circle5 {
  -webkit-transform: rotate(120deg);
          transform: rotate(120deg);
}
.spinner .spinner-circle6 {
  -webkit-transform: rotate(150deg);
          transform: rotate(150deg);
}
.spinner .spinner-circle7 {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.spinner .spinner-circle8 {
  -webkit-transform: rotate(210deg);
          transform: rotate(210deg);
}
.spinner .spinner-circle9 {
  -webkit-transform: rotate(240deg);
          transform: rotate(240deg);
}
.spinner .spinner-circle10 {
  -webkit-transform: rotate(270deg);
          transform: rotate(270deg);
}
.spinner .spinner-circle11 {
  -webkit-transform: rotate(300deg);
          transform: rotate(300deg);
}
.spinner .spinner-circle12 {
  -webkit-transform: rotate(330deg);
          transform: rotate(330deg);
}
.spinner .spinner-circle2:before {
  -webkit-animation-delay: -1.1s;
          animation-delay: -1.1s;
}
.spinner .spinner-circle3:before {
  -webkit-animation-delay: -1s;
          animation-delay: -1s;
}
.spinner .spinner-circle4:before {
  -webkit-animation-delay: -0.9s;
          animation-delay: -0.9s;
}
.spinner .spinner-circle5:before {
  -webkit-animation-delay: -0.8s;
          animation-delay: -0.8s;
}
.spinner .spinner-circle6:before {
  -webkit-animation-delay: -0.7s;
          animation-delay: -0.7s;
}
.spinner .spinner-circle7:before {
  -webkit-animation-delay: -0.6s;
          animation-delay: -0.6s;
}
.spinner .spinner-circle8:before {
  -webkit-animation-delay: -0.5s;
          animation-delay: -0.5s;
}
.spinner .spinner-circle9:before {
  -webkit-animation-delay: -0.4s;
          animation-delay: -0.4s;
}
.spinner .spinner-circle10:before {
  -webkit-animation-delay: -0.3s;
          animation-delay: -0.3s;
}
.spinner .spinner-circle11:before {
  -webkit-animation-delay: -0.2s;
          animation-delay: -0.2s;
}
.spinner .spinner-circle12:before {
  -webkit-animation-delay: -0.1s;
          animation-delay: -0.1s;
}


@-webkit-keyframes sk-circleFadeDelay {
  0%, 39%, 100% { opacity: 0; }
  40% { opacity: 1; }
}

@keyframes sk-circleFadeDelay {
  0%, 39%, 100% { opacity: 0; }
  40% { opacity: 1; }
}

.spinner .spinner-black:before {
  background-color: black;
}
.overflow-hidden {
  overflow: hidden;
}

.videoConatainer {
  background: #eddcd2;
  padding: 2.813rem 0;
}

.video-wrap{
  width:100%;
  max-width: 66.66%;
  position: relative;
  margin:0 auto;
  border-radius: 2.5rem;
  /* overflow: hidden; */
  background:transparent;
}
.video-wrap video{
  width: 100%;
  height: 100%;
}
.video-wrap .main-video{
  position: absolute;
  background: #431D1F;
  top:0;
  left: 0;
  width: 100%;
  height: 100%;
}
.video-wrap .small-video{
  position: absolute;
  background: #181818;
  bottom:0;
  right: 0;
  width: 11.25rem;
  height: 7.5rem;
  border-left: 0.063rem solid #888;
  border-top: 0.063rem solid #888;
  object-fit: cover;
}

.video-wrap .small-video video{
  object-fit: cover;
}

.video-wrap.gettingMedia {
  position: relative
}

.video-wrap.enteringRoom .main-video, .video-wrap.firstTimeGuidance .main-video , .video-wrap.gettingMedia .main-video, .video-wrap.preparing .main-video { 
  background: #431D1F;
  border-radius: 2.5rem;
}

.invisible {
  display: none;
}

.silhouette {
  max-height: 100%;
  height: 100%;
  max-width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate3d(-50%, -50%, 0);
          transform: translate3d(-50%, -50%, 0);
  object-fit: cover;
}
#progressbar {
  border-radius: 0;
  position: absolute;
  top:0;
  left: 0;
  width: 100%;
  height: 100%;
}

.enteringRoomState {
  position: absolute;
  width: 100%;
  margin: auto;
  left:0;
  right:0;
  top:0;
  bottom: 0;
  height:2.5rem;
}


.video-wrap.enteringRoom .enteringRoomState, .video-wrap.firstTimeGuidance .enteringRoomState {
  top: 3.563rem !important;
  bottom: auto !important;
  z-index: 1;
}
.enteringRoomState p {
  color: #FFFFFF;
}

.enteringRoomState .someone-text {
  font-size: 1.5rem;
  line-height: 2.5rem;
  margin: 0 0.625rem 0.938rem 0;
}
.enteringRoomState .TipsContainer {
  max-width: 34.375rem;
  margin: 3rem auto;
}
.enteringRoomState .TipsContainer p {
  line-height: 24px;
}

.enteringRoomState .btn-default{
  border: none!important;
}

.ready-state-text, .disconnected-state-text {
  position: absolute;
  width: 100%;
  margin: auto;
  left:0;
  right:0;
  top:0;
  bottom: 0;
  height:2.5rem;
}

.video-wrap.gettingMedia .getting-media-text {
  position: absolute;
  width: 100%;
  left: 0;
  right: 0;
  top: 2.5rem;
  height: 100%;
}

#CancelButton button {
  border: 1px solid #fff !important;
  color: #FFFFFF;
}

.video-wrap.enteringRoom #CancelButton button, .video-wrap.firstTimeGuidance #CancelButton button {
  border-color: #FFFFFF!important;
  border: 1px solid #FFFFFF
}

.video-wrap.gettingMedia #CancelButton {
  position: absolute;
  width: 100%;
  margin: auto;
  left: 0;
  bottom: 3.125rem;
  height: 2.5rem;
}

.video-wrap.enteringRoom #CancelButton, .video-wrap.firstTimeGuidance #CancelButton {
  position: absolute;
  width: 100%;
  margin: auto;
  left: 0;
  height: 2.5rem;
  top: 25.75rem;
}

#EndConnectionButton {
  position: absolute;
  width: 100%;
  margin: auto;
  left: 0;
  top: 0;
  height: 2.5rem;
  bottom: -85vh;
}

#EndConnectionButton #BtnEndConnection {
  color: #252525;
  font-weight: 500 !important;
  background: #E5E5E5; 
  border-color: 0.188rem solid #979797 !important
}

.video-wrap.preparing #CancelButton{
  width:7.5rem;
  position: relative;
  display: inline-block;
  margin-top:45%;
}

#StartButton {
  width:7.5rem;
  position: relative;
  display: inline-block;
  margin-top:45%;
}
#StartButton button #BtnStart {
  color: #252525;
  font-weight: 500 !important;
  background: #E5E5E5!important; 
  border-color: 0.188rem solid #979797 !important
}

.ready-state-text, .disconnected-state-text {
  font-size: 1.5rem;
  margin-top:0.625rem;
  position: absolute;
  top: -4.75rem;
  width: 100%;
}
.getting-media-text p, .ready-state-text p, .disconnected-state-text p {
  color: #757575;
}

.getting-media-text p {
  font-size: 1rem;
  color: #CBA2A7;
  line-height: 1.75rem;
  text-align: left;
}
.getting-media-text .permissionContainer {
  display: block;
  margin: auto;
  max-width: 23.125rem;
  padding: 6rem 0.625rem 0;
}
.getting-media-text .permissionContainer img{
  width: 1.875rem;
  float: left;
  margin-top: 1.25rem;
}
.getting-media-text .permissionContainer .permission-text{
  width: calc(100% - 1.875rem);
  padding-left: 1.5rem;
  float: left;
}
.getting-media-text .heading-text{
  font-size: 1.5rem;
  line-height: 2.5rem;
  color: #FFFFFF;
  text-align: center;
  padding: 0 0.625rem;
}

.ready-state-text, .disconnected-state-text {
  margin: 0 auto;
  top: 15%;
}

.disconnected-state-text .spinner, .ready-state-text .spinner {
  float: inherit!important;
}

.disconnected-state-text h4 {
  color:#fff!important;
}

.guidanceContainer {
  position: relative;
  z-index: 1;
  color: #FFFFFF;
  margin-top: 60px;
  width: 100%
}
.guidelines {
  padding: 0 4rem;
  text-align: left;
}
.guidelinesText {
  font-size: 1.5rem;
}
.guidanceButtonContainer {
  padding: 0 4rem;
  position: absolute;
  width: 100%;
  z-index: 1;
  bottom: 0;
  transition: all ease-in 0.2s;
}
.guidanceButtonContainer .buttons {
  display: block;
  position: relative;
}
.guidanceButtonContainer .BtnNotFollowGuidelines {
  float: left;
}
.guidanceButtonContainer .BtnFollowGuidelines {
  float: right;
}
.guidanceButtonContainer button {
  color: #fff;
  border-color: #fff;
  width: 280px;
  padding: 0 1rem !important;
}
.guidanceButtonContainer .BtnDisabledFollowGuidelines {
  float: right;
  color: gray;
  border-color: gray;
  opacity: 0.5;
}
.selectLanguageContainer {
  position: absolute;
  width: 100%;
  z-index: 2;
}

.guidanceButtonContainer .spincontainer {
  position: absolute;
  left: calc(50% - 20px);
  top: 0;
}

.guidanceButtonContainer .button_animation:hover {
  background: transparent !important;
  border-color: #FFFFFF;
  color: #FFFFFF !important; 
}


.audio-message-text {
  background: #FFFFFF;
  border-radius: 1.25rem;
  padding: 1.825rem;
  position: absolute;
  width: 18.75rem;
  right: 1.25rem;
  top:1.25rem;
}


@media (max-width: 1200px) and (min-width: 767px) {
  .video-wrap .enteringRoom .enteringRoomState, .video-wrap .firstTimeGuidance .enteringRoomState {
    top: 1rem;
  }
}
  
  .videoConatainer {
    background: #eddcd2;
    padding: 2.813rem 0;
  }
  
  .video-wrap{
    width:100%;
    max-width: 66.66%;
    position: relative;
    margin:0 auto;
    border-radius: 2.5rem;
    /* overflow: hidden; */
    background:transparent;
  }
  .video-wrap video{
    width: 100%;
    height: 100%;
  }
  .video-wrap .main-video{
    position: absolute;
    background: #431D1F;
    top:0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .video-wrap .small-video{
    position: absolute;
    background: #181818;
    bottom:0;
    right: 0;
    width: 11.25rem;
    height: 7.5rem;
    border-left: 0.063rem solid #888;
    border-top: 0.063rem solid #888;
    object-fit: cover;
  }
  
  .video-wrap .small-video video{
    object-fit: cover;
  }
  
  .video-wrap.gettingMedia {
    position: relative
  }
  
  .video-wrap.enteringRoom .main-video, .video-wrap.firstTimeGuidance .main-video, .video-wrap.gettingMedia .main-video, .video-wrap.preparing .main-video{ 
    background: #431D1F;
    border-radius: 2.5rem;
  }
  .video-wrap.enteringRoom, .video-wrap.firstTimeGuidance {
    overflow: hidden;
  }
  .invisible {
    display: none;
  }
  
  .silhouette {
    max-height: 100%;
    height: 100%;
    max-width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate3d(-50%, -50%, 0);
            transform: translate3d(-50%, -50%, 0);
    object-fit: cover;
  }
  #progressbar {
    border-radius: 0;
    position: absolute;
    top:0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  
  .enteringRoomState{
    position: absolute;
    width: 100%;
    margin: auto;
    left:0;
    right:0;
    top:0;
     bottom: 0;
     height:2.5rem;
  }
  
  .video-wrap.enteringRoom .enteringRoomState, .video-wrap.firstTimeGuidance .enteringRoomState {
    top: 3.563rem;
    bottom: auto;
  }
  .enteringRoomState p {
    color: #FFFFFF;
  }
  
  .enteringRoomState .someone-text {
    font-size: 1.5rem;
    line-height: 2.5rem;
    margin: 0 0.625rem 0 0.938rem;
  }
  .enteringRoomState .TipsContainer {
    max-width: 34.375rem;
    margin: 3rem auto;
  }
  .enteringRoomState .TipsContainer p {
    line-height: 24px;
  }
  
  .enteringRoomState .btn-default{
    border: none!important;
    min-width: 18.75rem;
    padding: 0.4375rem 0.9375rem !important;
    font-size: 1rem;
    font-weight: bold !important;
    margin-bottom: 20px;
  }
  
  .ready-state-text, .disconnected-state-text {
    position: absolute;
    width: 100%;
    margin: auto;
    left:0;
    right:0;
    top:0;
    bottom: 0;
    height:2.5rem;
  }
  
  .video-wrap.gettingMedia .getting-media-text {
    position: absolute;
    width: 100%;
    left: 0;
    right: 0;
    top: 2.5rem;
    height: 100%;
  }
  
  #CancelButton button {
    border: 1px solid #fff !important;
    color: #FFFFFF;
  }
  
  .video-wrap.enteringRoom #CancelButton button, .video-wrap.firstTimeGuidance #CancelButton button {
    border-color: #FFFFFF!important;
    border: 1px solid #FFFFFF
  }
  
  .video-wrap.gettingMedia #CancelButton {
    position: absolute;
    width: 100%;
    margin: auto;
    left: 0;
    bottom: 3.125rem;
    height: 2.5rem;
  }
  
  .video-wrap.enteringRoom #CancelButton, .video-wrap.firstTimeGuidance #CancelButton {
    position: absolute;
    width: 100%;
    margin: auto;
    left: 0;
    height: 2.5rem;
    top: 23.75rem;
  }
  
  #EndConnectionButton {
    position: absolute;
    width: 100%;
    margin: auto;
    left: 0;
    top: 0;
    height: 2.5rem;
    bottom: -85vh;
  }
  
  #EndConnectionButton #BtnEndConnection {
    color: #252525;
    font-weight: 500 !important;
    background: #E5E5E5; 
    border-color: 0.188rem solid #979797 !important
  }
  
  .video-wrap.preparing #CancelButton{
    width:7.5rem;
    position: relative;
    display: inline-block;
    margin-top:45%;
  }
  
  #StartButton {
    width:7.5rem;
    position: relative;
    display: inline-block;
    margin-top:45%;
  }
  #StartButton button #BtnStart {
    color: #252525;
    font-weight: 500 !important;
    background: #E5E5E5!important; 
    border-color: 0.188rem solid #979797 !important
  }

  .ready-state-text, .disconnected-state-text {
    font-size: 1.5rem;
  }
  .getting-media-text p, .ready-state-text p, .disconnected-state-text p {
    color: #757575;
  }
  
  .getting-media-text p {
    font-size: 1rem;
    color: #CBA2A7;
    line-height: 1.75rem;
    text-align: left;
  }
  .getting-media-text .permissionContainer {
    display: block;
    margin: auto;
    max-width: 23.125rem;
    padding: 6rem 0.625rem 0;
  }
  .getting-media-text .permissionContainer img{
    width: 1.875rem;
    float: left;
    margin-top: 1.25rem;
  }
  .getting-media-text .permissionContainer .permission-text{
    width: calc(100% - 1.875rem);
    padding-left: 1.5rem;
    float: left;
  }
  .getting-media-text .heading-text{
    font-size: 1.5rem;
    line-height: 2.5rem;
    color: #FFFFFF;
    text-align: center;
    padding: 0 0.625rem;
  }
  
  .ready-state-text, .disconnected-state-text {
    margin: 0 auto;
    top: 15%;
  }
  
  .disconnected-state-text .spinner, .ready-state-text .spinner {
    float: inherit!important;
  }
  
  .disconnected-state-text h4 {
    color:#fff!important;
  }


  @media (max-width: 1126px) {
    .guidanceButtonContainer button {
      width: 100%;
    }
    .guidanceButtonContainer .spincontainer {
      position: static;
    }
    .guidanceContainer {
      padding-bottom: 200px;
      height: auto !important;
    }
  }
  
  @media (max-width: 1200px) and (min-width: 767px) {
    .enteringRoomState .TipsContainer {
      margin: 1rem auto;
      margin-bottom: 0;
      margin-top: 0;
    }
    .video-wrap.enteringRoom #CancelButton, .video-wrap.firstTimeGuidance #CancelButton {
      top: 19rem;
    }
    .getting-media-text .permissionContainer {
      padding-top: 3rem;
    }
  }
  
  @media(max-width: 991px) {
    .getting-media-text .permissionContainer {
      padding-top: .5rem;
    }
    .guidelines, .guidanceButtonContainer {
      padding: 0 2rem;
    }
    .video-wrap.enteringRoom, .video-wrap.firstTimeGuidance {
      overflow: hidden;
    }
  }
  @media(max-width: 900px) {
    .getting-media-text .permissionContainer {
      padding-top: 0rem;
    }
    .video-wrap.gettingMedia #CancelButton {
      bottom: 1.125rem;
    }
    .video-wrap.gettingMedia .getting-media-text {
      top: 1.5rem;
    }
  
  }
  
  /*Notification Issue in following devices*/

  @media (min-width: 767px) and (max-width: 800px){
    .enteringRoomState .someone-text {
      font-size: 1.4rem;
    }
  }
  
  @media (max-width: 767px) {
    .enteringRoomState{
      position: absolute!important;
      margin: inherit!important;
    }
    .video-wrap.enteringRoom .enteringRoomState, .video-wrap.firstTimeGuidance .enteringRoomState {
      position: absolute;
    }
  
    .video-wrap.preparing #CancelButton {
      top: -5rem;
      bottom: inherit!important;
      margin-top: inherit!important;
    }
  
    .getting-media-text, .ready-state-text, .disconnected-state-text {
      margin-top: 0.313rem;
      font-size: .9rem;
    }
  
    .getting-media-text, .ready-state-text {
      position: relative;
      top:-8.75rem;    
    }
  
    #StartButton {
      top: -5rem;
      bottom: inherit!important;
      margin-top: inherit!important;
    }
  
    #StartButton .btn-default{
      border:0.188rem solid #fff;
    }
  
    /*Connected Stage*/
    #EndConnectionButton {
      top: -5rem;
    }
  
    .video-wrap.preparing .low-text{
      top: 1.25rem;
      margin: 0 auto;
      left:0;
      right:0;
      max-width: 18.75rem;
    }
    .video-wrap.gettingMedia #CancelButton {
      bottom: 3.125rem;
    }

    .guidanceContainer {
      position: absolute;
      padding-bottom: 100px;
      overflow:scroll;
      max-height:100%;
    }
    .guidanceButtonContainer {
      position: relative;
      margin-top: 2rem;
    }
    .video-wrap.enteringRoom .main-video, .video-wrap.firstTimeGuidance .main-video {
      border-radius: 0;
    }
    .guidanceButtonContainer button {
      width: 100%;
    }
  
  }
  
  
  /* FULL SCREEN STYLES */
  .video-wrap:-webkit-full-screen {
    width: 100%;
    height: 100%;
  }
  .video-wrap:fullscreen {
    width: 100%;
    height: 100%;
  }
  /* Webkit (Chrome, Opera & Safari) */
  .video-wrap:-webkit-full-screen {
    width: 100%;
    height: 100%;
  }
  /* Gecko (Firefox) */
  .video-wrap:-moz-full-screen {
    width: 100%;
    height: 100%;
  }
  /* Trident (Internet Explorer) */
  .video-wrap:-ms-fullscreen {
    width: 100%;
    height: 100%;
  }
  
  
  /*digesting-screen-full Stage*/
  .digesting-screen-full {
    width: 100vw;
    height: 100vh!important;
    height:calc(1vh * 100)!important;
    height:calc(var(--vh, 1vh) * 100)!important;
    margin-top: 0;
    border-radius: 0;
    max-width: 100%;
    overflow: hidden;
  }
  
  .digesting-screen-full h4{
    float: inherit!important;
    color:#fff!important;
  }
  .digesting-screen-full .spinner{
    float: inherit!important;
  }
  
  /*Preparing Stage*/
  .video-wrap.preparing .low-text{
    background: #FFFFFF;
    border-radius: 1.25rem;
    padding: 1.825rem;
    position: absolute;
    width: 18.75rem;
    right: 1.25rem;
    top:1.25rem;
  }
  .video-wrap.preparing .low-text img {
    position: absolute;
    right: 0.938rem;
    top: 1rem;
    width: 0.9rem;
  }
  .video-wrap.preparing .low-text p{ 
    margin-bottom: 0;
  }
  
  /*Notification Issue in following devices*/
  @media (min-width: 768px) and (max-width: 975px){
    .video-wrap.enteringRoom, .video-wrap.firstTimeGuidance {
      max-width: 75%;
      height: 22.188rem!important;
    }
  
  }
  /*Small devices & Extra small devices (portrait phones, less than 576px)*/
  @media (max-width: 767px) {
  
    .flex-grow-1.fullBackground h1{
      font-size: 2.5rem!important
    }
  
    .lead {
      font-size: 1.2rem;
    }
  
    .video-wrap video{
      object-fit: cover;
    }
  
    .fullBackground{
      height:100vh;
      height:calc(1vh * 100);
      height:calc(var(--vh, 1vh) * 100);
      overflow: hidden;
    }
  
    .video-wrap{
      width: 100%;
      max-width: inherit;
      height: auto!important;
    }
    .video-wrap .main-video{
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto;
      width: 100%;
      max-width: inherit;
      margin-top: 0;
      background: #431D1F;
      height: 15rem!important;
      position: relative!important;
    }
  
    .video-wrap .small-video{
      width: 5.313rem;
      height: 7.5rem;
      right: 0;
      box-shadow: 0 0 1.25rem #666;
    }
  
     /*Preparing Stage*/
    .fullBackground .video-wrap.preparing, .fullBackground .video-wrap.gettingMedia {
      height:calc(100vh - 4.563rem)!important;
      height:calc((1vh * 100) - 4.563rem)!important;
      height:calc((var(--vh, 1vh) * 100) - 4.563rem)!important;
      max-height:calc(100vh - 4.563rem)!important;
      max-height:calc((1vh * 100) - 4.563rem)!important;
      max-height:calc((var(--vh, 1vh) * 100) - 4.563rem)!important;
      margin-bottom: 0!important;
      border-radius: 0!important;
      overflow: hidden!important;
    }
  
    .fullBackground .video-wrap.preparing .main-video, .fullBackground .video-wrap.gettingMedia .main-video {
      top: 0%!important;
      height:calc(100vh - 4.563rem)!important;
      height:calc((1vh * 100) - 4.563rem)!important;
      height:calc((var(--vh, 1vh) * 100) - 4.563rem)!important;
    }
  
    /*Waiting stage*/
  
    .fullBackground .video-wrap.enteringRoom, .fullBackground .video-wrap.firstTimeGuidance {
      height:calc(100vh)!important;
      height:calc((1vh * 100))!important;
      height:calc((var(--vh, 1vh) * 100))!important;
      max-height:calc(100vh - 4.563rem)!important;
      max-height:calc((1vh * 100))!important;
      max-height:calc((var(--vh, 1vh) * 100))!important;
      margin-bottom: 0!important;
      border-radius: 0!important;
      overflow: hidden!important;
    }
  
    .fullBackground .video-wrap.enteringRoom .main-video, .fullBackground .video-wrap.firstTimeGuidance .main-video {
      top: 0%!important;
      height:calc(100vh)!important;
      height:calc((1vh * 100))!important;
      height:calc((var(--vh, 1vh) * 100))!important;
    }
  
    .fullBackground nav, .fullBackground .welcomecontainer, .fullBackground #Lobby .container, .fullBackground + footer {
      display: none
    }
    .video-wrap.enteringRoom .main-video, .video-wrap.firstTimeGuidance .main-video, .video-wrap.gettingMedia .main-video {
      border-radius: 0;
    }
  
    /*Disconnected State*/
  
    .fullBackground .video-wrap.disconnected{
      height:calc(100vh)!important;
      height:calc((1vh * 100))!important;
      height:calc((var(--vh, 1vh) * 100))!important;
      max-height:calc(100vh)!important;
      max-height:calc((1vh * 100))!important;
      max-height:calc((var(--vh, 1vh) * 100))!important;
      margin-bottom: 0!important;
      border-radius: 0!important;
      overflow: hidden!important;
    }
  
    .fullBackground .video-wrap.disconnected .main-video{
      top: 0%!important;
      height:calc(100vh)!important;
      height:calc((1vh * 100))!important;
      height:calc((var(--vh, 1vh) * 100))!important;
    }
  
    /*Ready*/
    .video-wrap.ready.digesting-screen-full, .video-wrap.connected.digesting-screen-full, .video-wrap.disconnected.digesting-screen-full{
      height:100vh!important;
      height:calc(1vh * 100)!important;
      height:calc(var(--vh, 1vh) * 100)!important;
      background: #431D1F;
    }

    .video-wrap.digesting.digesting-screen-full {
      height:100vh!important;
      background: #431D1F;
    }
  
    /*Connected full-screen Start*/
    .video-wrap.connected.digesting-screen-full .main-video{
      height:100vh!important;
      height:calc(1vh * 100)!important;
      height:calc(var(--vh, 1vh) * 100)!important;
    }
  
    .connected.digesting-screen-full.video-wrap .main-video #mainVideoTag {
      min-width: 100%;
      height:100%;
    }
    /*Connected full-screen End*/
    /*digesting Stage*/
  
    .video-wrap.digesting.digesting-screen-full .main-video{
      display: none;
    } 
  
  }

  
  @media (max-width: 991.98px) {

    .videoConatainer {
      padding-top: 1.563rem;
    }
  }
  
  @media (max-width: 575px) {
    .video-wrap.gettingMedia #CancelButton {
      bottom: 1.125rem;
    }
  }
  
  @media(max-width: 767px) {
    .ready-state-text {
      top: -3.45rem;
    }
    .disconnected-state-text{
      top: 5.5rem;
    }

  }

  @media(max-width: 600px) {
    .enteringRoomState .TipsContainer {
      margin: 2rem 0.625rem;
    }
    .video-wrap.enteringRoom #CancelButton, .video-wrap.firstTimeGuidance #CancelButton {
      top: 27.75rem;
    }
  }
  
  @media (max-width: 480px) {
    .disconnected-state-text{
      top: 3.45rem;
    }
    .enteringRoomState .TipsContainer {
      margin: 1.5rem 0.625rem;
    }
    .flex-grow-1.fullBackground h1{
      font-size: 1.5rem!important;
      height: 4.063rem;
      line-height: 4.063rem;
      padding: 0!important;
    }
  }
  
.experienceCardContainer {
    margin: 0 0 2rem;
    position: relative;
    text-align: left;
    height: 38.25rem;
}


.experienceCardContainer .durationBadge {
    position: absolute;
    left: 1.25rem;
    z-index: 10;
    top: 0.9375rem;
    width: 50px;
    height: 25px;
    border-radius: 12.6px;
    background-color: #ffffff;
    font-size: 10.5px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.87);
    padding-top: 5px;
    padding-bottom: 7px;
    text-align: center;
}


.topExperienceContainer {
    height: 16.875rem;
    position: relative;
    border-radius: 0.7875rem;
    background: url(/static/media/bitmap.95a1ce22.png);
    background-size: cover;
    background-position: center;
    overflow: hidden;
}

.topExperienceContainer .experience-card-background-image{
    position: absolute;
    width: 100%;
    height: 100%;
    right: 0;
    bottom: 0;
    /*background: rgba(67, 29, 31,0.8);*/
    opacity: 0.8;
}

.topExperienceContainer .experienceTitle {
    font-size: 1.575rem;
    font-weight: 600;
    line-height: 1.33;
    text-align: left;
    /*padding: 2.5rem 2.5rem;*/
    color: #FFFFFF;
    z-index: 10;
    position: absolute;
    bottom: 40px;
    left: 35px;
}

.topExperienceContainer .author{
    font-size: 0.7875rem;
    line-height: 1.67rem;
    font-weight: 500;
    color: #FFFFFF;
    z-index: 10;
    position: absolute;
    bottom: 8px;
    left: 35px;
}

.experienceContent {
    height: 10.125rem;
    position: relative;
    padding: 0 0.25rem;
}

.experienceContent .experienceTabAbout {
    border: none;
    margin-right: 2rem;
    padding: 0 0 0.625rem 0;
    font-size: 0.7875rem;
    font-weight: bold;
    cursor: pointer;
    width: 3rem;
    line-height: 1rem;
    opacity: 0.8;
    color: inherit;
}

.experienceContent .experienceTabOptions {
    border: none;
    padding: 0 0 0.625rem 0;
    font-size: 0.7875rem;
    font-weight: bold;
    cursor: pointer;
    width: 3.815rem;
    line-height: 1rem;
    opacity: 0.8;
    color: inherit;
}

.experienceContent .experienceTabActive {
    border-bottom: 0.125rem solid;
    opacity: 1;
}


.experienceContent .experienceTabContent {
    height: 4.125rem;
    margin-bottom: 26px;
}

.experienceContent .experienceDescription {
    font-size: 0.75rem;
    line-height: 1.83;
/*    margin-top: 1rem;*/
}

.experienceContent .experienceDescription a {
    line-height: 1.83;
    margin-top: 1rem;
    /*color: white;*/
}

.switchContainer {
    position: relative;
    width: 100%;
    height: 40px;
    margin: auto;
    font-size: 0.75rem;
    font-weight: 500;
}

.switchLabel {
    position: absolute;
    width: 80%;
    top: 0;
    left: 0;
    text-align: left;

}

.switchMain {
    position: absolute;
/*    width: 84px;*/
    top: 0;
    right: 0;
}

.experienceCardContainer .connectButtonsArea {
    /*position: absolute;*/
    width: 100%;
    height: 7.25rem;
    /*bottom: 0;*/
    padding-top: 0.5rem;
}
.experienceCardContainer .connectButtonsArea .btn {
    height: 2.625rem;
    font-size: 0.8125rem;
    width: 19.375rem;
}

.experienceConnectButton, .experienceCancelButton {
    padding: 0.5rem 0.25rem;
}

.experienceCardContainer .connectButtonsArea #BtnCancel {
    font-size: 0.775rem;
    background-color: #dfcccd!important;
    color: #540b10;
}


@media (max-width: 400px) {
    .experienceCardContainer .connectButtonsArea .btn {
        width: 17.5rem;
        font-size: 0.785rem;
    }
    .experienceCardContainer .connectButtonsArea #BtnCancel {
        font-size: 0.7rem;
    }

}

@media (max-width: 360px) {
    .experienceCardContainer .connectButtonsArea .btn {
        width: 15.5rem;
        font-size: 0.725rem;
    }
    .experienceCardContainer .connectButtonsArea #BtnCancel {
        font-size: 0.6rem;
    }

}
.selectedLanguage {
    position: absolute;
    left: 0;
    right: 0;
    top: -40px;
}
.BtnSelectLanguage {
    font-weight: bold !important;
    height: 80px;
    width: 215px;
    font-size: 1rem;
    padding-top: 35px!important;
}
.BtnSelectLanguage:hover {
    background: transparent !important;
    color: #CBA2A7 !important
}
.outerLanguageConatiner {
    padding: 15px;
    padding-top: 0;
}
.mainLanguageContainer {
    max-width: 340px;
    margin: auto;
}
.BtnSelectLanguage img{
    margin-right: 10px;
}
.selectLanguageDropdown {
    background: #ffffff;
    padding: 0 40px;
    text-align: left;
    border-bottom-right-radius: 40px;
    border-bottom-left-radius: 40px;
    padding-bottom: 10px;
}
.openDropdown, .openDropdown:hover {
    color: #794D4F!important;
    border: none!important;
}
.languageItem {
    padding: 10px 0;
    cursor: pointer;
}
.border-top {
    border-top: 1px solid #794D4F!important;
}

@media(max-width: 1180px) {
    .selectLanguageDropdown {
        padding: 0 30px;
        padding-bottom: 10px;
    }
    .outerLanguageConatiner {
        padding: 0
    }
    .mainLanguageContainer {
        max-width: 290px;
    }
    .languageItem {
        padding: 7px 0;
    }
}
.topCarousel {
	margin: auto;
	padding-bottom: 4.25rem;
	background-image: linear-gradient(to bottom, #9c676c, #48070c);
}

.custom-carousel-item {
  height: 17rem;
  padding-top: 2rem;
  color: white;
  padding-left: 3rem;
  padding-right: 3rem;
}


.custom-carousel-indicator li{
	width: 10px;
	height: 10px;
	border-radius: 100%;
	margin-left: 10px;
	margin-right: 20px;
}


.custom-carousel-item .btn {
    height: 3.125rem;
    /*font-size: 1rem;*/
    font-weight: bold!important;
}

.custom-carousel-item .every-day {
  font-size: 2.25rem;
  font-weight: 600;
  max-width: 35rem;
  margin: auto;
}

.carousel-gathering .every-day {
  max-width: 25rem;
  margin: auto;
}

.custom-carousel-item .next-at {
  font-size: 1.5rem;
  font-weight: 600;
}

.custom-carousel-item .next-at a {
  font-size: 1.5rem;
  font-weight: 600;
  color: white;
}


@media (max-width: 767px) {
  .custom-carousel-item {
	  padding-left: 2rem;
	  padding-right: 2rem;
  }
  .custom-carousel-item .every-day {
    font-size: 2rem;
  }  


  .custom-carousel-item .next-at, .custom-carousel-item .next-at a {
    font-size: 1.2rem;
  }
}

@media (max-width: 575px) {
  .custom-carousel-item {
	  padding-top: 2rem;
	  padding-left: 1rem;
	  padding-right: 1rem;
  }
  .custom-carousel-item .every-day {
    font-size: 1.55rem;
  }  
  .carousel-gathering .every-day {
    max-width: 22.5rem;
    margin: auto;
  }
  .custom-carousel-item .next-at, .custom-carousel-item .next-at a {
    font-size: 1.125rem;
  }
}
/*Banner CSS*/
.bannerbox {
  position: relative;
  height:calc(100vh / 1.2);
  min-height:43.75rem;
  max-height: 59.375rem;
  overflow: hidden;
}
.bannerbox img{
    width:100%;
    height: 100%;
    object-fit: cover;
}
.bannertext{
    position: absolute;
    width: 100%;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(180.02deg, rgba(248, 233, 222, 0) 0.3%, #F8E9DE 63.26%);
    mix-blend-mode: normal;
    padding-bottom: 1.25rem;
    height: 40%;
  }

.drawingsGradient{
    background: linear-gradient(180.02deg, #F8E9DE , white);
  }

  .bannertext h1{
    max-width: 48.75rem;
    margin: 0px auto;
    margin-top: 0.875rem;
    text-align: center;
    font-weight: 900;
    font-size: 4rem;
    line-height: 4.75rem;
    font-style: normal;
    color: #6A3E40;
  }
  .bannertext p {
      max-width: 34.375rem;
      text-align: center;
      font-size: 1.125rem;;
      margin: 0.875rem auto 2.5rem;
  }
  .welcomecontainer h1{
    max-width: 48.75rem;;
    color: #6A3E40;
    text-align: center;
    font-size: 3rem;;
    margin: 0.625rem auto 0;
    text-transform: capitalize;
}

.lineDrawingContainer {
  margin-bottom: 4rem;
}
.lineDrawingContainer img {
  height: 200px;
  max-width: 100%;
}
.lineDrawingContainer .spaceTitle{
  font-weight: 500;
  font-size: 1.375rem;
  line-height: 1.875rem;
  margin-top: 3rem;
}
.lineDrawingContainer .spaceText{
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.75rem;
  padding: 0 0.625rem;
}
.lineDrawingRow {
  justify-content: center;
}

@media (max-width: 1024px) {
  .lineDrawingRow .col-sm-4 {
    flex: 0 0 50%;
    max-width: 50%;
  }
}
/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {
  .bannerbox {
    min-height: inherit;
    max-height: 26.25rem;
    margin-bottom: 3.438rem;
  }

  .bannertext h1 {
    max-width: 75%;
    font-size: 1.938rem;
    line-height: 2.75rem;
    margin-top: 3rem;

  }
  .bannertext p {
    max-width: 28.125rem;
    font-size: 0.875rem;
    margin: 0.313rem auto 0.938rem;
  }
}
@media (min-width: 834px) and (max-width: 920px){
  .bannertext{
    bottom:-5%;
  }
  .bannertext h1 {
    margin-top: 3rem;
  }
}
@media (min-width: 576px) and (max-width: 920px){
  .bannertext h1{
    font-size: 1.75rem;
    line-height: 2.063rem;
    margin-top: 3rem;
  }
}
@media (max-width: 683px) {
  .lineDrawingRow .col-sm-4 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
/*Extra small devices (portrait phones, less than 576px)*/
@media (max-width: 575.98px) {
  .bannerbox{
    height: 27.5rem;
    overflow: hidden;
  }
  .bannerbox img{
    height:100%;
    object-fit: cover;
  }
  .bannertext h1 {
    max-width: 97%;
    font-size: 1.875rem;
    line-height: 2.5rem;
    margin-top: 2rem;
  }
  .welcomecontainer h1{
    font-size: 1.875rem;
    line-height: 2.5rem;
    margin: 0;
    padding: 0 !important;
  }
  .welcomecontainer {
    margin: 0!important;
    padding-bottom: 0.5rem;
  }
  .bannertext p {
    max-width: 90%;
    font-size: 1rem;
  }
}
/*Extra extra small devices (portrait phones, less than 576px)*/

@media (max-width: 500px) {
  .bannertext{
    height: 46%;
  }
}

@media (max-width: 373px) {
  .bannertext {
    bottom: -10%;
  }
  .bannertext h1 {
    font-size: 1.563rem;
    line-height: 2rem;
    margin-top: 0.5rem;
  }
  .bannertext p {
    font-size: 0.875rem;
  }
  .bannerbox {
    margin-bottom: 1.875rem;
  }
}
.title-heading {
    line-height: 3.5rem;
    font-size: 1.75rem;
}

.collapse-container {
    position: absolute;
    left: -2.813rem;
    top: 0.813rem;
    width: 1.875rem;
    height: 1.875rem;
    border: 0.063rem solid #181414;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.main-container {
    position: relative;
    left: 2.813rem;
    padding-right: 2rem;
}
.topLeadingText {
  margin: auto;
  background-image: linear-gradient(to bottom, #9c676c, #48070c);
  color: white;
  /*height: 372px;*/
}

.centeredBox {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100%;
}

.centeredBox img {
  max-width: 100%;
  max-height: 100%;
}

.team-area img, .team-area-modal img {
  max-width: 90%;
  width: 90%;
  border-radius: 1rem;
}

.team-area img {
  margin-left: 5%;
  cursor: pointer;
}

.team-area-modal img {
  margin: auto;
}

.team-area p, .team-area h5 {
  text-align: justify;
  max-width: 90%;
  margin-left: 5%;
}

.team-area-modal p, .team-area-modal h5 {
  text-align: justify;
  max-width: 90%;
  margin: auto;
}

.partnerbox img {
    max-width: 100%;
    max-height: 100%;
}

.media-logo {
    width: 144px;
    margin: auto;
}

.media-logo img {
    height: 72px;
    margin: auto;
}

.contentpages h3{
  font-weight: 600!important;
}
.contentpages h4{
  font-weight: 600!important;
}
.contentpages label{
  font-weight: bold;
}
.contentpages p.quotetext{
  font-style: italic;
  text-align: left;
  font-size: 1.25rem;
}
.contentpages ol, .contentpages ul{
  margin: 0;
  padding: 0;
}
.contentpages ol li, .contentpages ul li{
  font-size: 1rem;
  line-height: 1.75rem;
  margin-left: 1.125rem;
  margin-bottom:0.625rem;
}
.contentpages h2{
  font-weight: 600!important;
}
/* Medium devices (tablets, less than 992px)*/
@media (max-width: 991.98px) {
  .contentpages h1, .contentpages h2{font-size:2.25rem!important;}
  .contentpages h3{
    font-size: 1.5rem!important;
  }
  .contentpages h4{
    font-size: 1.125rem!important;
  }
}

/*Extra small devices (portrait phones, less than 576px)*/
@media (max-width: 575.98px) {
    .contentpages h1, .contentpages h2{
      font-size:2.5rem!important;
    }
    .contentpages p{
      font-size:0.875rem!important;
    }
    .contentpages p.quotetext{
      font-size:1rem!important;
    }
    .contentpages h4 {
      font-size: 1rem!important;
  }
  .contentpages h3{
    font-size: 1.25rem!important;
    line-height: 1.875rem;
  }
  .contentpages ol li, .contentpages ul li{
    font-size: 0.875rem;
    line-height: 1.125rem;
  }
}
.imageBox img {
    max-width: 100%;
}

.feedback-page {
  text-align: left;
}

/*Extra small devices (portrait phones, less than 576px)*/
@media (max-width: 575.98px) {
.mobiletext-left{
  text-align: left!important;
}
}
.account-page {
  text-align: left;
}
.user-email {
  word-break: break-all;
}
.user-membership {
  word-break: break-all;
  display: inline;
}
.cancelSubscriptionButtonContainer {
  display: block;
  float: right;
}
.cancelSubscriptionButtonContainer button {
  display: inline !important;
  margin-left: 15px !important;
}
.modal-content{
    padding: 1.375rem 1.875rem;
}
.modal-header {
    padding:0rem;
    border:0rem solid #fff;
    position: relative;
}
.modal-header .close{
    position: absolute;
    right: 0rem;
    top: 0rem;
    padding: 0rem;
    margin: 0rem;
    font-size: 2rem;
    font-weight: normal;
}
.modal-header .modal-title{
    font-style: normal;
    font-weight: 800;
    font-size: 1.25rem;
    line-height: 2.125rem;
}
.modal-body{
    padding: 0rem;
    margin-top: 2rem;
}
.modal-body label{
    font-size: 1rem;
}
.modal-body .privacyPolicy{
    font-weight: 400;
}
.modal-body button{
    display: block;
    margin: 0rem auto 0.938rem;
    box-shadow: 0rem 0.313rem 1.063rem rgba(0, 0, 0, 0.15);
}
.authSpinner {
    margin: 0rem auto 0.938rem;
}

.authSpinner .spinner {
    float: none;
    display: block;
}
.textmodalfooter{
    display: inline-block;
    line-height: 2.5rem;
    font-size: 0.875rem;
    margin-right: 0.938rem;
}
.modal-footer{
    padding-left: 0rem;
    padding-right: 0rem;
    text-align: center;
    display: block;
    padding-bottom: 0rem;
}
.modal-body .form-group{
    margin-bottom: 1.5rem;
}
.form-check, .form-check a, .form-check label{
    font-size: 0.875rem;
}
.form-check-input{
    margin-top: 0.2rem;
    font-size: 1rem;
}
.modal-body .form-control{
    border: 1px solid #CBA2A7;
}

.modal-body #FormSocialLogin .invalid-feedback{
    display: block;
}

/*Extra small devices (portrait phones, less than 576px)*/
@media (max-width: 575.98px) {
    .modal-footer{
        padding: 0rem;
    }
    .modal-header .modal-title {
        font-size: 1.125rem;
        line-height: 1.5rem;
    }
}
.digesting-screen {
  color: white;
  width: 100%;
  height: 100%;
  min-height: 100%;
}
.landing-page-wrap{
  position: relative;
  height: 100vh;
  width: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.landing-page-wrap,
.landing-page-wrap .report-person-wrapper {
  position: absolute;
}
.landing-title{
  position: relative;
  display: flex;
  flex: 0 0 80px;
  align-items: center;
  justify-content: center;
}
.landing-title h3 {
  margin-top: 3.75rem;
  font-weight: 300;
  font-size: 2.25rem;
  line-height: 3rem;
}
.landing-exit{
  width: 50%;
  text-align: left;
  float: left;
  padding-left: 3.125rem;
}
.landing-exit.reportSent{
  width: 100%;
  text-align: center;
  padding-right: 0;
}
.landing-exit button, .report-person button, .landing-exit button:hover, .report-person button:hover, #BtnSendReport {
  color: #fff;
  border-color: #ffff;
}
.landing-exit button, .report-person button, .landing-exit button:hover, .report-person button:hover {
  padding: 0;
}

.report-person {
  width: 50%;
  text-align: right;
  float: left;
  padding-right: 3.125rem;
}
.digesting-button-container {
  width: 100%;
  position: relative;
  flex: 0 0 auto;
  margin-bottom: 3.125rem;
  margin-top: 2rem;
}
.thank-you-card {
  background: #ffffff;
  border-radius: 0.938rem;
  width: 18.75rem;
  padding: 2.063rem 0.625rem;
  margin: 0 auto 3.125rem;
  color: #794D4F;
}
.thank-you-card p {
  font-size: 0.875rem;
  line-height: 1.75rem;
  margin: 0;
}
.thank-person {
  margin-bottom: 3.125rem;
}
.thank-person button{
  padding: 0 !important;
}
.thank-person button, .landing-exit button, .report-person button {
  width: 300px;
}

.report-person button, .landing-exit button {
  height: 100px;
}

.thank-person .replacement, .landing-exit .replacement, .report-person .replacement {
  width: 300px;
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}

.button-content-wrap {
  height: 100%;
}

.button-content {
  position: relative;
  height: 100%;
}

.button-image, .button-text {
  position: absolute;
}

.button-image {
  width: 20%;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100%;
}

.button-text {
  width: 80%;
  height: 100%;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100%;
  font-size: 0.9rem;
  line-height: 1.5;
}

.button-text p {
  margin: 0;
  text-align: left;
}

.button-text .button-text-follow {
  font-weight: 400;
}


.thank-you-card img {
  margin-bottom: 1rem;
}
#reportExplanation {
  height: 7.875rem;
}
.report-person-wrapper {
  width: 100%;
}
#BtnOpenReportPerson img, #BtnSendReport img, #BtnThankPerson img {
  width: 30px;
}

#BtnMoveOn img {
  width: 40px;
}

#BtnSendReport img {
  margin-right: 0.5rem;
}

#BtnPersonIsInCircle img, #BtnAddToMyCircle img {
  width: 50px;
}

#BtnOpenReportPerson .whiteImg, #BtnOpenReportPerson:hover .whiteImg, #BtnSendReport .redImg, #BtnSendReport:hover .whiteImg {
  display: none;
}
#BtnOpenReportPerson:hover .redImg, #BtnSendReport:hover .redImg {
  display: inline;
}

#BtnRemoveFromCircle {
  text-align: center;
  color: #540b10 !important;
  height: auto;
  font-weight: 600;
  padding: 0.5rem 0;
}

#BtnRemoveFromCircle:hover {
  background: #794D4F !important;
  color: #FFFFFF !important;
  box-shadow: 0px 8px 17px rgba(128, 74, 73, 0.42) !important;
  border-radius: 0.39375rem !important;
}

#BtnPersonIsInCircle .dropdown-menu {
  padding: 0;
  border-radius: 0.39375rem !important;
}

#BtnPersonIsInCircle {
  padding-bottom: 3rem;
}

#BtnAddToMyCircle {
  margin-bottom: 3rem;
}

.customCheckbox{
  position: relative;
  line-height: 25px;
  cursor: pointer;
  margin-bottom: 10px;
  display: inline-block;
}
.customCheckbox input[type="checkbox"]{
  position: absolute;
  opacity: 0;
}
.customCheckbox span{
  display: inline-block;
  width: 25px;
  height: 25px;
  border-radius: 2px;
  background: #fff;
  float: left;
  margin-right: 10px;
  position: relative;
}
.customCheckbox span:after{
  content: '';
    display: inline-block;
    width: 7px;
    height: 2px;
    background: #431c1f;
    position: absolute;
    -webkit-transform: rotate(39deg);
            transform: rotate(39deg);
    top: 13px;
    right: 13px;
    opacity: 0;
}
.customCheckbox span:before{
  content: '';
    display: inline-block;
    width: 12px;
    height: 2px;
    -webkit-transform: rotate(-50deg);
            transform: rotate(-50deg);
    background: #431c1f;
    position: absolute;
    right: 4px;
    top: 11px;
    opacity: 0;
}
.customCheckbox input[type="checkbox"]:checked + span:after, .customCheckbox input[type="checkbox"]:checked + span:before{
  opacity: 1;
}

#RequestNotificationsComponentDiv .customCheckbox span{
  width: 18px;
  height: 18px;
  margin-top: 3px;
}

#RequestNotificationsComponentDiv .customCheckbox {
  margin-bottom: 0;
}

#RequestNotificationsComponentDiv .customCheckbox input[type="checkbox"]:checked + span {
  background: #9a6d6e;
}

#RequestNotificationsComponentDiv .customCheckbox span:before{
  right: 1px;
  top: 8px;
}

#RequestNotificationsComponentDiv .customCheckbox span:after{
  top: 10px;
  right: 8px;
}

#RequestNotificationsComponentDiv .customCheckbox.no-repeat span:before, #RequestNotificationsComponentDiv .customCheckbox.no-repeat span:after{
  background: #DADADA;
}

@media (min-width: 768px) and (max-width: 1215px) {
  .report-person-wrapper .reportContainer {
    flex: 0 0 80%;
    max-width: 80%;
    margin-left: 10%;
  }
}

@media (max-width: 767px) {
  .landing-exit, .report-person {
    padding: 0;
    text-align: center;
    width: 100%;
    margin-bottom: 1.25rem;
  }
  .thank-person {
    margin-bottom: 1.25rem;
  }
  .thank-you-card {
    margin: 0 auto 1.25rem;
    margin-bottom: 3rem;
  }

  .digesting-button-container {
    bottom: 0
  }
  .landing-title h3 {
    margin-top: 2.75rem;
    font-size: 2rem;
  }
  #RequestNotificationsComponentDiv .customCheckbox {
    margin-bottom: 0;
  }
  #RequestNotificationsComponentDiv .customCheckbox span:before, #RequestNotificationsComponentDiv .customCheckbox span:after{
    background: #DADADA;
  }

  #BtnPersonIsInCircle {
    padding-bottom: 0;
  }

  #BtnAddToMyCircle {
    margin-bottom: 0;
  }
}
/* For phones */
@media (max-width: 576px) {
  .report-person-wrapper {
    top:0.625rem;
    width: 100%;
  }  
}
.experienceCardRow {
    padding: 1.4rem 1.4rem 0;
    flex-wrap: inherit;
    margin: 0;
}
.flexBox {
    display: flex;
    overflow: auto;
}
.flexBox::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.flexBox {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.categoryTitle{
    font-size: 1.5rem;
    font-weight: bold;
    line-height: 1.33;
}

.categoryDescription {
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.63;
    padding: 20px;
    color: #424242;
}

.experience-scroll-arrows {
    width: 100%;
    height: 2.5rem;
    position: relative;
}

.experience-scroll-arrows .arrow-left {
    position: absolute;
    top: 0;
    left: 1.4rem;
}

.experience-scroll-arrows .arrow-right {
    position: absolute;
    top: 0;
    right: 1.4rem;
}

.itemContainer {
    width: 20rem;
    min-width: 20rem;
    margin: 0 10px 0 10px;
}

.owl-carousel .owl-item img {
    width: inherit!important;
}


@media (max-width: 1100px) {
    .experienceCardRow .col-md-3 {
        flex: 0 0 50%;
        max-width: 50%;
    }

}
@media (max-width: 767px) {
    .experienceCardRow .col-md-3 {
        flex: 0 0 100%;
        max-width: 100%;
    }
}

@media (max-width: 400px) {
    .experienceCardRow {
        padding: 1rem 1rem 0;
    }
    .itemContainer {
        width: 18rem;
        min-width: 18rem;
        margin: 0 10px 0 10px;
    }

    .experience-scroll-arrows .arrow-left {
        left: 1rem;
    }

    .experience-scroll-arrows .arrow-right {
        right: 1rem;
    }
}

@media (max-width: 360px) {
    .experienceCardRow {
        padding: 0.8rem 0.8rem 0;
    }
    .itemContainer {
        width: 16rem;
        min-width: 16rem;
        margin: 0 8px 0 8px;
    }

    .experience-scroll-arrows .arrow-left {
        left: 0.8rem;
    }

    .experience-scroll-arrows .arrow-right {
        right: 0.8rem;
    }
}
.facilitatorPage {
	width: 100%;
	background-color: #3b161f;
	/*background-image: linear-gradient(to bottom, #9c676c, #48070c);*/
	color: white;
}

.facilitatorPageInfo {
	text-align: left;
	max-width: 80%;
	margin: auto;
}

.facilitator-lead {
	font-size: 0.875rem;
	font-weight: 500;
}

.facilitator-name {
	font-size: 2rem;
	font-weight: 600;
}

.facilitator-bio {
	font-size: 0.75rem;
}

.facilitator-bio a {
	color: white;
}

.centeredBoxFacilitatorPage {
  display: flex;
  /*justify-content: center;*/
  align-items: center;
  min-height: 100%;
}

.centeredBoxFacilitatorPage img {
  max-width: 100%;
  max-height: 100%;
}


@media (max-width: 768px) {
	.centeredBoxFacilitatorPage {
	  justify-content: center;
	}
}
.topDedicatedExperiencePageContainer {
    height: 26rem;
    position: relative;
    border-top-left-radius: 0.75rem;
    border-top-right-radius: 0.75rem;
    background: url(/static/media/bitmap.95a1ce22.png);
    background-size: cover;
    background-position: center;
}


.topDedicatedExperiencePageContainer .durationBadgeInModal {
    position: absolute;
    left: 35px;
    z-index: 10;
    top: 35px;
    width: 50px;
    height: 25px;
    border-radius: 12.6px;
    background-color: #ffffff;
    font-size: 10.5px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.87);
    padding-top: 5px;
    padding-bottom: 7px;
    text-align: center;
}


.topDedicatedExperiencePageContainer .closeModalX {
    position: absolute;
    /*width: 1.75rem;*/
    right: 1.25rem;
    z-index: 10;
    top: 0.9375rem;
    padding: 0;
    height: 15px;
    width: 15px;
    line-height: 15px;
    cursor: pointer;
}

.topDedicatedExperiencePageContainer .closeModalX img {
    height: 15px;
    width: 15px;
}



.topDedicatedExperiencePageContainer .dedicated-experience-background-image{
    position: absolute;
    width: 100%;
    height: 100%;
    right: 0;
    bottom: 0;
    /*background: rgba(67, 29, 31,0.8);*/
    opacity: 80%;
}

.topDedicatedExperiencePageContainer .experienceTitle {
    font-size: 28px;
    font-weight: 600;
    line-height: 1.33;
    text-align: left;
    /*padding: 2.5rem 2.5rem;*/
    color: #FFFFFF;
    z-index: 10;
    position: absolute;
    bottom: 164px;
    left: 35px;
}

.topDedicatedExperiencePageContainer .author{
    font-size: 0.75rem;
    line-height: 1.875rem;
    font-weight: 600;
    color: #FFFFFF;
    z-index: 10;
    position: absolute;
    bottom: 132px;
    left: 35px;
}


.infoImg {
    position: absolute;
    /*width: 1.75rem;*/
    left: 1.25rem;
    z-index: 10;
    top: 0.9375rem;
}

.experience-requirements {
    font-size: 0.75rem;
    font-weight: 500;
}

.microphoneImg {
    width: 1.8rem;
    height: 1.8rem;
    margin-right: 0.5rem;
}

.speakerImg {
    width: 1.5rem;
    height: 1.5rem;
    margin-right: 0.8rem;
}

.guidedImg {
    width: 1.5rem;
    height: 1.5rem;
    margin-right: 0.8rem;
}

.clockImg {
    width: 1.8rem;
    height: 1.8rem;
    margin-right: 0.5rem;
}


.experienceDescriptionModal {
    font-size: 0.75rem;
    line-height: 1.83;    
}


.topDedicatedExperiencePageContainer .connectButtonsArea {
    position: absolute;
    width: 100%;
    height: 7.25rem;
    top: 16.875rem;
    /*padding-top: 0.5rem;*/
}
.topDedicatedExperiencePageContainer .connectButtonsArea .btn {
    height: 2.625rem;
    font-size: 0.8125rem;
    width: 19.375rem;
}

.topDedicatedExperiencePageContainer .experienceConnectButton, .topDedicatedExperiencePageContainer .experienceCancelButton {
    padding: 0.5rem 0;
    width: 19.375rem;
    margin: auto;
}

.topDedicatedExperiencePageContainer .connectButtonsArea #BtnCancel {
    font-size: 0.775rem;
    background-color: #dfcccd!important;
    color: #540b10;
}

.experienceVideoTitle {
    font-size: 1.5rem;
    font-weight: bold;
    line-height: 1.33;
}

.experienceVideo iframe {
    width: 100%;
}


.facilitatorCard {
    min-height: 5rem;
    width: 100%;
    position: relative;
    border-radius: 0.25rem;
    background: #f3f3f3;
}

.facilitatorImg {
    position: absolute;
    width: 3.75rem;
    height: 3.75rem;
    bottom: 0.625rem;
    left: 1rem;
    border-radius: 50%;
}

.facilitatorName {
    position: absolute;
    left: 6.25rem;
    top: 1.125rem;
    font-size: 1.25rem;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.87);
}

.facilitatorRole {
    position: absolute;
    left: 6.25rem;
    top: 2.8rem;
    font-size: 0.8125rem;
    font-weight: 400;
    color: #000000;
    opacity: 0.55;
}

.facilitatorArrow {
    position: absolute;
    right: 2rem;
    top: 1.5rem;
    font-size: 1.5rem;
    font-weight: 400;
    color: #000000;
    opacity: 0.55;
}

.my-modal-classname {
    padding: 0!important;
    border-radius: 0.75rem;
}

@media (max-width: 400px) {
    .topDedicatedExperiencePageContainer .connectButtonsArea .btn {
        width: 17.5rem;
        font-size: 0.785rem;
    }
    .topDedicatedExperiencePageContainer .connectButtonsArea #BtnCancel {
        font-size: 0.7rem;
    }

    .topDedicatedExperiencePageContainer .experienceConnectButton, .topDedicatedExperiencePageContainer .experienceCancelButton {
        width: 17.5rem;
    }

    .facilitatorName {
        left: 6rem;
    }

    .facilitatorRole {
        left: 6rem;
    }
}

@media (max-width: 360px) {
    .topDedicatedExperiencePageContainer .connectButtonsArea .btn {
        width: 15.5rem;
        font-size: 0.725rem;
    }
    .topDedicatedExperiencePageContainer .connectButtonsArea #BtnCancel {
        font-size: 0.6rem;
    }

    .topDedicatedExperiencePageContainer .experienceConnectButton, .topDedicatedExperiencePageContainer .experienceCancelButton {
        width: 15.5rem;
    }

    .facilitatorImg {
        width: 3rem;
        height: 3rem;
        bottom: 1rem;
        left: 1rem;
        border-radius: 50%;
    }

    .facilitatorName {
        left: 5rem;
    }

    .facilitatorRole {
        left: 5rem;
    }
}


