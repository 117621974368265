.presenceIndicatorDiv {
  width: 340px;
  height: 112px;
  margin: auto;
  position: relative;
  top: -56px;
  margin-bottom: 20px;
  color: #736060;
}

.presenceIndicatorDiv .presenceIndicatorBox1 {
  width: 166px;
  height: 112px;
  border-radius: 12px;
  box-shadow: 0 12px 35px 0 rgba(119, 66, 55, 0.15);
  background-color: #fcf5f1;
  position: absolute;
  top: 0;
  left: 0;
}

.presenceIndicatorDiv .presenceIndicatorBox2 {
  width: 166px;
  height: 112px;
  border-radius: 12px;
  box-shadow: 0 12px 35px 0 rgba(119, 66, 55, 0.15);
  background-color: #fcf5f1;
  position: absolute;
  top: 0;
  right: 0;
}

#presence-circle-explainer {
  position: absolute;
  top: 0;
  right: 0.5rem;
}

.people-number {
	padding-top: 16px;
	margin: auto;
  font-size: 24px;
  font-weight: bold;
  color: #795557;
}

@media (max-width: 376px) {
	.presenceIndicatorDiv {
	  width: 96%;
	}

	.presenceIndicatorDiv .presenceIndicatorBox1 {
	  width: 48%;
	}

	.presenceIndicatorDiv .presenceIndicatorBox2 {
	  width: 48%;
	}
}



