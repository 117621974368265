.facilitatorPage {
	width: 100%;
	background-color: #3b161f;
	/*background-image: linear-gradient(to bottom, #9c676c, #48070c);*/
	color: white;
}

.facilitatorPageInfo {
	text-align: left;
	max-width: 80%;
	margin: auto;
}

.facilitator-lead {
	font-size: 0.875rem;
	font-weight: 500;
}

.facilitator-name {
	font-size: 2rem;
	font-weight: 600;
}

.facilitator-bio {
	font-size: 0.75rem;
}

.facilitator-bio a {
	color: white;
}

.centeredBoxFacilitatorPage {
  display: flex;
  /*justify-content: center;*/
  align-items: center;
  min-height: 100%;
}

.centeredBoxFacilitatorPage img {
  max-width: 100%;
  max-height: 100%;
}


@media (max-width: 768px) {
	.centeredBoxFacilitatorPage {
	  justify-content: center;
	}
}