.digesting-screen {
  color: white;
  width: 100%;
  height: 100%;
  min-height: 100%;
}
.landing-page-wrap{
  position: relative;
  height: 100vh;
  width: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.landing-page-wrap,
.landing-page-wrap .report-person-wrapper {
  position: absolute;
}
.landing-title{
  position: relative;
  display: flex;
  flex: 0 0 80px;
  align-items: center;
  justify-content: center;
}
.landing-title h3 {
  margin-top: 3.75rem;
  font-weight: 300;
  font-size: 2.25rem;
  line-height: 3rem;
}
.landing-exit{
  width: 50%;
  text-align: left;
  float: left;
  padding-left: 3.125rem;
}
.landing-exit.reportSent{
  width: 100%;
  text-align: center;
  padding-right: 0;
}
.landing-exit button, .report-person button, .landing-exit button:hover, .report-person button:hover, #BtnSendReport {
  color: #fff;
  border-color: #ffff;
}
.landing-exit button, .report-person button, .landing-exit button:hover, .report-person button:hover {
  padding: 0;
}

.report-person {
  width: 50%;
  text-align: right;
  float: left;
  padding-right: 3.125rem;
}
.digesting-button-container {
  width: 100%;
  position: relative;
  flex: 0 0 auto;
  margin-bottom: 3.125rem;
  margin-top: 2rem;
}
.thank-you-card {
  background: #ffffff;
  border-radius: 0.938rem;
  width: 18.75rem;
  padding: 2.063rem 0.625rem;
  margin: 0 auto 3.125rem;
  color: #794D4F;
}
.thank-you-card p {
  font-size: 0.875rem;
  line-height: 1.75rem;
  margin: 0;
}
.thank-person {
  margin-bottom: 3.125rem;
}
.thank-person button{
  padding: 0 !important;
}
.thank-person button, .landing-exit button, .report-person button {
  width: 300px;
}

.report-person button, .landing-exit button {
  height: 100px;
}

.thank-person .replacement, .landing-exit .replacement, .report-person .replacement {
  width: 300px;
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}

.button-content-wrap {
  height: 100%;
}

.button-content {
  position: relative;
  height: 100%;
}

.button-image, .button-text {
  position: absolute;
}

.button-image {
  width: 20%;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100%;
}

.button-text {
  width: 80%;
  height: 100%;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100%;
  font-size: 0.9rem;
  line-height: 1.5;
}

.button-text p {
  margin: 0;
  text-align: left;
}

.button-text .button-text-follow {
  font-weight: 400;
}


.thank-you-card img {
  margin-bottom: 1rem;
}
#reportExplanation {
  height: 7.875rem;
}
.report-person-wrapper {
  width: 100%;
}
#BtnOpenReportPerson img, #BtnSendReport img, #BtnThankPerson img {
  width: 30px;
}

#BtnMoveOn img {
  width: 40px;
}

#BtnSendReport img {
  margin-right: 0.5rem;
}

#BtnPersonIsInCircle img, #BtnAddToMyCircle img {
  width: 50px;
}

#BtnOpenReportPerson .whiteImg, #BtnOpenReportPerson:hover .whiteImg, #BtnSendReport .redImg, #BtnSendReport:hover .whiteImg {
  display: none;
}
#BtnOpenReportPerson:hover .redImg, #BtnSendReport:hover .redImg {
  display: inline;
}

#BtnRemoveFromCircle {
  text-align: center;
  color: #540b10 !important;
  height: auto;
  font-weight: 600;
  padding: 0.5rem 0;
}

#BtnRemoveFromCircle:hover {
  background: #794D4F !important;
  color: #FFFFFF !important;
  box-shadow: 0px 8px 17px rgba(128, 74, 73, 0.42) !important;
  border-radius: 0.39375rem !important;
}

#BtnPersonIsInCircle .dropdown-menu {
  padding: 0;
  border-radius: 0.39375rem !important;
}

#BtnPersonIsInCircle {
  padding-bottom: 3rem;
}

#BtnAddToMyCircle {
  margin-bottom: 3rem;
}

.customCheckbox{
  position: relative;
  line-height: 25px;
  cursor: pointer;
  margin-bottom: 10px;
  display: inline-block;
}
.customCheckbox input[type="checkbox"]{
  position: absolute;
  opacity: 0;
}
.customCheckbox span{
  display: inline-block;
  width: 25px;
  height: 25px;
  border-radius: 2px;
  background: #fff;
  float: left;
  margin-right: 10px;
  position: relative;
}
.customCheckbox span:after{
  content: '';
    display: inline-block;
    width: 7px;
    height: 2px;
    background: #431c1f;
    position: absolute;
    transform: rotate(39deg);
    top: 13px;
    right: 13px;
    opacity: 0;
}
.customCheckbox span:before{
  content: '';
    display: inline-block;
    width: 12px;
    height: 2px;
    transform: rotate(-50deg);
    background: #431c1f;
    position: absolute;
    right: 4px;
    top: 11px;
    opacity: 0;
}
.customCheckbox input[type="checkbox"]:checked + span:after, .customCheckbox input[type="checkbox"]:checked + span:before{
  opacity: 1;
}

#RequestNotificationsComponentDiv .customCheckbox span{
  width: 18px;
  height: 18px;
  margin-top: 3px;
}

#RequestNotificationsComponentDiv .customCheckbox {
  margin-bottom: 0;
}

#RequestNotificationsComponentDiv .customCheckbox input[type="checkbox"]:checked + span {
  background: #9a6d6e;
}

#RequestNotificationsComponentDiv .customCheckbox span:before{
  right: 1px;
  top: 8px;
}

#RequestNotificationsComponentDiv .customCheckbox span:after{
  top: 10px;
  right: 8px;
}

#RequestNotificationsComponentDiv .customCheckbox.no-repeat span:before, #RequestNotificationsComponentDiv .customCheckbox.no-repeat span:after{
  background: #DADADA;
}

@media (min-width: 768px) and (max-width: 1215px) {
  .report-person-wrapper .reportContainer {
    flex: 0 0 80%;
    max-width: 80%;
    margin-left: 10%;
  }
}

@media (max-width: 767px) {
  .landing-exit, .report-person {
    padding: 0;
    text-align: center;
    width: 100%;
    margin-bottom: 1.25rem;
  }
  .thank-person {
    margin-bottom: 1.25rem;
  }
  .thank-you-card {
    margin: 0 auto 1.25rem;
    margin-bottom: 3rem;
  }

  .digesting-button-container {
    bottom: 0
  }
  .landing-title h3 {
    margin-top: 2.75rem;
    font-size: 2rem;
  }
  #RequestNotificationsComponentDiv .customCheckbox {
    margin-bottom: 0;
  }
  #RequestNotificationsComponentDiv .customCheckbox span:before, #RequestNotificationsComponentDiv .customCheckbox span:after{
    background: #DADADA;
  }

  #BtnPersonIsInCircle {
    padding-bottom: 0;
  }

  #BtnAddToMyCircle {
    margin-bottom: 0;
  }
}
/* For phones */
@media (max-width: 576px) {
  .report-person-wrapper {
    top:0.625rem;
    width: 100%;
  }  
}