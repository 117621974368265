.selectedLanguage {
    position: absolute;
    left: 0;
    right: 0;
    top: -40px;
}
.BtnSelectLanguage {
    font-weight: bold !important;
    height: 80px;
    width: 215px;
    font-size: 1rem;
    padding-top: 35px!important;
}
.BtnSelectLanguage:hover {
    background: transparent !important;
    color: #CBA2A7 !important
}
.outerLanguageConatiner {
    padding: 15px;
    padding-top: 0;
}
.mainLanguageContainer {
    max-width: 340px;
    margin: auto;
}
.BtnSelectLanguage img{
    margin-right: 10px;
}
.selectLanguageDropdown {
    background: #ffffff;
    padding: 0 40px;
    text-align: left;
    border-bottom-right-radius: 40px;
    border-bottom-left-radius: 40px;
    padding-bottom: 10px;
}
.openDropdown, .openDropdown:hover {
    color: #794D4F!important;
    border: none!important;
}
.languageItem {
    padding: 10px 0;
    cursor: pointer;
}
.border-top {
    border-top: 1px solid #794D4F!important;
}

@media(max-width: 1180px) {
    .selectLanguageDropdown {
        padding: 0 30px;
        padding-bottom: 10px;
    }
    .outerLanguageConatiner {
        padding: 0
    }
    .mainLanguageContainer {
        max-width: 290px;
    }
    .languageItem {
        padding: 7px 0;
    }
}