.topDedicatedExperiencePageContainer {
    height: 26rem;
    position: relative;
    border-top-left-radius: 0.75rem;
    border-top-right-radius: 0.75rem;
    background: url('../../resources/images/bitmap.png');
    background-size: cover;
    background-position: center;
}


.topDedicatedExperiencePageContainer .durationBadgeInModal {
    position: absolute;
    left: 35px;
    z-index: 10;
    top: 35px;
    width: 50px;
    height: 25px;
    border-radius: 12.6px;
    background-color: #ffffff;
    font-size: 10.5px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.87);
    padding-top: 5px;
    padding-bottom: 7px;
    text-align: center;
}


.topDedicatedExperiencePageContainer .closeModalX {
    position: absolute;
    /*width: 1.75rem;*/
    right: 1.25rem;
    z-index: 10;
    top: 0.9375rem;
    padding: 0;
    height: 15px;
    width: 15px;
    line-height: 15px;
    cursor: pointer;
}

.topDedicatedExperiencePageContainer .closeModalX img {
    height: 15px;
    width: 15px;
}



.topDedicatedExperiencePageContainer .dedicated-experience-background-image{
    position: absolute;
    width: 100%;
    height: 100%;
    right: 0;
    bottom: 0;
    /*background: rgba(67, 29, 31,0.8);*/
    opacity: 80%;
}

.topDedicatedExperiencePageContainer .experienceTitle {
    font-size: 28px;
    font-weight: 600;
    line-height: 1.33;
    text-align: left;
    /*padding: 2.5rem 2.5rem;*/
    color: #FFFFFF;
    z-index: 10;
    position: absolute;
    bottom: 164px;
    left: 35px;
}

.topDedicatedExperiencePageContainer .author{
    font-size: 0.75rem;
    line-height: 1.875rem;
    font-weight: 600;
    color: #FFFFFF;
    z-index: 10;
    position: absolute;
    bottom: 132px;
    left: 35px;
}


.infoImg {
    position: absolute;
    /*width: 1.75rem;*/
    left: 1.25rem;
    z-index: 10;
    top: 0.9375rem;
}

.experience-requirements {
    font-size: 0.75rem;
    font-weight: 500;
}

.microphoneImg {
    width: 1.8rem;
    height: 1.8rem;
    margin-right: 0.5rem;
}

.speakerImg {
    width: 1.5rem;
    height: 1.5rem;
    margin-right: 0.8rem;
}

.guidedImg {
    width: 1.5rem;
    height: 1.5rem;
    margin-right: 0.8rem;
}

.clockImg {
    width: 1.8rem;
    height: 1.8rem;
    margin-right: 0.5rem;
}


.experienceDescriptionModal {
    font-size: 0.75rem;
    line-height: 1.83;    
}


.topDedicatedExperiencePageContainer .connectButtonsArea {
    position: absolute;
    width: 100%;
    height: 7.25rem;
    top: 16.875rem;
    /*padding-top: 0.5rem;*/
}
.topDedicatedExperiencePageContainer .connectButtonsArea .btn {
    height: 2.625rem;
    font-size: 0.8125rem;
    width: 19.375rem;
}

.topDedicatedExperiencePageContainer .experienceConnectButton, .topDedicatedExperiencePageContainer .experienceCancelButton {
    padding: 0.5rem 0;
    width: 19.375rem;
    margin: auto;
}

.topDedicatedExperiencePageContainer .connectButtonsArea #BtnCancel {
    font-size: 0.775rem;
    background-color: #dfcccd!important;
    color: #540b10;
}

.experienceVideoTitle {
    font-size: 1.5rem;
    font-weight: bold;
    line-height: 1.33;
}

.experienceVideo iframe {
    width: 100%;
}


.facilitatorCard {
    min-height: 5rem;
    width: 100%;
    position: relative;
    border-radius: 0.25rem;
    background: #f3f3f3;
}

.facilitatorImg {
    position: absolute;
    width: 3.75rem;
    height: 3.75rem;
    bottom: 0.625rem;
    left: 1rem;
    border-radius: 50%;
}

.facilitatorName {
    position: absolute;
    left: 6.25rem;
    top: 1.125rem;
    font-size: 1.25rem;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.87);
}

.facilitatorRole {
    position: absolute;
    left: 6.25rem;
    top: 2.8rem;
    font-size: 0.8125rem;
    font-weight: 400;
    color: #000000;
    opacity: 0.55;
}

.facilitatorArrow {
    position: absolute;
    right: 2rem;
    top: 1.5rem;
    font-size: 1.5rem;
    font-weight: 400;
    color: #000000;
    opacity: 0.55;
}

.my-modal-classname {
    padding: 0!important;
    border-radius: 0.75rem;
}

@media (max-width: 400px) {
    .topDedicatedExperiencePageContainer .connectButtonsArea .btn {
        width: 17.5rem;
        font-size: 0.785rem;
    }
    .topDedicatedExperiencePageContainer .connectButtonsArea #BtnCancel {
        font-size: 0.7rem;
    }

    .topDedicatedExperiencePageContainer .experienceConnectButton, .topDedicatedExperiencePageContainer .experienceCancelButton {
        width: 17.5rem;
    }

    .facilitatorName {
        left: 6rem;
    }

    .facilitatorRole {
        left: 6rem;
    }
}

@media (max-width: 360px) {
    .topDedicatedExperiencePageContainer .connectButtonsArea .btn {
        width: 15.5rem;
        font-size: 0.725rem;
    }
    .topDedicatedExperiencePageContainer .connectButtonsArea #BtnCancel {
        font-size: 0.6rem;
    }

    .topDedicatedExperiencePageContainer .experienceConnectButton, .topDedicatedExperiencePageContainer .experienceCancelButton {
        width: 15.5rem;
    }

    .facilitatorImg {
        width: 3rem;
        height: 3rem;
        bottom: 1rem;
        left: 1rem;
        border-radius: 50%;
    }

    .facilitatorName {
        left: 5rem;
    }

    .facilitatorRole {
        left: 5rem;
    }
}

