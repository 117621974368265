.topCarousel {
	margin: auto;
	padding-bottom: 4.25rem;
	background-image: linear-gradient(to bottom, #9c676c, #48070c);
}

.custom-carousel-item {
  height: 17rem;
  padding-top: 2rem;
  color: white;
  padding-left: 3rem;
  padding-right: 3rem;
}


.custom-carousel-indicator li{
	width: 10px;
	height: 10px;
	border-radius: 100%;
	margin-left: 10px;
	margin-right: 20px;
}


.custom-carousel-item .btn {
    height: 3.125rem;
    /*font-size: 1rem;*/
    font-weight: bold!important;
}

.custom-carousel-item .every-day {
  font-size: 2.25rem;
  font-weight: 600;
  max-width: 35rem;
  margin: auto;
}

.carousel-gathering .every-day {
  max-width: 25rem;
  margin: auto;
}

.custom-carousel-item .next-at {
  font-size: 1.5rem;
  font-weight: 600;
}

.custom-carousel-item .next-at a {
  font-size: 1.5rem;
  font-weight: 600;
  color: white;
}


@media (max-width: 767px) {
  .custom-carousel-item {
	  padding-left: 2rem;
	  padding-right: 2rem;
  }
  .custom-carousel-item .every-day {
    font-size: 2rem;
  }  


  .custom-carousel-item .next-at, .custom-carousel-item .next-at a {
    font-size: 1.2rem;
  }
}

@media (max-width: 575px) {
  .custom-carousel-item {
	  padding-top: 2rem;
	  padding-left: 1rem;
	  padding-right: 1rem;
  }
  .custom-carousel-item .every-day {
    font-size: 1.55rem;
  }  
  .carousel-gathering .every-day {
    max-width: 22.5rem;
    margin: auto;
  }
  .custom-carousel-item .next-at, .custom-carousel-item .next-at a {
    font-size: 1.125rem;
  }
}