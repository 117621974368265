body {
  margin: 0;
  padding: 0;

  text-align: center;
  min-height: 100vh;
  width: 100vw;
  overflow-x: hidden;

}


#ccc-notify-dismiss {
	display: none !important;
}

/* For phones */
@media (max-width: 720px) {
  .mobtextcenter{
    text-align: center;
  }
  footer a{
    font-size: .9rem;
  }
}