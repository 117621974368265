.account-page {
  text-align: left;
}
.user-email {
  word-break: break-all;
}
.user-membership {
  word-break: break-all;
  display: inline;
}
.cancelSubscriptionButtonContainer {
  display: block;
  float: right;
}
.cancelSubscriptionButtonContainer button {
  display: inline !important;
  margin-left: 15px !important;
}