.title-heading {
    line-height: 3.5rem;
    font-size: 1.75rem;
}

.collapse-container {
    position: absolute;
    left: -2.813rem;
    top: 0.813rem;
    width: 1.875rem;
    height: 1.875rem;
    border: 0.063rem solid #181414;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.main-container {
    position: relative;
    left: 2.813rem;
    padding-right: 2rem;
}