.overflow-hidden {
  overflow: hidden;
}

.humanMinuteAreaContainer .exploreHM{
  max-width: 15rem;
  margin: 0 auto 1.25rem;
}

.humanMinuteAreaContainer .exploreHM img{
  margin-bottom: 0.938rem;
  height: 2rem;
  width: 2rem;
}

.humanMinuteAreaContainer .exploreHM h5{
  margin-bottom: 0.75rem;
  font-weight: bold;
  font-size: 1.5rem;
}
.humanMinuteAreaContainer .exploreHM p{
  margin-bottom: 0;
}

.humanMinuteAreaContainer .exploreHM--from-invite h5{
  font-weight: 500;
}

.humanMinuteAreaContainer {
  background: #eddcd2;
  padding: 2.813rem 0;
}

.hm-area-wrap{
  width:100%;
  max-width: 66.66%;
  position: relative;
  margin:0 auto;
  border-radius: 2.5rem;
  /* overflow: hidden; */
  background:transparent;
}

.hm-area-wrap .main-video{
  position: absolute;
  background: #431D1F;
  top:0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 2.5rem;
}

.hm-area-wrap .video-background{
  position: absolute;
  overflow: hidden;
  border-radius: 2.5rem;
  background: url('../../../resources/images/bitmap.png');
  background-size: cover;
  background-position: center;
  left: 0;
  top:0;
  width: 100%;
}

.hm-area-wrap .video-background .video-background-image{
  position: absolute;
  width: 100%;
  height: 100%;
  right: 0;
  bottom: 0;
  background: rgba(67, 29, 31,0.4);
}

.hm-area-wrap .video-background .my-video-image{
  position: absolute;
  height: 10rem;
  width: 15.813rem;
  right: 0;
  bottom: 0;
  background: url('../../../resources/images/bitmap_me.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  filter: blur(0.5rem);
}
.hm-area-wrap .video-background img{
  width: 100%;
}

.hm-area-wrap.present .main-video, .hm-area-wrap.absent .main-video { 
  background: transparent;
  box-sizing: border-box;
}

.notifications-checkbox {
  width: 320px;
  margin: auto;
  padding-left: 0.625rem;
  padding-right: 0.625rem;
  line-height: 1.35rem;
}

.waitingStateContainer {
  position: absolute;
  width: 100%;
  margin: auto;
  left:0;
  right:0;
  top: 1rem;
  bottom: auto;
  height:2.5rem;
  color: #FFFFFF;
}

.waitingStateContainer .someone-text {
  font-size: 1.5rem;
  line-height: 2.5rem;
  margin: 0 0.625rem 0.938rem 0;
}

.centeredBoxHM .btn-default{
  min-width: 15.75rem;
  height: 2.625rem
}

.centeredBoxHM .BtnAuthModal{
  min-width: 0;
}

#CancelButton button {
  border: 1px solid #fff !important;
  color: #FFFFFF;
}

.hm-area-wrap.waiting #CancelButton button {
  border-color: #FFFFFF!important;
  border: 1px solid #FFFFFF
}

.hm-area-wrap.waiting #CancelButton {
  position: absolute;
  width: 100%;
  margin: auto;
  left: 0;
  height: 2.5rem;
  bottom: 3rem;
}

.humanMinuteAreaContainer .videoVisitors {
  padding: 0 0.938rem;
  max-width: 66.66%;
  margin: 3.125rem auto 0;
}

.audio-message-text {
  background: #FFFFFF;
  border-radius: 1.25rem;
  padding: 1.825rem;
  position: absolute;
  width: 18.75rem;
  right: 1.25rem;
  top:1.25rem;
}


.hm-area-wrap.firstTimeGuidance .enteringRoomState {
  top: 3.563rem !important;
  bottom: auto !important;
  z-index: 1;
}


.hm-area-wrap.firstTimeGuidance #CancelButton button {
  border-color: #FFFFFF!important;
  border: 1px solid #FFFFFF
}


.hm-area-wrap.firstTimeGuidance #CancelButton {
  position: absolute;
  width: 100%;
  margin: auto;
  left: 0;
  height: 2.5rem;
  top: 23.75rem;
}


.guidanceContainer {
  position: relative;
  z-index: 1;
  color: #FFFFFF;
  margin-top: 60px;
  width: 100%;
  /*overflow:scroll;*/
}
.guidelines {
  padding: 0 4rem;
  text-align: left;
}
.guidelinesText {
  font-size: 1.5rem;
}
.guidanceButtonContainer {
  padding: 0 4rem;
  position: absolute;
  width: 100%;
  z-index: 1;
  bottom: 0;
  transition: all ease-in 0.2s;
}
.guidanceButtonContainer .buttons {
  display: block;
  position: relative;
}
.guidanceButtonContainer .BtnNotFollowGuidelines {
  float: left;
}
.guidanceButtonContainer .BtnFollowGuidelines {
  float: right;
}
.guidanceButtonContainer button {
  color: #fff;
  border-color: #fff;
  width: 280px;
  padding: 0 1rem !important;
}
.guidanceButtonContainer .BtnDisabledFollowGuidelines {
  float: right;
  color: gray;
  border-color: gray;
  opacity: 0.5;
}
.selectLanguageContainer {
  position: absolute;
  width: 100%;
  z-index: 2;
}

.guidanceButtonContainer .spincontainer {
  position: absolute;
  left: calc(50% - 20px);
  top: 0;
}

.guidanceButtonContainer .button_animation:hover {
  background: transparent !important;
  border-color: #FFFFFF;
  color: #FFFFFF !important; 
}

.centeredBoxHM {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100%;
  position: relative; 
  padding-top: 24px; 
  z-index: 1;
}

.socialloginContainer {
  position: relative;
  margin: 0 auto 1.25rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.socialloginContainer.connectButtonContainer {
  padding: 0 1rem;
  max-width: 40.625rem;	
  margin: auto;	
  color: #fff;
}
.socialloginContainer a{
  text-decoration: underline;
}
.socialloginContainer.connectButtonContainer a{
  color: #FFFFFF;
}
.socialloginContainer p {
  line-height: 1.75rem;
}
.socialloginContainer button {
  background: #FFFFFF;
  border-radius: 2.844rem;
  line-height: 1.75rem;
  height: 3rem;
  width: 100%;
  display: block;
  margin: 0 auto 1.25rem;
  font-size: 1rem;
  font-weight: bold!important;
  box-shadow: none!important;
  border: 1px solid #794D4F !important;
}
.socialloginContainer.connectButtonContainer button {
  width: 18.75rem;
  border: none!important;
}
.socialloginContainer .activeButton {
  background: #794D4F !important;
  color: #fff !important;
}
.socialloginContainer .btn:hover {
  box-shadow: none !important;
}
.socialloginContainer .facebookButton {
  color: #4469B0;
}
.socialloginContainer .facebookButton:focus {
  outline: none;
}
.socialloginContainer .facebookButton .fa-facebook-official:before {
  position: relative;
  top: 0.188rem;
  margin-right: 0.625rem;
  margin-left: -0.625rem;
  font-size: 1.5rem;
}
.presence-indicator {
  padding: 0 10px;
}

.hm-area-wrap.firstTimeGuidance {
  overflow: hidden;
}


@media (max-width: 1126px) {
  .guidanceButtonContainer button {
    width: 100%;
  }
  .guidanceButtonContainer .spincontainer {
    position: static;
  }
  .guidanceContainer {
    padding-bottom: 200px;
    height: auto !important;
  }
}

  
@media (max-width: 1200px) and (min-width: 767px) {

  .hm-area-wrap.waiting #CancelButton {
    bottom: 3rem;
  }

  .hm-area-wrap.firstTimeGuidance #CancelButton {
    top: 19rem;
  }
}

@media (min-width: 767px) {

  .share-one-minute {
    max-width: 66%;
    margin: auto;
  }
}


@media(max-width: 991px) {

  .guidelines, .guidanceButtonContainer {
    padding: 0 2rem;
  }
  .hm-area-wrap.firstTimeGuidance {
    overflow: hidden;
  }
}
  
@media (max-width: 767px) {

  #Lobby h2 {
    font-size: 1.75rem;
  }

  .socialloginContainer.connectButtonContainer {	
    padding: 2rem 1rem 0;	
  }

  .guidanceContainer {
    position: absolute;
    padding-bottom: 100px;
    overflow:scroll;
    max-height:100%;
  }
  .guidanceButtonContainer {
    position: relative;
    margin-top: 2rem;
  }
  .hm-area-wrap.firstTimeGuidance .main-video {
    border-radius: 0;
  }
  .guidanceButtonContainer button {
    width: 100%;
  }

  .fullBackground nav, .fullBackground .topCarousel, .fullBackground .welcomecontainer, .fullBackground #Lobby .container, .fullBackground #Lobby .presence-indicator,.fullBackground + footer, .fullBackground .hm-title {
    display: none
  }

}
  

/*Notification Issue in following devices*/
@media (min-width: 768px) and (max-width: 975px){
  .hm-area-wrap.present, .hm-area-wrap.absent, .hm-area-wrap.waiting {
    max-width: 75%;
    height: 22.188rem!important;
  }

  .hm-area-wrap.present .video-background, .hm-area-wrap.absent .video-background, .hm-area-wrap.waiting .video-background {
   
    height: 22.188rem!important;
  }
  
}


  /*Small devices & Extra small devices (portrait phones, less than 576px)*/
  @media (max-width: 767px) {
  
    .flex-grow-1.fullBackground h1{
      font-size: 2.5rem!important
    }
  
    .lead {
      font-size: 1.2rem;
    }
  
    .fullBackground{
      height:100vh;
      height:calc(var(--vh, 1vh) * 100);
      overflow: hidden;
    }
  
    .hm-area-wrap{
      width: 100%;
      max-width: inherit;
    }

    .fullBackground .hm-area-wrap.firstTimeGuidance {
      height:calc(100vh)!important;
      height:calc((var(--vh, 1vh) * 100))!important;
      max-height:calc(100vh - 4.563rem)!important;
      max-height:calc((var(--vh, 1vh) * 100))!important;
      margin-bottom: 0!important;
      border-radius: 0!important;
      overflow: hidden!important;
    }
  
    .fullBackground .hm-area-wrap.firstTimeGuidance .main-video {
      top: 0%!important;
      height:calc(100vh)!important;
      height:calc((var(--vh, 1vh) * 100))!important;
    }


  
  }
  
  @media (max-width: 1200px) {
    .hm-area-wrap .video-background .my-video-image{
      width: 12.5rem;
      height: 7.5rem;
    }
  }
  
  @media (max-width: 991.98px) {
    .humanMinuteAreaContainer {
      padding-top: 1.563rem;
    }
    .hm-area-wrap .video-background .my-video-image{
      width: 9.375rem;
      height: 6.25rem;
    }
  }

  @media(max-width: 600px) {

    .share-one-minute--from-invite {
      font-size: 1.5rem;
    }

    .hm-area-wrap.waiting #CancelButton {
      bottom: 2rem;
    }

    .hm-area-wrap.firstTimeGuidance #CancelButton {
      top: 27.75rem;
    }

    .hm-area-wrap.hm-area-social {
      height: 100%!important;
    }

    .hm-area-wrap.hm-area-social .video-background {
      height: 100%!important;
    }

  }
  
  @media (max-width: 480px) {
    .hm-area-wrap .video-background .my-video-image{
      width: 7.5rem;
      height: 5rem;
    }

    .flex-grow-1.fullBackground h1{
      font-size: 1.5rem!important;
      height: 4.063rem;
      line-height: 4.063rem;
      padding: 0!important;
    }
    .socialloginContainer button {
      font-size: 0.875rem;
    }

    .waitingStateContainer {
      top: 0;
    }

    .waitingStateContainer .someone-text {
      font-size: 1.25rem;
      line-height: 1.75rem;
    }

    .hm-area-wrap.waiting #CancelButton {
      bottom: 1rem;
    }
  }