.experienceCardRow {
    padding: 1.4rem 1.4rem 0;
    flex-wrap: inherit;
    margin: 0;
}
.flexBox {
    display: flex;
    overflow: auto;
}
.flexBox::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.flexBox {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.categoryTitle{
    font-size: 1.5rem;
    font-weight: bold;
    line-height: 1.33;
}

.categoryDescription {
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.63;
    padding: 20px;
    color: #424242;
}

.experience-scroll-arrows {
    width: 100%;
    height: 2.5rem;
    position: relative;
}

.experience-scroll-arrows .arrow-left {
    position: absolute;
    top: 0;
    left: 1.4rem;
}

.experience-scroll-arrows .arrow-right {
    position: absolute;
    top: 0;
    right: 1.4rem;
}

.itemContainer {
    width: 20rem;
    min-width: 20rem;
    margin: 0 10px 0 10px;
}

.owl-carousel .owl-item img {
    width: inherit!important;
}


@media (max-width: 1100px) {
    .experienceCardRow .col-md-3 {
        flex: 0 0 50%;
        max-width: 50%;
    }

}
@media (max-width: 767px) {
    .experienceCardRow .col-md-3 {
        flex: 0 0 100%;
        max-width: 100%;
    }
}

@media (max-width: 400px) {
    .experienceCardRow {
        padding: 1rem 1rem 0;
    }
    .itemContainer {
        width: 18rem;
        min-width: 18rem;
        margin: 0 10px 0 10px;
    }

    .experience-scroll-arrows .arrow-left {
        left: 1rem;
    }

    .experience-scroll-arrows .arrow-right {
        right: 1rem;
    }
}

@media (max-width: 360px) {
    .experienceCardRow {
        padding: 0.8rem 0.8rem 0;
    }
    .itemContainer {
        width: 16rem;
        min-width: 16rem;
        margin: 0 8px 0 8px;
    }

    .experience-scroll-arrows .arrow-left {
        left: 0.8rem;
    }

    .experience-scroll-arrows .arrow-right {
        right: 0.8rem;
    }
}