.my-modal-classname {
    padding: 0!important;
    border-radius: 0.75rem;
}

.topNoOneInCircleModalContainer {
    height: 26.5rem;
    position: relative;
    border-radius: 0.75rem;
    background-image: linear-gradient(to bottom, #9c676c, #48070c);
    background-size: cover;
    background-position: center;
    overflow: hidden;
}

.topNoOneInCircleModalContainer .closeModalX {
    position: absolute;
    /*width: 1.75rem;*/
    right: 1.25rem;
    z-index: 10;
    top: 0.9375rem;
    padding: 0;
    height: 15px;
    width: 15px;
    line-height: 15px;
    cursor: pointer;
}

.topNoOneInCircleModalContainer .closeModalX img {
    height: 15px;
    width: 15px;
}

.topNoOneInCircleModalContainer .noOneInCircleTitle {
	  font-size: 24px;
	  font-weight: bold;
	  line-height: 1.42;
    text-align: left;
    padding-right: 2rem;
    color: #FFFFFF;
    z-index: 10;
    position: absolute;
    top: 6rem;
    left: 2rem;
}

.topNoOneInCircleModalContainer .noOneInCircleText{
	  font-size: 14px;
	  font-weight: 500;
	  line-height: 1.71;
	  text-align: left;
    color: #FFFFFF;
    z-index: 10;
    position: absolute;
    top: 10rem;
    left: 2rem;
    padding-right: 2rem;
}


.topNoOneInCircleModalContainer .buttonArea {
    position: absolute;
    width: 100%;
    height: 3rem;
    bottom: 2rem;
    /*padding-top: 0.5rem;*/
}

.topNoOneInCircleModalContainer .buttonArea .btn {
    height: 2.5rem;
    border: none!important;
    font-size: 1rem;
    font-weight: bold!important;
    width: 14rem;
    border-radius: 0.7875rem!important;
}


@media (max-width: 575px) {
	.topNoOneInCircleModalContainer {
    height: 32rem;
  }

	.topNoOneInCircleModalContainer .noOneInCircleTitle {
	  padding-right: 1rem;
	  left: 1.5rem;
	}

	.topNoOneInCircleModalContainer .noOneInCircleText{
	  padding-right: 1rem;
	  top: 12rem;
	  left: 1.5rem;
	}

}

@media (max-width: 400px) {
	.topNoOneInCircleModalContainer {
    height: 34rem;
  }

	.topNoOneInCircleModalContainer .noOneInCircleTitle {
	  padding-right: 1rem;
	  left: 1.5rem;
	}

	.topNoOneInCircleModalContainer .noOneInCircleText{
	  padding-right: 1rem;
	  top: 12rem;
	  left: 1.5rem;
	}

}

