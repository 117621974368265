.modal-content{
    padding: 1.375rem 1.875rem;
}
.modal-header {
    padding:0rem;
    border:0rem solid #fff;
    position: relative;
}
.modal-header .close{
    position: absolute;
    right: 0rem;
    top: 0rem;
    padding: 0rem;
    margin: 0rem;
    font-size: 2rem;
    font-weight: normal;
}
.modal-header .modal-title{
    font-style: normal;
    font-weight: 800;
    font-size: 1.25rem;
    line-height: 2.125rem;
}
.modal-body{
    padding: 0rem;
    margin-top: 2rem;
}
.modal-body label{
    font-size: 1rem;
}
.modal-body .privacyPolicy{
    font-weight: 400;
}
.modal-body button{
    display: block;
    margin: 0rem auto 0.938rem;
    box-shadow: 0rem 0.313rem 1.063rem rgba(0, 0, 0, 0.15);
}
.authSpinner {
    margin: 0rem auto 0.938rem;
}

.authSpinner .spinner {
    float: none;
    display: block;
}
.textmodalfooter{
    display: inline-block;
    line-height: 2.5rem;
    font-size: 0.875rem;
    margin-right: 0.938rem;
}
.modal-footer{
    padding-left: 0rem;
    padding-right: 0rem;
    text-align: center;
    display: block;
    padding-bottom: 0rem;
}
.modal-body .form-group{
    margin-bottom: 1.5rem;
}
.form-check, .form-check a, .form-check label{
    font-size: 0.875rem;
}
.form-check-input{
    margin-top: 0.2rem;
    font-size: 1rem;
}
.modal-body .form-control{
    border: 1px solid #CBA2A7;
}

.modal-body #FormSocialLogin .invalid-feedback{
    display: block;
}

/*Extra small devices (portrait phones, less than 576px)*/
@media (max-width: 575.98px) {
    .modal-footer{
        padding: 0rem;
    }
    .modal-header .modal-title {
        font-size: 1.125rem;
        line-height: 1.5rem;
    }
}