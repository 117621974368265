.topLeadingText {
  margin: auto;
  background-image: linear-gradient(to bottom, #9c676c, #48070c);
  color: white;
  /*height: 372px;*/
}

.centeredBox {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100%;
}

.centeredBox img {
  max-width: 100%;
  max-height: 100%;
}

.team-area img, .team-area-modal img {
  max-width: 90%;
  width: 90%;
  border-radius: 1rem;
}

.team-area img {
  margin-left: 5%;
  cursor: pointer;
}

.team-area-modal img {
  margin: auto;
}

.team-area p, .team-area h5 {
  text-align: justify;
  max-width: 90%;
  margin-left: 5%;
}

.team-area-modal p, .team-area-modal h5 {
  text-align: justify;
  max-width: 90%;
  margin: auto;
}

.partnerbox img {
    max-width: 100%;
    max-height: 100%;
}

.media-logo {
    width: 144px;
    margin: auto;
}

.media-logo img {
    height: 72px;
    margin: auto;
}

.contentpages h3{
  font-weight: 600!important;
}
.contentpages h4{
  font-weight: 600!important;
}
.contentpages label{
  font-weight: bold;
}
.contentpages p.quotetext{
  font-style: italic;
  text-align: left;
  font-size: 1.25rem;
}
.contentpages ol, .contentpages ul{
  margin: 0;
  padding: 0;
}
.contentpages ol li, .contentpages ul li{
  font-size: 1rem;
  line-height: 1.75rem;
  margin-left: 1.125rem;
  margin-bottom:0.625rem;
}
.contentpages h2{
  font-weight: 600!important;
}
/* Medium devices (tablets, less than 992px)*/
@media (max-width: 991.98px) {
  .contentpages h1, .contentpages h2{font-size:2.25rem!important;}
  .contentpages h3{
    font-size: 1.5rem!important;
  }
  .contentpages h4{
    font-size: 1.125rem!important;
  }
}

/*Extra small devices (portrait phones, less than 576px)*/
@media (max-width: 575.98px) {
    .contentpages h1, .contentpages h2{
      font-size:2.5rem!important;
    }
    .contentpages p{
      font-size:0.875rem!important;
    }
    .contentpages p.quotetext{
      font-size:1rem!important;
    }
    .contentpages h4 {
      font-size: 1rem!important;
  }
  .contentpages h3{
    font-size: 1.25rem!important;
    line-height: 1.875rem;
  }
  .contentpages ol li, .contentpages ul li{
    font-size: 0.875rem;
    line-height: 1.125rem;
  }
}