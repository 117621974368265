.cardRow {
    padding: 2.25rem 1.875rem 1.25rem;;
}
.cardRow .cardContainer {
    background: rgba(81, 35, 37, 0.98);
    border-radius: 2.5rem;
}
.cardRow .relateCardContainer {
    background: rgba(170, 101, 84, 0.98);
    border-radius: 2.5rem;
}
.cardRow .selfCardContainer {
    background: #813F4C;
    border-radius: 2.5rem;
}
.cardRow .cardTitle {
    padding: 4.688rem 3rem 0;
    max-width: 370px;
    margin: auto;
    height: 16.688rem;
}
.cardRow .cardTitle p {
    font-weight: normal;
    font-size: 2.25rem!important;
    line-height: 3rem!important;
}
.cardRow p {
    color: #ffffff;
}
.cardRow .cardDescription {
    padding: 1.563rem 2.5rem 0;
    height: 10.938rem;
    font-weight: 100;
}
.cardRow .joinButton {
    padding: 2.5rem 0 3.063rem;
}
.cardRow .joinButton button{
    color: #FFFFFF;
    border:1px solid #FFFFFF;
}
.cardRow .col-sm-4 {
    margin-bottom: 30px;
}
.cardRow .cardRowContainer {
    justify-content: center;
}
.alignCenter{
    max-width: 27.5rem;
}

/*Extra small devices (portrait phones, less than 576px)*/
@media (max-width: 1200px) {
    .cardRow .cardDescription {
        padding: 1.563rem 1.5rem 0;
        height: 13.938rem;
    }
}
@media (max-width: 1024px) {
    .cardRow .col-sm-4 {
        flex: 0 0 50%;
        max-width: 50%;
    }
    .cardRow .cardDescription {
        padding: 1.563rem 2.5rem 0;
        height: 12.938rem;
        font-size: 0.9rem;
    }
}

@media (max-width: 683px) {
    .cardRow .col-sm-4 {
        flex: 0 0 100%;
        max-width: 100%;
    }
    .cardRow .cardDescription {
        padding: 1.563rem 1.5rem 0;
        height: 8.938rem;
    }
    .cardRow .cardTitle {
        padding: 4.688rem 1.5rem 0;
        max-width: 370px;
        margin: auto;
        height: 16.688rem;
    }
}
@media(max-width: 420px) {
    .cardRow .cardDescription {
        height: 12.938rem;
    }
}