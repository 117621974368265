 /* Navbar */

.navbar-in-lobby{
    background: #9c676c;
    /*background-image: linear-gradient(to bottom, #9c676c, #48070c);*/
}

.navbar-out-of-lobby{
    background: #9c676c;
    background-image: linear-gradient(to bottom, #9c676c, #48070c);
}

.navbar-facilitator-page {
    background: #3b161f;
}

 .navbar-toggler{
     outline: none!important;
 }
.navbar-brand #logoSymbol{
     max-width: 1.75rem;
}
.navbar-brand #logoWordmark{
    max-height:1.813rem; 
}
.navbar-brand {
    padding-top: 0;
    padding-bottom: 0.625rem; 
}
.navbar-nav .nav-item{
    margin: 0 1.875rem;
}
.btn-secondary, .btn-secondary:hover{
    color: #CBA2A7;
    border-color:#AA6362;
    
}
#NavbarSignIn {
    box-shadow: none;
    margin-left: 1.875rem;
}
/* Footer */
.footer {
    background: #431D1F;
    font-size: 1rem;
}
.footer p{
    padding: 2rem 0 1rem 0; 
    margin-bottom: 0;
    line-height: 1.875rem;
}
.footerLinks {
    background: #431D1F;
}
.footerLinks a {
    color: #ffffff;
    margin: 0 2rem;
}
.footer-link{
    display: inline;
    margin: 0 0.938rem;
    font-size: 1rem;
}
.userbtn{
    width:2.5rem!important;
    padding: 0!important;
    background: white;
    border-radius: 100%!important;
}
.userbtn img{
    width:1.813rem;
    height:1.813rem;
    object-fit: contain;
    margin:-0.313rem 0 0;
}
.donationIcon {
    position: absolute;
    top: 0.188rem;
    right: 0.3rem;

}

.donationIconImg {
    max-height: 2.5rem;
    max-width: 2.5rem;
}

.donation {
    padding-right: 3.125rem !important;
}
@media (max-width: 1212px) {
    .navbar-nav .nav-item{
        margin: 0 0.625rem;
    }
    .userDropdown {
        margin-left: 0.5rem!important;
    }
}
@media (max-width: 929px) {
    .navbar-nav .nav-item{
        margin: 0 0.005rem;
    }
}

@media (min-width: 768px) and (max-width: 821px) {
    .navbar-brand #logoWordmark {
        max-height: 1.475rem!important;
    }
}

@media (min-width: 768px) and (max-width: 773px) {
    .navbar-expand-md .navbar-nav .nav-link{
        padding-right: 0.3rem;
        padding-left: 0.3rem;
    }
}


@media (max-width: 768px) {
    .socialMediaLinks li{
        margin: 0 1rem!important;
    }
    .footerLinks a{
        margin: 0 1rem!important;
    }
}
@media (max-width: 767px) {
    .donation {
        padding-right: 0.5rem !important;
    }
    .donationIcon {
        position: absolute;
        top: 0.188rem;
        left: 6.875rem;
    }
    .btn.btn-secondary.userDropdown{
        margin-top: 1rem;
    }
}
@media (max-width: 480px) {
    .navbar-brand #logoWordmark {
        max-height: 1.375rem!important;
    }
}
@media only screen and (max-width: 690px) and (min-width: 576px)  {
    
    .navbar-brand #logoSymbol {
        max-width: 1.25rem;
    }
    .navbar-nav .nav-item {
        margin: 0 0.063rem;
    }
    .navbar-nav .nav-item .nav-link{
        font-size: 0.875rem;
    }
    .navbar-nav .btn {
        padding: 0rem 0.938rem;
        font-size: .7rem;
        line-height: 1.875rem;
    }
}

.btn.btn-secondary.userDropdown:hover{
    color: #ffffff;
    border-color: #ffffff;
}

.socialMediaLinks {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    list-style: none;
    padding: 0 0 4rem;
    margin: 0;
}

.socialMediaLinks li {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 3em;
    height: 3em;
    margin: 0 1.5rem;
}

.socialMediaLinks li a {
    width: 100%;
    height: 100%;
    padding: 0.25em;
    border-radius: 50%;
    background: #794D4F;
    transition: background 300ms;
    font-size: 1.5rem;
    line-height: 1.5;
}

.socialMediaLinks li a.facebook:hover {
    background: #3D63A7;
}

.socialMediaLinks li a.instagram:hover {
    background: #F00075;
}

.socialMediaLinks li a.youtube:hover {
    background: #F00;
}

