.inner-tooltip {
  min-width: 22.5rem;
}

.not-transparent {
  opacity: 1;	
  width: 34.375rem;
}

#WhatAreGatheringsTrigger {
	font-size: 0.7em;
	vertical-align: super;
}


.emailBox {
  clear: both;
  width: 100%;
  margin: 3.5rem 0 3.688rem;
}


@media (max-width: 991.98px) {
  .emailBox {
    margin-top: 2.5rem;
  }
}

@media (max-width: 767px) {
  .emailBox .emaillink{
    font-size: 1rem;
  }
}
